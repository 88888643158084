<template>
  <div
    v-click-outside="closeSelect"
    class="v-custom-select"
    :class="{
      'v-custom-select---active': selectOpen,
      'v-custom-select---pink': error,
    }"
  >
    <label v-if="label" class="v-custom-select--label">{{ label }}</label>
    <div
      class="v-custom-select--input-container"
      :class="{ 'v-input---has-error': error }"
    >
      <div class="v-custom-select--input" @click="openSelect">
        <div class="v-custom-select--input-text">
          {{ selectedName }}
        </div>
        <span class="v-custom-select--input-icon"></span>
      </div>
      <div v-show="selectOpen" class="v-custom-select--dropdown-wrapper">
        <div class="v-custom-select--dropdown">
          <div
            v-for="(option, index) in options"
            :key="`option-${index}`"
            class="v-custom-select--dropdown-item"
            :class="{
              'v-custom-select--dropdown-item---selected': index == modelValue,
              'v-custom-select--dropdown-item---split':
                top > 0 && index == top - 1,
            }"
            @click="selectItem(option)"
          >
            <div class="v-custom-select--dropdown-item--name">
              {{ option.name }}
            </div>
            <div
              v-if="option.subname"
              class="v-custom-select--dropdown-item--subname"
            >
              {{ option.subname }}
            </div>
            <hr
              v-if="top > 0 && index == top - 1"
              class="v-custom-select--dropdown--top5-splitter"
            />
          </div>
        </div>
      </div>
      <input v-validation="validation" type="hidden" :value="modelValue">
      <t2-input-error v-if="error" :message="error" />
    </div>
  </div>
</template>
<script>
import T2InputError from "@atomic/molecules/InputError";
import ClickOutside from "@/common/directives/clickOutside";
export default {
  components: {
    T2InputError,
  },
  directives: {
    ClickOutside,
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    selectEmpty: {
      type: String,
      default: "",
    },
    top: {
      type: Number,
      default: 0,
    },
    validation: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      error: null,
      selectOpen: false,
    };
  },
  computed: {
    selectedOption() {
      return this.options && this.modelValue
        ? this.options.filter((option) => option.value === this.modelValue)[0]
        : {
            name: null,
            value: null,
          };
    },
    selectedName() {
      return this.selectedOption.name ?? this.selectEmpty;
    },
  },
  methods: {
    openSelect() {
      this.selectOpen = true;
    },
    closeSelect() {
      this.selectOpen = false;
    },
    selectItem(item) {
      this.selected = item;
      this.$emit("update:modelValue", this.selected.value);
      this.error = null;
      this.closeSelect();
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-custom-select {
  width: 100%;
  position: relative;
  &--label {
    display: block;
    font-size: 15px;
    text-align: left;
    color: #061f2d;
    padding: 8px 0;
  }
  &--input {
    background-color: @color-white;
    border-radius: 10px;
    border: solid 1px #cfd2d3;
    color: @color-dark;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 13px;
    cursor: pointer;
    &-text {
      line-height: 18px;
      font-size: 18px;
      font-weight: 500;
    }
    &-icon {
      height: 12px;
      width: 12px;
      border-right: 3px solid @color-dark;
      border-bottom: 3px solid @color-dark;
      margin-left: auto;
      margin-top: -5px;
      transform: rotate(45deg);
      border-radius: 2px;
    }
  }
  &--dropdown {
    max-height: 267px;
    overflow-y: scroll;
    &-wrapper {
      position: absolute;
      z-index: 3;
      background-color: @color-white;
      left: 0;
      right: 0;
      top: 83px;
      padding: 17px 5px 17px 17px;
      border: 1px solid #e0e0e0;
      border-top: none;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 50px @color-white;
      border-radius: 50px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      -webkit-box-shadow: inset 0 0 50px #c4c4c4;
      background-color: #555;
      height: 50px;
    }
    &-item {
      &:last-child {
        padding-bottom: 0;
      }
      &--name {
        font-size: 14px;
      }
      &--subname {
        font-size: 10px;
        padding-top: 5px;
      }
      padding-bottom: 13px;
      cursor: default;
      &:hover {
        color: @color-blue;
      }
      &---selected {
        color: @color-red;
        &:hover {
          color: @color-red;
        }
      }
      &---split {
        padding-bottom: 4px;
      }
    }
    &--top5-splitter {
      border: none;
      border-top: 1px solid #cfd2d3;
      opacity: 0.5;
      margin-left: 0;
      width: calc(100% - 10px);
    }
  }
  &---active {
    .v-custom-select--input,
    .v-custom-select--input-icon {
      border-color: @color-blue;
    }
    .v-custom-select--input-text {
      color: @color-blue;
    }
  }
  &---pink {
    .v-custom-select--input,
    .v-custom-select--input-icon {
      border-color: @color-red;
    }
    .v-custom-select--input-text {
      color: @color-red;
    }
  }
}
</style>
