import store from "@/store";
import constants from "@/constants";

const defaultTitle = constants.Meta.Defaults.Title;
const defaultContent = constants.Meta.Defaults.Description;

const setMetadata = (metadata) => {
  document.title = metadata.title;

  metadata.meta.forEach((x) => {
    _createOrUpdateMeta(x.name, x.content ? x.content : defaultContent);
  });
};

const getDefaultMetadata = () => {
  return formatMetadata({});
};

const getMetadataByAlias = (alias) => {
  var aliasMetadata = store.getters["metadata/getValue"](alias) ?? {};
  return formatMetadata({
    title: aliasMetadata.seoTitle,
    content: aliasMetadata.seoDescription,
  });
};

const formatMetadata = (metadata) => {
  return {
    title: metadata.title ? metadata.title : defaultTitle,
    meta: [
      {
        name: "description",
        content: metadata.content ? metadata.content : defaultContent,
      },
      {
        name: "og:description",
        content: metadata.content ? metadata.content : defaultContent,
      },
      {
        name: "og:title",
        content: metadata.title ? metadata.title : defaultTitle,
      },
      {
        name: "og:url",
        content: window.location.href,
      },
    ],
  };
};

const getPageId = (alias) => {
  return store.getters["metadata/getValue"](alias)
    ? store.getters["metadata/getValue"](alias).pageId
    : 0;
};

const isPageHidden = (alias) => {
  return store.getters["metadata/getValue"](alias)
    ? store.getters["metadata/getValue"](alias).hidden
    : true;
};

const filterPathRedundancies = (path) => {
  return path.split("/")
    .filter(x => x)
    .reduce((a, b) => a === "/" ? a + b : `${a}/${b}`, "/");
}

const fetchMetadata = (alias) => {
  const formattedAlias = filterPathRedundancies(alias);

  return store.dispatch("metadata/fetch", formattedAlias)
    .then(() => store.getters["metadata/getValue"](formattedAlias));
};

const isPageActive = (alias) => {
  let metadata = store.getters["metadata/getValue"](alias);
  if (!metadata || !metadata.publishFrom) {
    return false;
  }

  let now = new Date().getTime();
  return (
    new Date(metadata.publishFrom.replace(/ /g, "T")).getTime() <= now &&
    (metadata.publishTo
      ? now < new Date(metadata.publishTo.replace(/ /g, "T")).getTime()
      : true)
  );
};

const _createOrUpdateMeta = (name, content) => {
  const el = document.querySelectorAll(`[data-name='${name}']`);

  if (el.length > 0) {
    el[0].content = content;
  } else {
    pushMetaElement({ name, content });
  }
};

const pushMetaElement = (attributes) => {
  const newEl = document.createElement("meta");

  Object.entries(attributes).forEach(([key, value]) => {
    newEl[key] = value;
  })
  newEl.setAttribute("data-name", attributes.name);
  document.getElementsByTagName("head")[0].appendChild(newEl);
};

const removeMetaByName = (name) => {
  document.querySelector(`[name='${name}']`).remove();
}

export default {
  setMetadata,
  getDefaultMetadata,
  getMetadataByAlias,
  filterPathRedundancies,
  formatMetadata,
  getPageId,
  isPageHidden,
  fetchMetadata,
  isPageActive,
  pushMetaElement,
  removeMetaByName
};
