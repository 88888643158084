<template>
  <t2-consents-campaign-modal
    v-if="loaded"
    :data="modalData"
    @game-rules-click="handleRulesClick"
    @participate-click="handleParticipateClick"
    @save-consents="handleSavePolicy"
    @link-click="handleLinkClick"
    @close-click="handleCloseModal"
  />
</template>
<script>
import T2ConsentsCampaignModal from "@atomic/organisms/Campaigns/ConsentsCampaign/Modal";
import dashboardApi from "@/views/dashboard/api/dashboard.js";
import consentsCampaignService from "@/services/consentsCampaign.js";
import PolarModalService from "@/services/polarModal";
import constants from "@/constants";

export default {
  name: "T2ConsentCampaignModal",
  components: {
    T2ConsentsCampaignModal,
  },
  inject: ["emitter"],
  props: {
    initial: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalData: {
        component: "",
        content: {},
      },
      consents: [],
      loaded: false,
    };
  },
  created() {
    if (!consentsCampaignService.isConsentCampaignActive()) {
      return;
    }

    var initialModal = this.initial
      ? this.initial
      : constants.ConsentCampaignComponents.Main;

    this.loadModal(initialModal);

    if (initialModal === constants.ConsentCampaignComponents.Main) {
      this.getConsentsData().then(() => {
        if (
          sessionStorage.getItem(
            constants.SessionStorage.ConsentsCampaignModalShow
          ) ||
          this.consents.every((x) => x.agreed)
        ) {
          return;
        }

        sessionStorage.setItem(
          constants.SessionStorage.ConsentsCampaignModalShow,
          true
        );
        this.openModal();
      });
    } else {
      this.openModal();
    }
  },
  methods: {
    getConsentsData() {
      return dashboardApi
        .getConsents()
        .then((response) => {
          response.data.forEach(
            (consent) => (consent.agreed = consent.consentAgreementStatus === 1)
          );
          this.consents = response.result.filter(x => !x.agreed);
        })
        .catch((response) => {
          PolarModalService.handleErrorResponse(this, response);
        });
    },
    handleParticipateClick() {
      return dashboardApi
        .saveAgreement()
        .then(() => {
          this.loadModal(
            constants.ConsentCampaignComponents.Consents,
            this.consents
          );
        })
        .catch((response) =>
          PolarModalService.handleErrorResponse(this, response)
        );
    },
    handleSavePolicy(data) {
      this.modalData.content.loader = true;
      var formattedData = consentsCampaignService.formatConsentUpdateData(data);

      return dashboardApi
        .updateConsents(formattedData)
        .then(() => {
          this.loadModal(
            data.every((x) => x.agreed)
              ? constants.ConsentCampaignComponents.Activated
              : constants.ConsentCampaignComponents.Saved
          );
        })
        .catch((response) =>
          PolarModalService.handleErrorResponse(this, response)
        )
        .finally(() => {
          this.modalData.content.loader = false;
        });
    },
    handleCloseModal() {
      this.emitter.emit(constants.ModalControl.Close, {
        id: constants.Modals.ConsentCampaign,
      });
    },
    handleRulesClick() {
      window.open(this.modalData.content.gameRulesLink, "_blank").focus();
    },
    handleLinkClick(url) {
      window.open(url, "_blank").focus();
    },
    loadModal(modal, data) {
      var result = consentsCampaignService.getModalInformation(modal, data);
      this.modalData.content = result.content;
      this.modalData.component = result.component;
    },
    openModal() {
      this.loaded = true;
      this.$nextTick(() => {
        this.emitter.emit(constants.ModalControl.Open, {
          id: constants.Modals.ConsentCampaign,
        });
      });
    },
  },
};
</script>
