<template>
  <div class="v-newsletter">
    <div class="v-newsletter--wrapper">
      <img
        v-if="data.image"
        v-lazy="data.image"
        class="v-newsletter--image"
        :style="{
          opacity: elements.image.opacity,
          transform: `translate(0,${elements.image.translate}px)`,
        }"
      />
      <div class="v-newsletter--form">
        <div class="v-newsletter--form-details">
          <div
            v-if="data.title"
            class="v-newsletter--form-title"
            :style="{
              opacity: elements.title.opacity,
              transform: `translate(0,${elements.title.translate}px)`,
            }"
          >
            {{ data.title }}
          </div>
          <div
            v-if="data.description"
            class="v-newsletter--form-description"
            :style="{
              opacity: elements.description.opacity,
              transform: `translate(0,${elements.description.translate}px)`,
            }"
          >
            {{ data.description }}
          </div>
        </div>
        <div
          class="v-newsletter--form-wrapper"
          :style="{
            opacity: elements.form.opacity,
            transform: `translate(0,${elements.form.translate}px)`,
          }"
        >
          <t2-input
            ref="email"
            v-model.trim="email"
            class="v-newsletter--form-input"
            :placeholder="data.input.placeholder"
            type="email"
            autocomplete="email"
            :validation="{
              rules: 'required|email',
              translations: {
                required: data.input.emptyError,
                email: data.input.validationError,
              },
            }"
            @enter="submit"
          />
          <div class="v-newsletter--form-button--wrapper">
            <t2-button
              v-if="data.button"
              v-loader="data.button.loader"
              class="v-newsletter--form-button"
              @click="submit"
            >
              {{ data.button.text }}
            </t2-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import T2Button from "@atomic/atoms/Button";
import T2Input from "@atomic/molecules/Input";
import { useBreakpoints } from "@/composables/useBreakpoints.js";
export default {
  components: {
    T2Input,
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    animate: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit"],
  setup() {
    const { isSm } = useBreakpoints();

    return { isSm };
  },
  data() {
    return {
      email: null,
      elements: null,
    };
  },
  watch: {
    show() {
      if (!this.animate) {
        return;
      }

      let iteration = 1;
      for (const element in this.elements) {
        setTimeout(() => {
          this.elements[element].opacity = 1;
          this.elements[element].translate = 0;
        }, iteration * 200);
        iteration++;
      }
    },
  },
  created() {
    this.setElements();
  },
  mounted() {
    if (!this.isSm || !this.animate) {
      return;
    }

    for (const element in this.elements) {
      this.elements[element].opacity = 1;
      this.elements[element].translate = 0;
    }
  },
  methods: {
    setElements() {
      const opacity = this.animate ? 0 : 1;
      const translate = this.animate ? 40 : 0;

      this.elements = {
        image: {
          opacity,
          translate,
        },
        title: {
          opacity,
          translate,
        },
        description: {
          opacity,
          translate,
        },
        form: {
          opacity,
          translate,
        },
      };
    },
    submit() {
      if (this.$refs.email.hasErrors()) {
        return;
      }

      this.$emit("submit", { email: this.email });
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-newsletter {
  width: 100%;
  background: linear-gradient(0deg, #0A4E9B 0%, #3375B6 64.47%, #529DD4 100%);
  padding: 68px 0 87px;
  @media (width: @media-breakpoint-sm-max) {
    padding: 41px 0;
  }
  @media screen and (max-width: ((@media-breakpoint-sm-max - 1px))) {
    padding: 22px 0;
  }
  &--wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 1032px;
    padding: 0 10px 0;
    @media (width: @media-breakpoint-sm-max) {
      padding: 0 79px 0;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      flex-direction: column;
    }
    @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
      padding: 0 15px 0;
    }
  }
  &--image {
    margin-right: 10px;
    transition: 0.4s ease-out 0.1s;
    @media (width: @media-breakpoint-sm-max) {
      width: 244px;
      height: 152px;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      margin: 0 0 30px;
    }
    @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
      height: 105.81px;
      width: 171px;
    }
  }
  &--form {
    margin-left: auto;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      margin-left: 0;
      width: 100%;
    }
    &-wrapper {
      display: flex;
      transition: 0.4s ease-out 0.1s;
      @media (width: @media-breakpoint-sm-max) {
        width: 100%;
      }
      @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
        display: block;
      }
    }
    &-details {
      @media screen and (max-width: @media-breakpoint-sm-max) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &-title {
      font-weight: bold;
      font-size: 48px;
      line-height: 52px;
      color: @color-white;
      margin-bottom: 15px;
      max-width: 425px;
      transition: 0.4s ease-out 0.1s;
      @media (width: @media-breakpoint-sm-max) {
        font-size: 36px;
        line-height: 39px;
      }
      @media screen and (max-width: @media-breakpoint-sm-max) {
        max-width: 355px;
        text-align: center;
      }
      @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
        font-size: 30px;
        line-height: 32px;
      }
    }
    &-description {
      font-size: 16px;
      line-height: 1.54;
      color: @color-white;
      font-weight: bold;
      margin-bottom: 25px;
      max-width: 425px;
      transition: 0.4s ease-out 0.1s;
      @media (width: @media-breakpoint-sm-max) {
        margin-bottom: 46px;
      }
      @media screen and (max-width: @media-breakpoint-sm-max) {
        max-width: 355px;
        text-align: center;
      }
      @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
        font-size: 15px;
        line-height: 1.31;
        margin-bottom: 17px;
        max-width: 302px;
      }
    }
    &-input {
      width: 425px !important;
      .v-input--container {
        height: 44px;
      }
      .v-input---has-error {
        margin-bottom: 0;
      }
      .v-input--error---container {
        position: static;
        bottom: auto;
        left: 0;
        background-color: transparent;
        padding: 3px 12px 6px 0;
        .v-input--error---icon {
          display: none;
        }
      }
      .v-input--container:not(.v-input---has-error) {
        margin-bottom: 33px;
      }
      .v-input--label {
        margin-top: 0;
      }
      @media screen and (max-width: 938px) {
        width: auto !important;
        max-width: 300px;
      }
      @media (width: @media-breakpoint-sm-max) {
        width: 425px !important;
        max-width: 425px;
      }
      @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
        width: 100% !important;
        max-width: 100%;
      }
    }
    &-button {
      &--wrapper {
        display: flex;
        align-items: flex-end;
        padding-left: 16px;
        @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
          padding: 0;
        }
      }
      margin-bottom: 33px;
      height: 44px;
      @media screen and (max-width: (@media-breakpoint-sm-max - 1px)) {
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
}
</style>
