export default {
  Defaults: {
    Title: "Vartotojai tvirtina: PILDYK pigiausia! | Pildyk",
    Description: "Pigiausias mobilusis ryšys!",
  },
  Ids: {
    Dashboard: "Dashboard",
    PricePlansCancellation: "PricePlansCancellation",
    PortOut: "PortOut",
    Report: "Report",
    Invoice: "Invoice",
    Settings: "Settings",
    MyRequests: "MyRequests",
    OrderConfirmation: "OrderConfirmation",
    Plans: "Plans",
    Refill: "Refill",
    Help: "Help",
    Login: "Login",
    Registration: "Registration",
    PasswordRecovery: "PasswordRecovery",
    RefillStatus: "RefillStatus",
    Campaign: "Campaign",
    CampaignSuccess: "CampaignSuccess",
    CampaignFailure: "CampaignFailure",
    EmailConfirmation: "EmailConfirmation",
    Home: "Home",
    AwardsCampaign: "AwardsCampaign",
    AwardsCampaignSuccess: "AwardsCampaignSuccess",
    AwardsCampaignLanding: "AwardsCampaignLanding",
  },
};
