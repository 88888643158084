const polarModal = {
  namespaced: true,
  state: {
    data: {
      pole: null,
      heading: null,
      subHeading: null,
      button: null
    }
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    }
  },
  getters: {
    getData: state => {
      return state.data;
    }
  }
};

export default polarModal;
