<template>
  <t2-header
    v-if="$store.getters['header/hasData']"
    :data="headerData"
    :user-logged="$store.getters['user/isLoggedIn']"
    :dark="dark"
    :is-user-menu-open="isUserMenuOpen"
    :is-main-menu-open="isMainMenuOpen"
    @toggle-main-menu="handleMainMenuToggle"
    @user-button-click="handleUserButtonClick"
    @link-click="handleRoute"
    @event-click="handleEventClick"
    @language-select="handleLanguageSelect"
  />
</template>
<script>
import T2Header from "@atomic/templates/Header";
import UrlParserService from "@/services/urlParser";
import LanguageService from "@/services/language";
import ResourceService from "@/services/resource";
import LoaderService from "@/services/loader";
import HeaderService from "./services/header";
import constants from "@/constants";
export default {
  name: "HeaderWrapper",
  components: {
    T2Header,
  },
  inject: ["emitter"],
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      resourcesLoaded: false,
      isMainMenuOpen: false,
      isUserMenuOpen: false,
    };
  },
  computed: {
    headerData() {
      return HeaderService.formatData(this.$store.getters["header/getData"]);
    },
  },
  created() {
    this.emitter.on("mainResourcesLoaded", this.handleResourceLoad);
    this.emitter.on("close-mobile-navigations", this.closeAllNavigation);
  },
  mounted() {
    this.emitter.on("language-select", this.handleLanguageSelect);
  },
  beforeUnmount() {
    this.emitter.off("mainResourcesLoaded", this.handleResourceLoad);
    this.emitter.off("close-mobile-navigations", this.closeAllNavigation);
    this.emitter.off("language-select", this.handleLanguageSelect);
  },
  methods: {
    handleRoute(payload) {
      this.closeAllNavigation();

      const isPildykRoute = payload.url && UrlParserService.getHost(payload.url) === window.location.hostname
      const urlPath = UrlParserService.getPath(payload.url);

      if (isPildykRoute && urlPath === constants.Routes.Logout) {
        return this.emitter.emit("navigationClick", payload);
      }

      if (isPildykRoute && this.$route.path !== urlPath) {
        return this.$router.push(urlPath);
      }

      if (isPildykRoute && this.$route.path === urlPath) {
        return;
      }

      if (!payload.url) {
        return;
      }

      if (payload.external) {
        const win = window.open(payload.url, "_blank");
        win.focus();

        return;
      }

      window.location.href = payload.url;
    },
    handleLanguageSelect(payload) {
      if (!payload) {
        return;
      }

      this.closeAllNavigation();
      if (payload.value === constants.UkrainianLanguage) {
        const language = this.$store.getters["user/getLanguage"];
        return this.$router.push(`${language !== "lt" ? '/' + language: ""}${constants.Routes.UkraineCampaign}`);
      }

      const language = payload.value;
      this.resourcesLoaded = false;

      LoaderService.showLoader();
      window.scroll(0, 0);
      this.$store.commit("user/setLanguage", language);

      return ResourceService.reloadResources().then(() => {
        return Promise.all([
          this.$store.dispatch("translations/reload", language),
          this.$store.dispatch("header/fetch", language),
          this.$store.dispatch("footer/fetch", language),
        ]);
      }).then(() => {
        const routeChanged = LanguageService.checkCurrentRoute(this, language);
        this.emitter.emit("mainResourcesLoaded", !routeChanged);
      });
    },
    handleMainMenuToggle() {
      this.isMainMenuOpen = !this.isMainMenuOpen;
      this.isUserMenuOpen = false;
    },
    handleUserButtonClick() {
      if (this.$store.getters['user/isLoggedIn'] && !this.$store.getters["user/isDefaultLanguage"]) {
        return this.emitter.emit("navigationClick", { url: constants.Routes.Logout });
      }

      if (!this.$store.getters['user/isLoggedIn']) {
        this.isMainMenuOpen = false;
        return this.$router.push(this.$store.getters["user/getLanguageRoutes"].Login);
      }

      this.isMainMenuOpen = false;
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    closeAllNavigation() {
      this.isMainMenuOpen = false;
      this.isUserMenuOpen = false;
    },
    handleResourceLoad() {
      this.resourcesLoaded = true;
    },
    handleEventClick(link) {
      if (link.event === "logOut") {
        return this.emitter.emit("navigationClick", { url: constants.Routes.Logout });
      }

      if (link.event === "idCard") {
        return this.$store.dispatch("menu/showIdCard", link);
      }
      
      if (link.event === "pukCode") {
        return this.$store.dispatch("menu/showPukCode", link);
      } 

      return this.handleUserButtonClick();
    },
  },
};
</script>
