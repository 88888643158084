<template>
  <div class="user-profile">
    <t2-loader-box
      v-if="Object.keys(navigation.user) == 0"
      class="user-profile--loader-box"
    />
    <t2-user-profile
      v-else
      :user="navigation.user"
      :class="{ 'user-profile-info---tablet-hidden': !showMobileBanner }"
    />
    <ul
      v-for="(listItem, index) in navigationLinks"
      :key="'dnl-' + index"
      class="user-profile--nav"
      :class="listItem.customClass"
    >
      <li
        v-for="(item, secondIndex) in listItem.list"
        :key="secondIndex"
        class="user-profile--nav-item"
        :class="{
          [`user-profile--nav-item---${item.red ? 'red' : 'blue'}`]: true,
          'user-profile--nav-item---selected': item.selected
        }
        "
        @click.prevent="$emit(eventName(item.event), item)"
      >
        <div>
          <t2-svg class="user-profile--nav-icon" :icon-id="item.iconId" />
        </div>
        <a
          v-loader="item.loader"
          class="user-profile--nav-link"
          :href="item.url || '#'"
        >
          {{ item.name }}
          <t2-badge v-if="item.badge && item.badge.length > 0" :text="item.badge" />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import T2LoaderBox from "@atomic/atoms/LoaderBox/LoaderBox.vue";
import T2Svg from "@atomic/atoms/Svg/Svg.vue";
import T2Badge from "@atomic/atoms/Badge/Badge.vue";
import T2UserProfile from "@atomic/organisms/UserProfile/UserProfile.vue";
export default {
  components: {
    T2LoaderBox,
    T2Svg,
    T2Badge,
    T2UserProfile,
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
    navigationLinks: {
      type: Array,
      required: true,
    },
    showMobileBanner: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "navigation-click",
    "event-click",
  ],
  methods: {
    eventName(event) {
      return event ? "event-click" : "navigation-click";
    }
  }
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";
@import (reference) "~@/assets/less/fonts.less";
.user-profile {
  background-color: @dashboard--user-profile-bg-color;
  padding: 60px 19px 60px 50px;
  transition: padding 0.2s ease-in-out;
  max-width: 316px;
  width: 100%;

  @media screen and (max-width: @media-breakpoint-lg-max) {
    padding-left: 20px;
    max-width: unset;
    width: unset;
    background-color: @color-white;
    padding: 0;
    flex: 0 0;
  }

  &--loader-box {
    height: 152px;

    @media screen and (max-width: @media-breakpoint-lg-max) {
      height: 135px;
    }
  }

  &--user-info-title {
    .BP-bold;
    text-transform: uppercase;
    &---name {
      font-size: 32px;
      color: @color-red;
      margin: 0 0 9px 0;
    }
    &---phone {
      font-size: 20px;
      color: @color-dark;
      margin: 0;
    }
  }

  &--nav {
    padding: 20px 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid @dashboard--user-profile-nav-border-color;

    @media screen and (max-width: @media-breakpoint-lg-max) {
      display: none;
    }
    &-item {
      padding: 8px 10px 8px 45px;
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
      min-height: 40px;
      display: flex;
      align-items: center;
      position: relative;

      &:not(:first-child):not(:last-child) {
        margin: 6px 0;
      }

      &:hover {
        background-color: @color-grey-5;
      }
    }
    &-link {
      .BP-light;
      font-size: 16px;
      color: @color-dark;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      &:hover {
        color: @color-red;
      }

      &.loading {
        color: transparent;
        pointer-events: none;
      }
    }
    &-icon {
      display: inline-block;
      position: relative;
      max-width: 24px;
      max-height: 24px;
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      left: 13px;
    }
    /// USER-PROFILE--NAV - MODIFIERS
    &-item---red {
      .user-profile--nav-link {
        color: @color-red;
      }
      .user-profile--nav-icon {
        fill: @color-red;
      }
    }
    &-item---blue {
      .user-profile--nav-link {
        color: @color-blue;
      }
      .user-profile--nav-icon {
        fill: @color-blue;
      }
    }
    &-item---selected {
      background-color: @color-grey-5;
      .user-profile--nav-link {
        font-weight: bold;
      }
    }
  }
}
</style>
