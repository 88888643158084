import { useRoute } from 'vue-router'
import store from "@/store";
import UrlParserService from "@/services/urlParser";
import constants from "@/constants";

const _domain = "pildyk.lt";

const formatData = (data) => {
  const route = useRoute();

  return {
    default: {
      submenu: {
        name: store.getters["user/isDefaultLanguage"]
          ? _translations("my_pildyk")
          : _translations("logout"),
        loginText: _formatLoginText(),
        url:
          store.getters["user/isDefaultLanguage"] ||
          !store.getters["user/isLoggedIn"]
            ? store.getters["user/getLanguageRoutes"].Login
            : constants.Routes.Logout,
        hasSubmenu: true,
      },
      topup: {
        name: _translations("refill"),
        url: store.getters["user/getLanguageRoutes"].Refill,
      },
      index: {
        name: "index",
        url: store.getters["user/getLanguageRoutes"].Home,
      },
      help: {
        help: "help",
        url: _getMobileHelpUrl(),
      },
      socials: data.socials,
      languages: _formatLanguages(),
    },
    links: _formatPildykLinks(data.links),
    submenu: store.getters["menu/getSubmenu"].map((m) => _formatSubMenu(m, route)),
  };
};

const _formatSubMenu = (menu, route) => {
  menu.list = menu.list.map(listItem => {
    if (listItem.url) {
      listItem.selected = listItem.url === (route?.meta?.routeKey || route.path);
    }

    return listItem;
  })

  return menu;
}

const _formatLoginText = () => {
  if (store.getters["user/isLoggedIn"] && !store.getters["user/isDefaultLanguage"]) {
    return _translations("logout");
  }

  if (store.getters["user/isLoggedIn"]) {
    return "";
  }

  return _translations("login");
};

const _translations = (id) => {
  return store.getters["translations/getValue"]("public")(id);
};

const _formatLanguages = () => {
  return constants.Languages.map((lang) => ({
    value: lang,
    flag: constants.Images.LanguageFlags[lang.toUpperCase()],
    selected: lang === store.getters["user/getLanguage"],
  }));
};

const _getMobileHelpUrl = () => {
  if (store.getters["user/isLoggedIn"] && !store.getters["user/isDefaultLanguage"]) {
    return constants.Routes.Home;
  }
  
  if (store.getters["user/isLoggedIn"]) {
    return constants.Routes.Dashboard;
  }

  return store.getters["user/getLanguageRoutes"].Login;
};

const _formatPildykLinks = (links) => {
  return links.map((link) => {
    if (link.url.includes(_domain)) {
      link.url = link.url.split(_domain)[1];
    }

    link.href = UrlParserService.getPath(link.url);

    return link;
  })
};

export default { formatData };
