<template>
  <label
    class="v-toggle-checkbox"
    :class="{
      small,
      'v-toggle-checkbox---disabled': showLoader,
    }"
  >
    <input
      type="checkbox"
      :checked="modelValue"
      :disabled="showLoader"
      @change="$emit('update:modelValue', !modelValue)"
    />
    <div
      class="v-toggle-checkbox--slider"
      :class="{ 'v-toggle-checkbox--slider---with-smile': withSmile }"
    >
      <div class="v-toggle-checkbox--circle">
        <svg
          class="v-toggle-checkbox--loader-container"
          :class="{ 'v-toggle-checkbox--loader-container---loader': showLoader }"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9" cy="2" r="1.5" />
          <circle cx="13.9492" cy="4.05028" r="1.5" transform="rotate(45 13.9492 4.05028)" />
          <circle cx="16" cy="9" r="1.5" transform="rotate(90 16 9)" />
          <circle cx="13.949" cy="13.9492" r="1.5" transform="rotate(135 13.949 13.9492)" />
          <circle cx="9" cy="16" r="1.5" />
          <circle cx="4.05078" cy="13.9497" r="1.5" transform="rotate(45 4.05078 13.9497)" />
          <circle cx="2" cy="9" r="1.5" transform="rotate(90 2 9)" />
          <circle cx="4.05055" cy="4.05078" r="1.5" transform="rotate(135 4.05055 4.05078)" />
        </svg>
      </div>
    </div>
  </label>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    withSmile: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";

.v-toggle-checkbox {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  @media screen and (max-width: @media-breakpoint-sm-max) {
    width: 40px;
    height: 22px;
  }

  &---disabled {
    pointer-events: none;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &--circle {
    height: 26px;
    width: 26px;
    background-color: @color-white;
    border-radius: 50%;

    position: absolute;
    left: 4px;
    bottom: 4px;

    -webkit-transition: 0.4s;
    transition: 0.4s;

    display: flex;
    justify-content: center;
    align-items: center;

    circle {
      fill: @color-grey-9;
      opacity: 0.5;
      animation: transparent-fade 0.8s linear infinite;
      -webkit-transition: fill 0.4s;
      transition: fill 0.4s;

      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.1s;
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
      }
      &:nth-child(4) {
        animation-delay: 0.3s;
      }
      &:nth-child(5) {
        animation-delay: 0.4s;
      }
      &:nth-child(6) {
        animation-delay: 0.5s;
      }
      &:nth-child(7) {
        animation-delay: 0.6s;
      }
      &:nth-child(8) {
        animation-delay: 0.7s;
      }
    }

    @media screen and (max-width: @media-breakpoint-sm-max) {
      height: 18px;
      width: 18px;

      bottom: 2px;
    }
  }
  &--loader-container {
    position: relative;
    height: 18px;
    width: 18px;
    transition: opacity 0.4s ease-in-out;
    opacity: 0;

    &---loader {
      opacity: 1;
    }
  }

  &--slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @color-grey-8;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &---with-smile {
      &:after {
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zIDIuNTczMjRDMi40OTExOCAyLjU3MzI0IDIgMy4wMTkyNCAyIDMuNjY4OEMyIDQuMjIxMDggMS41NTIyOCA0LjY2ODggMSA0LjY2ODhDMC40NDc3MTUgNC42Njg4IDAgNC4yMjEwOCAwIDMuNjY4OEMwIDIuMDAzNjYgMS4yOTk2OCAwLjU3MzI0MiAzIDAuNTczMjQyQzQuNzAwMzIgMC41NzMyNDIgNiAyLjAwMzY2IDYgMy42Njg4QzYgNC4yMjEwOCA1LjU1MjI4IDQuNjY4OCA1IDQuNjY4OEM0LjQ0NzcyIDQuNjY4OCA0IDQuMjIxMDggNCAzLjY2ODhDNCAzLjAxOTI0IDMuNTA4ODIgMi41NzMyNCAzIDIuNTczMjRaIiBmaWxsPSIjMEE0RTlCIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEgMi41NzMyNEMxMC40OTEyIDIuNTczMjQgMTAgMy4wMTkyNCAxMCAzLjY2ODhDMTAgNC4yMjEwOCA5LjU1MjI4IDQuNjY4OCA5IDQuNjY4OEM4LjQ0NzcyIDQuNjY4OCA4IDQuMjIxMDggOCAzLjY2ODhDOCAyLjAwMzY2IDkuMjk5NjggMC41NzMyNDIgMTEgMC41NzMyNDJDMTIuNzAwMyAwLjU3MzI0MiAxNCAyLjAwMzY2IDE0IDMuNjY4OEMxNCA0LjIyMTA4IDEzLjU1MjMgNC42Njg4IDEzIDQuNjY4OEMxMi40NDc3IDQuNjY4OCAxMiA0LjIyMTA4IDEyIDMuNjY4OEMxMiAzLjAxOTI0IDExLjUwODggMi41NzMyNCAxMSAyLjU3MzI0WiIgZmlsbD0iIzBBNEU5QiIvPgo8cGF0aCBkPSJNMTIuMjg1NyA4LjAwOTY1QzEyLjI4NTcgOS40Mzg3OSAxMS43NDM5IDEwLjgwOTQgMTAuNzc5NCAxMS44MTk5QzkuODE0OTMgMTIuODMwNSA4LjUwNjgzIDEzLjM5ODIgNy4xNDI4NiAxMy4zOTgyQzUuNzc4ODkgMTMuMzk4MiA0LjQ3MDc4IDEyLjgzMDUgMy41MDYzMSAxMS44MTk5QzIuNTQxODQgMTAuODA5NCAyIDkuNDM4NzkgMiA4LjAwOTY2TDcuMTQyODYgOC4wMDk2NUgxMi4yODU3WiIgZmlsbD0iIzBBNEU5QiIvPgo8L3N2Zz4K");
        background-size: 14px 13px;
        background-repeat: no-repeat;
        height: 14px;
        width: 13px;
        position: absolute;
        right: 11px;
        top: 12px;
        opacity: 0;
        @media screen and (max-width: @media-breakpoint-sm-max) {
          background-size: 10px 9px;
          height: 10px;
          width: 9px;
          right: 7px;
          top: 7px;
        }
      }
    }
  }
  input:checked + .v-toggle-checkbox--slider {
    background-color: @color-blue;

    circle {
      fill: @color-white;
    }
  }

  input:checked + .v-toggle-checkbox--slider > .v-toggle-checkbox--circle {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    @media screen and (max-width: @media-breakpoint-sm-max) {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
  }

  input:checked + .v-toggle-checkbox--slider---with-smile:after {
    transition: all 0.2s ease-in-out;
    transition-delay: 0.15s;
    opacity: 1;
  }

  &.small {
    height: 24px;
    width: 46px;

    .v-toggle-checkbox--circle {
      height: 20px;
      width: 20px;
      left: 2.5px;
      bottom: 2px;
    }

    input:checked + .v-toggle-checkbox--slider > .v-toggle-checkbox--circle {
      -webkit-transform: translateX(21px);
      -ms-transform: translateX(21px);
      transform: translateX(21px);
    }
  }
}

@keyframes transparent-fade {
  0% {
    opacity: 1;
  }
  20%, 100% {
    opacity: 0.5;
  }
}
</style>
