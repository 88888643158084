<template>
  <t2-modal
    id="awards-campaign-medal-receive-modal"
    class="v-awards-medal-receive-modal"
    :class="{ 'v-awards-medals-receive-modal--hide-close': !data.showCloseButton }"
  >
    <div class="v-awards-medal-receive-modal--container">
      <div class="v-awards-medal-receive-modal--image-wrapper">
        <img :src="data.image" class="v-awards-medal-receive-modal--image" />
        <span v-if="data.points">
          {{ data.points }}
        </span>
      </div>
      <img
        :src="data.backgroundImage"
        class="v-awards-medal-receive-modal--background"
      />
      <t2-heading class="v-awards-medal-receive-modal--title">
        {{ data.title }}
      </t2-heading>
      <p class="v-awards-medal-receive-modal--description">
        {{ data.description }}
      </p>
      <t2-button
        class="v-awards-medal-receive-modal--button"
        pink
        @click="$emit('button-click')"
      >
        {{ data.button }}
      </t2-button>
    </div>
  </t2-modal>
</template>
<script>
import T2Button from "@atomic/atoms/Button";
import T2Modal from "@atomic/molecules/Modal";
import T2Heading from "@atomic/atoms/Heading";
export default {
  components: {
    T2Modal,
    T2Heading,
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["button-click"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-awards-medal-receive-modal {
  .popup {
    &--dialog,
    &--content {
      padding: 0;
    }
    &--close {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 10;
    }
    &--dialog {
      max-width: 750px;
    }
  }
  &--hide-close {
    .popup--close {
      display: none;
    }
  }
  &--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px 60px;
    width: 100%;
    position: relative;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 40px 20px 20px;
    }
  }
  &--image {
    max-width: 223px;
    width: 100%;
    height: auto;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      max-width: 116px;
    }
    &-wrapper {
      margin-bottom: 60px;
      position: relative;
      span {
        position: absolute;
        background-color: @color-darkblue;
        color: @color-white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        height: 30%;
        width: 30%;
        font-size: 24px;
        @media screen and (max-width: @media-breakpoint-sm-max) {
          font-size: 12px;
        }
      }
      @media screen and (max-width: @media-breakpoint-sm-max) {
        margin-bottom: 30px;
      }
    }
  }
  &--title {
    margin: 0 0 30px;
    font-size: 36px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 24px;
      line-height: 26px;
    }
  }
  &--description {
    max-width: 380px;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    color: @color-grey-2;
    margin: 0 0 50px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      max-width: 280px;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 27px;
    }
  }
  &--background {
    max-width: 583px;
    width: 100%;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0);
    height: auto;
    position: absolute;
    z-index: -1;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      display: none;
    }
  }
  &--button {
    @media screen and (max-width: @media-breakpoint-sm-max) {
      max-width: 300px;
      width: 100%;
    }
  }
}
</style>
