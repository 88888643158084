/* eslint-disable no-unused-vars */
import constants from "@/constants";
// import helpers from "@/common/helpers";

const orderPlanGroup = (priceplan) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        actionField: { list: "Planų krepšelis" },
        products: [
          {
            name: priceplan.planName,
            price: priceplan.price.toString(),
            id: priceplan.id.toString(),
            category: _resolveSectionId(priceplan.displaySection),
          },
        ],
      },
    },
  });
};

const orderBoxPlan = (priceplan) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        actionField: { list: "Kiti planai" },
        products: [
          {
            name: priceplan.title,
            price: priceplan.price.toString(),
            id: priceplan.id.toString(),
            category: _resolveSectionId(priceplan.displaySection),
          },
        ],
      },
    },
  });
};

const authenticationEvent = (msisdn) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({
    userId: msisdn,
    event: "authentication",
  });
};

const otherPlansImpressionEvent = () => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({
    ecommerce: {
      actionField: { list: "Kiti planai" },
    },
  });
};

const loggedInPlanOrderEvent = () => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({ event: "Planai order - logged in user" });
};

const notLoggedInPlanOrderEvent = () => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({ event: "Planai order - new user" });
};

const addToCartEvent = (priceplan) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "EUR",
      add: {
        products: [
          {
            name: priceplan.name,
            id: priceplan.id.toString(),
            price: priceplan.price.toString(),
            category: _resolveSectionId(priceplan.displaySection),
            quantity: 1,
          },
        ],
      },
    },
  });
};

const transactionEvent = (id, msisdn, revenue, paymentMethod, bankName) => {
  // Removed due to issues with page freeze on transaction event.

  // if (!window.dataLayer) {
  //   return;
  // }
  // window.dataLayer.push({
  //   event: "transaction",
  //   ecommerce: {
  //     purchase: {
  //       actionField: {
  //         id: id,
  //         userId: msisdn,
  //         revenue: revenue,
  //         dimension1: paymentMethod,
  //       },
  //       products: [
  //         {
  //           variant: paymentMethod,
  //           brand: bankName,
  //           name: constants.GaScriptPayments.Topup,
  //           category: constants.GaScriptPayments.Topup,
  //           price: revenue.toString(),
  //           quantity: 1,
  //         },
  //       ],
  //     },
  //   },
  // });
};

const orderBoxPlanTransactionEvent = (priceplan, msisdn, paymentMethod) => {
  // Removed due to issues with page freeze on transaction event.

  // if (!window.dataLayer) {
  //   return;
  // }
  // window.dataLayer.push({
  //   event: "transaction",
  //   ecommerce: {
  //     purchase: {
  //       actionField: {
  //         id: helpers.generateGuid(), //Todo: should be taken from Aduro response
  //         userId: msisdn,
  //       },
  //       products: [
  //         {
  //           name: priceplan.title,
  //           price: priceplan.price.toString(),
  //           id: priceplan.id.toString(),
  //           category: _resolveSectionId(priceplan.displaySection),
  //           quantity: 1,
  //           variant: _getPaymentMethodName(paymentMethod),
  //         },
  //       ],
  //     },
  //   },
  // });
};

const orderPlanGroupTransactionEvent = (priceplans, msisdn, paymentMethod) => {
  // Removed due to issues with page freeze on transaction event.

  // if (!window.dataLayer) {
  //   return;
  // }
  // var products = [];
  // priceplans.forEach(function (plan) {
  //   products.push(_getProductFromPlan(plan, paymentMethod));
  // });

  // window.dataLayer.push({
  //   event: "transaction",
  //   ecommerce: {
  //     purchase: {
  //       actionField: {
  //         id: helpers.generateGuid(), //Todo: should be taken from Aduro response
  //         userId: msisdn,
  //       },
  //       products: products,
  //     },
  //   },
  // });
};

const pushPageView = (url) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({
    event: "VirtualPageview",
    page_url: url,
  });
};

//display sections map = 1: "Not shown" 2: "Voice", 3: "Data", 4: "Services", 5: "Additional", 6: "Combo"
const _resolveSectionId = (id) => {
  if (!id) {
    return "";
  }

  switch (id) {
    case 1:
      return "Not shown";
    case 2:
      return "Voice";
    case 3:
      return "Data";
    case 4:
      return "Services";
    case 5:
      return "Additional";
    case 6:
      return "Combo";
  }
};

const _getPaymentMethodName = (paymentMethod) => {
  return paymentMethod === constants.PaymentMethods.CreditCard
    ? constants.GaScriptPayments.CardPayment
    : constants.GaScriptPayments.AccountPayment;
};

const _getProductFromPlan = (plan, paymentMethod) => {
  return {
    name: plan.planName,
    price: plan.price.toString(),
    id: plan.id.toString(),
    category: _resolveSectionId(plan.displaySection),
    quantity: 1,
    variant: _getPaymentMethodName(paymentMethod),
  };
};

export default {
  authenticationEvent,
  orderPlanGroup,
  orderBoxPlan,
  transactionEvent,
  orderBoxPlanTransactionEvent,
  orderPlanGroupTransactionEvent,
  otherPlansImpressionEvent,
  loggedInPlanOrderEvent,
  notLoggedInPlanOrderEvent,
  pushPageView,
  addToCartEvent,
};
