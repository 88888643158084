<template>
  <div
    class="v-toggle-switch"
    :class="{
      'v-toggle-switch---truthy': toggleValue === true,
      'v-toggle-switch---falsy': toggleValue === false,
      'v-toggle-switch---full-blue': blue,
    }"
  >
    <div
      class="v-toggle-switch--button"
      :class="{ 'v-toggle-switch--true': toggleValue == true }"
      @click="toggle(true)"
    >
      {{ trueLabel }}
    </div>
    <div
      class="v-toggle-switch--button"
      :class="{
        'v-toggle-switch--false': !blue && toggleValue == false,
        'v-toggle-switch--true': blue && toggleValue == false,
      }"
      @click="toggle(false)"
    >
      {{ falseLabel }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    trueLabel: {
      type: String,
      default: "",
    },
    falseLabel: {
      type: String,
      default: "",
    },
    blue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle-click"],
  data() {
    return {
      toggleValue: this.value,
    };
  },
  watch: {
    value: function () {
      this.toggleValue = this.value;
    },
  },
  methods: {
    toggle(clickValue) {
      this.toggleValue =
        this.toggleValue === null ? clickValue : !this.toggleValue;
      this.$emit("toggle-click", this.toggleValue);
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-toggle-switch {
  background-color: @color-white;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  &--button {
    display: inline-block;
    padding: 11px 20px;
    border-radius: 10px;
    font-size: 16px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 110px;
    text-align: center;
    -webkit-transition: background-color 100ms ease-in-out;
    -moz-transition: background-color 100ms ease-in-out;
    -o-transition: background-color 100ms ease-in-out;
    transition: background-color 100ms ease-in-out;
  }
  &--true {
    background-color: @color-lighter-blue;
    color: @color-white;
  }
  &--false {
    background-color: @color-red;
    color: @color-white;
  }

  &---sliding {
    width: 100%;
    min-height: 43px;
    height: 100%;
    margin-bottom: 25px;
    position: relative;
    border: 1px solid #E5E7EB;
    transition: all ease-in .3s;

    &::before {
      content: " ";
      position: absolute;
      width: 50%;
      height: 100%;
      border-radius: 10px;
      transition: all ease-in .3s;
    }

    .v-toggle-switch--button {
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all ease-in .3s;
      transform: translate(-50%, -50%);

      &.v-toggle-switch--true,
      &.v-toggle-switch--false {
        border: none;
        z-index: 1;
        background-color: unset;
      }
      &:first-child {
        left: 25%;
      }
      &:last-child {
        left: 75%;
      }
    }
  }
  &---sliding.v-toggle-switch---truthy {
    &::before {
      background-color: @color-lighter-blue;
      transform: translate3d(0, 0, 0);
    }
  }
  &---sliding.v-toggle-switch---falsy {
    &::before {
      background-color: @color-red;
      transform: translate3d(100%, 0, 0);
    }
  }
  &---sliding.v-toggle-switch---full-blue {
    &::before {
      background-color: @color-lighter-blue;
    }
  }
  &---sliding.v-toggle-switch---limited-width {
    max-width: 233px;
  }
}
</style>
