import constants from "../constants";
import commonConstants from "@/constants";

const formatRemindPasswordChangeModal = () => {
  return {
    id: constants.Ids.PasswordChangeReminder,
    typeId: constants.Types.Modal,
    title: commonConstants.Resources.NewPolarModal.PasswordReminderTitle,
    text: commonConstants.Resources.NewPolarModal.PasswordReminderDescription,
    buttonText: commonConstants.Resources.NewPolarModal.PasswordReminderConfirmButton,
    buttonClose: commonConstants.Resources.NewPolarModal.PasswordReminderCancelButton,
    buttonUrl: `${commonConstants.Routes.SettingsLoginInfo.Path}#${commonConstants.Routes.SettingsLoginInfo.Hashes.ChangePassword}`,
    imageUrl: commonConstants.Images.Confirmation.Url,
    priority: 0,
    showOnlyToLoggedInUsers: true,
    showOncePerSession: true,
    isUserSpecificBanner: true,
    locations: [2]
  };
};

export default { formatRemindPasswordChangeModal };
