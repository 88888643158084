import dayjs from "dayjs";
import constants from "@/constants";
import localConstants from "../constants";

const _formatPrizeDeliveryDate = () => {
  return dayjs().add(10, "day").format("YYYY-MM-DD");
};

const _formatWinMore = () => ({
  title: constants.Resources.PrizeCampaign.WinMoreHeading,
  content: {
    image: constants.Images.RefillImages.Card,
    title: constants.Resources.PrizeCampaign.WinMoreTitle,
    description: constants.Resources.PrizeCampaign.WinMoreDescription,
    button: constants.Resources.PrizeCampaign.WinMoreButton,
  },
});

const _formatAnimation = () => ({
  assetsPath: "/img/fonosCampaign/",
  prizeAssetsPath: "/img/fonosCampaign/animation/",
  blurLoop: constants.Images.RefillImages.Animated.BlurLoop,
  confetti: constants.Images.RefillImages.Animated.Confetti,
  loopStart: constants.Images.RefillImages.Animated.LoopStart,
  loop: constants.Images.RefillImages.Animated.Loop,
  finish: constants.Images.RefillImages.Animated.Finish,
});

const getGameInformation = (index, data) => {
  const states = [
    _formatGameReady,
    _formatGameStarted,
    _formatGameFinished.bind(null, data),
  ];

  return states[index]();
};

const _formatGameReady = () => ({
  gameStatus: localConstants.GameStatus.Ready,
  title: constants.Resources.PrizeCampaign.GameReadyTitle,
  description: constants.Resources.PrizeCampaign.GameReadyDescription,
  button: constants.Resources.PrizeCampaign.GameReadyButton,
  image: constants.Images.RefillImages.Dice,
  mouseFollow: constants.Images.RefillImages.MouseFollow,
  gameAcceptance: constants.Resources.PrizeCampaign.GameReadyRulesInformation,
  prizes: formatPrizes(),
  animation: _formatAnimation(),
});

const _formatGameStarted = () => ({
  gameStatus: localConstants.GameStatus.Started,
  prizes: formatPrizes(),
  animation: _formatAnimation(),
});

const _formatPrizeType = (type) => {
  const types = {
    [localConstants.PrizeTypes.Plan]: {
      description: constants.Resources.PrizeCampaign.GameFinishedPrizeDescriptionPlan,
      button: constants.Resources.PrizeCampaign.GameFinishedPrizeButtonPlan,
      winMore: _formatWinMore(),
    },
    [localConstants.PrizeTypes.Vas]: {
      description: constants.Resources.PrizeCampaign.GameFinishedPrizeDescriptionVas,
      button: constants.Resources.PrizeCampaign.GameFinishedPrizeButtonVas,
      winMore: _formatWinMore(),
    },
    [localConstants.PrizeTypes.Physical]: {
      description: constants.Resources.PrizeCampaign.GameFinishedPrizeDescriptionPhysical,
      button: constants.Resources.PrizeCampaign.GameFinishedPrizeButtonPhysical,
    },
    [localConstants.PrizeTypes.PhysicalSpecial]: {
      description: constants.Resources.PrizeCampaign.GameFinishedPrizeDescriptionPhysicalSpecial,
      button: constants.Resources.PrizeCampaign.GameFinishedPrizeButtonPhysicalSpecial,
    },
    [localConstants.PrizeTypes.Nothing]: {
      description: constants.Resources.PrizeCampaign.GameFinishedPrizeDescriptionNothing,
      button: constants.Resources.PrizeCampaign.GameFinishedPrizeButtonNothing,
    },
  }
  return types[type] || types[localConstants.PrizeTypes.Nothing];
};

const _formatGameFinished = (prize) => ({
  gameStatus: localConstants.GameStatus.Finished,
  title: prize.description,
  buttonLoader: false,
  mouseFollow: constants.Images.RefillImages.MouseFollow,
  animationImages: {
    path: "/img/fonosCampaign/animation/",
    emoji: "emojiParty.png",
    prize: `${prize.prizeId}.png`,
  },
  isNothing: prize.typeId === localConstants.PrizeTypes.Nothing,
  isPhysicalSpecial: prize.typeId === localConstants.PrizeTypes.PhysicalSpecial,
  isBasicPrize: ![localConstants.PrizeTypes.Nothing, localConstants.PrizeTypes.PhysicalSpecial].includes(prize.typeId),
  prizes: formatPrizes(),
  animation: _formatAnimation(),
  ..._formatPrizeType(prize.typeId),
});

const formatPhysicalPrizeForm = (locations, isPhone = false) => ({
  isPhone,
  heading: constants.Resources.PrizeCampaign.FormHeading,
  description: isPhone
    ? constants.Resources.PrizeCampaign.FormDescriptionPhone
    : constants.Resources.PrizeCampaign.FormDescription.replace("{0}", _formatPrizeDeliveryDate()),
  inputFirstName: {
    label: constants.Resources.PrizeCampaign.FormFirstName,
    emptyError: constants.Resources.ValidationMessages.Required,
  },
  inputLastName: {
    label: constants.Resources.PrizeCampaign.FormLastName,
    emptyError: constants.Resources.ValidationMessages.Required,
  },
  inputLocation: {
    label: constants.Resources.PrizeCampaign.FormLocation,
    selectEmpty: constants.Resources.PrizeCampaign.FormSelectEmpty,
    emptyError: constants.Resources.ValidationMessages.Required,
    locations,
  },
  button: {
    text: constants.Resources.PrizeCampaign.FormButton,
    loader: false,
  },
  inputAddress: isPhone
    ? {
        label: constants.Resources.PrizeCampaign.FormAddress,
        emptyError: constants.Resources.ValidationMessages.Required,
      }
    : null,
  inputPersonalNumber: isPhone
    ? {
        label: constants.Resources.PrizeCampaign.FormPersonalCode,
        emptyError: constants.Resources.ValidationMessages.Required,
        validationError: constants.Resources.ValidationMessages.PersonCodeFormat,
      }
    : null,
});

const formatSteps = (isRefill) => ({
  items: [
    {
      image: constants.Images.RefillImages.Step1,
      title: constants.Resources.PrizeCampaign.Step1Title,
      description: constants.Resources.PrizeCampaign.Step1Description,
    },
    {
      image: constants.Images.RefillImages.Step2,
      title: constants.Resources.PrizeCampaign.Step2Title,
      description: constants.Resources.PrizeCampaign.Step2Description,
    },
    {
      image: constants.Images.RefillImages.Step3,
      title: constants.Resources.PrizeCampaign.Step3Title,
      description: constants.Resources.PrizeCampaign.Step3Description,
    },
  ],
  button: isRefill
    ? constants.Resources.PrizeCampaign.StepButton
    : constants.Resources.PrizeCampaign.StepButtonGame,
});

const formatPrizes = () => ({
  title: constants.Resources.PrizeCampaign.PrizesTitle,
  description: constants.Resources.PrizeCampaign.PrizesDescription,
  emoji: constants.Images.RefillImages.EmojiOk,
  images: {
    fonosDesktop: constants.Images.RefillImages.AllPrizes,
    fonosMobile: constants.Images.RefillImages.Phone,
    others: [
      constants.Images.RefillImages.Jumper,
      constants.Images.RefillImages.Earbuds,
      constants.Images.RefillImages.Backpack,
      constants.Images.RefillImages.Pin,
      constants.Images.RefillImages.Mug,
      constants.Images.RefillImages.Gb,
      constants.Images.RefillImages.Mask,
    ],
  },
});

const formatDeliveryModel = (data) => ({
  name: data.firstName,
  surname: data.lastName,
  locationId: data.location.id,
});

const formatPhoneDeliveryModel = (data) => ({
  name: data.firstName,
  surname: data.lastName,
  locationId: data.location.id,
  personCode: data.personalNumber,
  address: data.address,
});

export default {
  getGameInformation,
  formatPhysicalPrizeForm,
  formatSteps,
  formatPrizes,
  formatDeliveryModel,
  formatPhoneDeliveryModel,
};
