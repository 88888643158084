import store from "@/store/index.js";
import constants from "@/constants/index.js";

export function useMetaLanguageLinks() {
  const languageAttribute = "data-language-link";

  const _formatPathFromMeta = (to, language) => {
    const host = location.protocol + '//' + location.host;
    const paths = Object.values(store.state.user.allRoutes[language.toUpperCase()]);
    const aliasPaths = to.meta?.alias?.filter(a => !/:\w/.test(a)) || [];
    if (aliasPaths.length > 0) {
      return host + aliasPaths.find(path => paths.find(x => x === path));
    }

    const path = paths.find(path => path === to.path);
    if (path) {
      return host + path;
    }

    return language === store.getters["user/getLanguage"] ? host + to.path : null;
  };

  const _formatLinks = (to) => {
    const links = [
      constants.LanguagesMap.LT,
      constants.LanguagesMap.EN,
      constants.LanguagesMap.RU
    ].map(language => ({
      language,
      rel: language === constants.DefaultLanguage ? "canonical" : "alternate",
      path: _formatPathFromMeta(to, language),
      breakpoint: null,
    }));

    if (to.meta?.metaLinkBreakpoints?.length > 0) {
      to.meta.metaLinkBreakpoints.forEach(link => {
        if (link.path !== to.path) {
          return;
        }

        links.push({
          language: link.language,
          rel: "alternate",
          path: _formatPathFromMeta({ path: link.path }, link.language),
          breakpoint: link.breakpoint,
        })
      });
    }

    links.push({
      ...links[0],
      language: "x-default",
      rel: "alternate",
      breakpoint: null,
    });

    return links.filter(x => x.path)
      .map(x => {
        const linkElement = document.createElement("link");
        linkElement.rel = x.rel;
        linkElement.hreflang = x.language;
        linkElement.href = x.path;
        linkElement.setAttribute(languageAttribute, "");

        if (x.breakpoint) {
          linkElement.media = `screen and (max-width: ${x.breakpoint}px)`;
        }

        return linkElement;
      });
  };

  const setLinksUp = (to) => {
    const links = _formatLinks(to);

    links.forEach(link => {
      document.head.appendChild(link);
    });
  };

  const cleanLinksUp = () => {
    const links = document.querySelectorAll(`[${languageAttribute}='']`);
    links.forEach(x => {
      x.remove();
    });
  };

  return {
    setLinksUp,
    cleanLinksUp,
  }
};
