import "@/assets/less/fonts.less";
import "@/assets/less/shame.less";
import "promise-polyfill/src/polyfill";
import "@/common/pollyFills.js";
import "@/common/extensions.js";
import { createApp } from "vue";
import VueLazyLoad from "vue3-lazyload";
import VueCookies from "vue-cookies";
import dayjs from "dayjs";
import "dayjs/locale/lt";
import flatpickr from "flatpickr";
import language from "flatpickr/dist/l10n/lt.js";
import PildykApp from "./Pildyk.vue";
import NarsykApp from "./Narsyk.vue";
import pildykRouter from "./router/pildyk";
import narsykRouter from "./router/narsyk";
import store from "@/store/index.js";
import emitter from "@/common/emitter";
import ContentType from "@/common/contentType";
import Loader from "@/common/directives/loader";
import Validation from "@/common/directives/validation";
import ClickOutside from "@/common/directives/clickOutside";
import DoubleHeight from "@/common/directives/doubleHeight";
import ImageResize from "@/common/directives/imageResize";
import PreventHref from "@/common/directives/preventHref";
import RevealOnScroll from "@/common/directives/revealOnScroll";
import Scrollable from "@/common/directives/scrollable";
import TableScroll from "@/common/directives/tableScroll";
import PositionOrder from "@/common/directives/positionOrder";

flatpickr.localize(language.lt);
dayjs.locale("lt");

let app;

if (window.location.pathname.includes("/narsyk")) {
  app = createApp(NarsykApp).use(narsykRouter);
} else {
  store.dispatch("user/setUserDataFromToken");
  app = createApp(PildykApp).use(pildykRouter);
}

VueCookies.config("1d", "/", ".pildyk.lt", true);

app
  .provide("emitter", emitter)
  .use(store)
  .use(VueLazyLoad)
  .directive("loader", Loader)
  .directive("validation", Validation)
  .directive("click-outside", ClickOutside)
  .directive("double-height", DoubleHeight)
  .directive("image-resize", ImageResize)
  .directive("prevent-href", PreventHref)
  .directive("reveal-on-scroll", RevealOnScroll)
  .directive("scrollable", Scrollable)
  .directive("table-scroll", TableScroll)
  .directive("position-order", PositionOrder)
  .use({
    install(application) {
      application.config.globalProperties.$contentType = ContentType;
      application.config.globalProperties.$cookies = VueCookies;
    }
  })
  .mount("#app");