const getItem = (items, type) => {
  for (var i = 0; i < items.length; i++) {
    if (
      (items[i].name === "button-field" ||
        items[i].name === "button-field-preorder" ||
        items[i].name === "multi-button-field") &&
      items[i].name === type
    ) {
      let returnableItems = {};
      for (var itemIndex = 0; items[i].items.length > itemIndex; itemIndex++) {
        let key = items[i].items[itemIndex].name.replace(
          /-([a-z])/g,
          function (g) {
            return g[1].toUpperCase();
          }
        );
        returnableItems[key] = items[i].items[itemIndex].value;
      }
      return returnableItems;
    }
    if (items[i].name === type) {
      return _getItem(items[i]);
    }
  }
  return null;
};

const wrapperContent = (items, type) => {
  if (items.length) {
    return items.find((value) => value.name === type);
  }
  return null;
};

const getByType = (items, type) => {
  if (items.length) {
    return items.find(function (value) {
      return value.type === type;
    });
  }
  return null;
};

const getSkuCodes = (items) => {
  const skuCodes = [];

  items.forEach((element) => {
    const skuField = getItem(element.children, "text-field-sku");

    if (skuField) {
      skuCodes.push(skuField);
    }
  });

  return skuCodes;
};

const _getItem = (item) => {
  if (item.value !== undefined) {
    return item.value;
  }
  if (item.items.length === 1 && item.value === undefined) {
    var returnable = (function (string) {
      try {
        return JSON.parse(string);
      } catch (err) {
        return false;
      }
    })(item.items[0].value);
    if (returnable) {
      return returnable;
    } else {
      return item.items[0].value;
    }
  }
};

export default {
  getItem,
  wrapperContent,
  getByType,
  getSkuCodes,
};
