import constants from "@/constants";

const formatConfirmModal = () => {
  return  {
    image: constants.Images.Confirmation.Url,
    title: constants.Resources.NewDashboard.ConfirmDeactivatedCardReplacementModal,
    decline: constants.Resources.NewDashboard.Cancel,
    confirm: constants.Resources.NewDashboard.Proceed,
  };
};

export default {
  formatConfirmModal
};
