<template>
  <div class="v-consents-campaign-main">
    <div class="v-consents-campaign-main--container">
      <div class="v-consents-campaign-main--title">
        {{ data.title }} <span>{{ data.prize }}</span>
      </div>
      <p class="v-consents-campaign-main--description">
        {{ data.description }}
      </p>
      <img :src="data.image" class="v-consents-campaign-main--image" />
      <t2-button
        class="v-consents-campaign-main--game-rules"
        @click="$emit('game-rules-click')"
      >
        {{ data.gameRulesButton }}
      </t2-button>
    </div>
    <div class="v-consents-campaign-modal--button-wrapper">
      <t2-button
        v-loader="data.button.loader"
        @click="$emit('participate-click')"
      >
        {{ data.button.text }}
      </t2-button>
    </div>
  </div>
</template>
<script>
import T2Button from "@atomic/atoms/Button";

export default {
  components: {
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "game-rules-click",
    "participate-click",
  ],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-consents-campaign-main {
  width: 100%;
  min-height: 100%;
  &--container {
    background: linear-gradient(
      270deg,
      #009edb 1%,
      #009edb 31%,
      #33a8de 57%,
      #65b1e2 86%,
      #78b5e3 100%
    );
    border-radius: 8px 8px 0 0;
    padding: 50px 80px 30px;
    position: relative;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 50px 20px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &--title {
    text-transform: uppercase;
    color: @color-white;
    font-weight: bold;
    font-size: 34px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px;
    span {
      padding: 3px 12px;
      border-radius: 6px;
      background: linear-gradient(90deg, #ffb6ce 0%, #ed5ca1 100%);
      margin-left: 10px;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 22px;
      line-height: 32px;
      max-width: 308px;
      margin-bottom: 20px;
      span {
        padding: 3px 6px;
      }
    }
  }
  &--description {
    color: @color-white;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin: 0 0 20px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 16px;
      line-height: 20px;
      max-width: 308px;
      margin-bottom: 20px;
    }
  }
  &--image {
    max-width: 100%;
    height: auto;
    padding: 15px;
    max-height: 400px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      max-width: 275px;
    }
  }
  &--game-rules {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background: rgba(255, 255, 255, 0.1);
    font-size: 14px;
    line-height: 15px;
    font-weight: normal;
    text-transform: none;
    min-width: 150px;
    padding: 10px;
    letter-spacing: 0;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      position: static;
      margin: 0 auto;
    }
  }
}
</style>
