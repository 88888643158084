import { ref } from 'vue';
import { useMediaQuery } from '@vueuse/core'

export function useBreakpoints() {
  const isLg = ref(useMediaQuery('(max-width: 1199px)'))

  return {
    isSm: ref(useMediaQuery('(max-width: 768px)')),
    isMd: ref(useMediaQuery('(max-width: 1023px)')),
    isLg,
    isXl: ref(!isLg.value),
  }
};
