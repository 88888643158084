const cart = {
  namespaced: true,
  state: {
    data: null,
  },
  mutations: {
    setData(state, value) {
      state.data = value;
    },
  },
  actions: {
    resetData({ commit }) {
      commit("setData", []);
    },
  },
  getters: {
    getData: (state) => {
      return state.data;
    },
  },
};

export default cart;
