<template>
  <div class="v-information-banner">
    <div
      class="v-information-banner--wrapper"
      :class="{
        'v-information-banner---warning': warning,
        'v-information-banner---error': error,
        'v-information-banner---info': info,
      }"
    >
      <div class="v-information-banner--main-content">
        <div class="v-information-banner--icon">
          <t2-svg :iconId="iconId"></t2-svg>
        </div>
        <p class="v-information-banner--text" v-html="text"></p>
        <span class="v-information-banner--close" @click="$emit('banner-close')">
          <t2-svg icon-id="close"></t2-svg>
        </span>
      </div>
      <p
        v-if="description"
        class="v-information-banner--description"
        v-html="description"
      />
    </div>
  </div>
</template>
<script>
import T2Svg from "@atomic/atoms/Svg";

export default {
  components: {
    T2Svg,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['banner-close'],
  computed: {
    iconId() {
      if (this.warning) {
        return "banner-warning";
      } else if (this.error) {
        return "banner-error";
      }

      return "banner-info";
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-information-banner {
  max-width: 1250px;
  padding: 0 10px;
  width: 100%;
  color: @color-white;
  @media screen and (max-width: @media-breakpoint-sm-max) {
    top: 60px;
  }

  &--wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 20px 30px 20px 40px;
    background-color: #009edb;
    border-radius: 4px;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 12px;
      line-height: 18px;
      padding: 10px 20px;
    }
  }

  &--main-content {
    display: flex;
    align-items: center;
  }

  &--description {
    max-width: 993px;
    font-size: 16px;
    line-height: 22px;
    margin-left: 52px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 12px;
      line-height: 18px;
      margin-left: 0;
    }
  }

  &--text {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    margin: 0;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &--icon {
    min-width: 27px;
    max-width: 27px;
    height: 27px;
    margin-right: 25px;

    svg {
      fill: white;
      width: 100%;
      height: 100%;
    }
  }

  &--close {
    cursor: pointer;
    margin-left: auto;

    svg {
      fill: @color-white;
      width: 11px;
      height: 11px;
      margin-left: 20px;
    }
  }

  &---warning {
    background-color: #888B95;
    color: @color-white;

    svg {
      fill: @color-white;
    }

    .v-information-banner--description a {
      color: @color-blue;
      text-decoration: underline;

      &:hover {
        color: @color-darkred;
      }
    }
  }

  &---error {
    background-color: #EC1940;
    color: @color-white;

    svg {
      fill: @color-white;
    }
  }

  &---info {
    background-color: #0A4E9B;
    color: @color-white;

    svg {
      fill: @color-white;
    }
  }
}
</style>
