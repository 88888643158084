<template>
  <t2-privacy-policy-modal
    v-if="loaded"
    :data="modalData"
    @save-policy="handleSavePolicy"
  ></t2-privacy-policy-modal>
</template>

<script>
import T2PrivacyPolicyModal from "@atomic/organisms/Modals/PrivacyPolicyModal";
import dashboardApi from "@/views/dashboard/api/dashboard.js";
import constants from "@/constants";
import PolarModalService from "@/services/polarModal";

export default {
  name: "T2Consents",
  components: {
    T2PrivacyPolicyModal,
  },
  inject: ["emitter"],
  data() {
    return {
      modalData: {
        title: constants.Resources.Consents.PolicyTitle,
        items: [],
        description: constants.Resources.Consents.PolicyDescription,
        moreInfo: constants.Resources.Consents.PolicyMoreInfo,
        buttons: {
          agreeSelected: constants.Resources.Consents.AgreeSelectedButton,
          agreeAll: constants.Resources.Consents.AgreeAllButton,
          loader: false,
        },
      },
      loaded: false,
    };
  },
  created() {
    this.getConsentsData();
  },
  methods: {
    formatData(data) {
      data.forEach((consent) => {
        if (consent.consentAgreementStatus === 0) {
          consent.agreed = null;
        } else {
          consent.agreed = consent.consentAgreementStatus === 1;
        }
      });

      return data;
    },
    formatConsentUpdateData(consents) {
      return consents.map((consent) => {
        return {
          consentAgreementStatus: consent.agreed
            ? constants.ConsentStatus.Agree
            : constants.ConsentStatus.Disagree,
          title: consent.title,
          description: consent.description,
          consentType: consent.consentType,
        };
      });
    },
    getConsentsData() {
      return dashboardApi
        .getConsents()
        .then((response) => {
          this.modalData.items = this.formatData(response.data);
          this.loaded = true;
          this.$nextTick(() => {
            this.emitter.emit(constants.ModalControl.Open, {
              id: constants.Modals.PrivacyPolicy,
            });
          });
        })
        .catch((response) =>
          PolarModalService.handleErrorResponse(this, response)
        ).finally(() => {
          this.modalData.loader = false;
        });
    },
    handleSavePolicy(data) {
      this.modalData.buttons.loader = true;
      var formattedData = this.formatConsentUpdateData(data);

      return dashboardApi.updateConsents(formattedData).then(() => {
        history.replaceState(
          {},
          document.title,
          window.location.href.split("#")[0]
        );
        this.emitter.emit(constants.ModalControl.Close, {
          id: constants.Modals.PrivacyPolicy,
        });
      });
    },
  },
};
</script>
