import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import constants from "@/constants";

const isTokenCloseToExpiration = (token) => {
  if (token) {
    const expirationInSeconds = _getTokenExpirationInSeconds(token);

    return (
      expirationInSeconds > 0 &&
      expirationInSeconds < constants.TokenExpirationWindow * 60
    );
  }

  return false;
};

const isTokenExpired = (token) => {
  return _getTokenExpirationInSeconds(token) <= 0;
};

const decodeToken = (token) => {
  return jwtDecode(token);
};

const _getTokenExpirationInSeconds = (token) => {
  const decoded = decodeToken(token);

  return dayjs.unix(decoded.exp).diff(dayjs(), "s");
};

export default {
  isTokenCloseToExpiration,
  isTokenExpired,
  decodeToken,
};
