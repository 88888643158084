const common = {
  namespaced: true,
  state: {
    previousRoute: null,
    mainResourcesLoaded: false,
    mainConfigurationLoaded: false,
    newVersionAvailable: false,
    isNotFound: false,
  },
  mutations: {
    setPreviousRoute(state, value) {
      state.previousRoute = value;
    },
    setMainResourcesLoaded(state, value) {
      state.mainResourcesLoaded = value;
    },
    setMainConfigurationLoaded(state, value) {
      state.mainConfigurationLoaded = value;
    },
    setNewVersionAvailable(state, value) {
      state.newVersionAvailable = value;
    },
    setIsNotFound(state, value) {
      state.isNotFound = value;
    }
  },
  getters: {
    getPreviousRoute: (state) => {
      return state.previousRoute;
    },
    isMainResourcesLoaded: (state) => {
      return state.mainResourcesLoaded;
    },
    isMainConfigurationLoaded: (state) => {
      return state.mainConfigurationLoaded;
    },
    isNewVersionAvailable: (state) => {
      return state.newVersionAvailable;
    },
    isNotFound: (state) => {
      return state.isNotFound;
    },
  },
};

export default common;
