import WhaleboneApi from "@/views/whalebone-parent/api";

const whalebone = {
  state: {
    requestPromise: null,
  },
  namespaced: true,
  mutations: {
    setRequestPromise(state, value) {
      state.requestPromise = value;
    }
  },
  actions: {
    fetchGeneralStatistics({ commit, state }) {
      if (state.requestPromise) {
        return state.requestPromise;
      }

      const fetchPromise = WhaleboneApi.getGeneralStatistics();
      commit("setRequestPromise", fetchPromise);

      return fetchPromise;
    }
  }
};

export default whalebone;
