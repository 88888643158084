<template>
  <t2-modal
    :id="pole.id"
  >
    <div
      class="polar--icons-wrapper"
      :class="{ 'polar--icons-wrapper---bigger': pole.imageUrl }"
    >
      <img
        :src="getMainIconId"
        class="polar--main-icon"
        :class="{ 'polar--main-icon---bigger': pole.imageUrl }"
      />
    </div>
    <slot></slot>
  </t2-modal>
</template>
<script>
import T2Modal from "@atomic/molecules/Modal/Modal.vue";
import constants from "@/constants/index.js";
export default {
  components: {
    T2Modal,
  },
  props: {
    pole: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getMainIconId() {
      if (this.pole && this.pole.imageUrl) {
        return this.pole.imageUrl;
      }

      return this.pole && this.pole.id === "success"
        ? constants.Images.Success.Url
        : constants.Images.Error.Url;
    },
  },
};
</script>
<style lang="less">
.polar {
  &--icons-wrapper {
    width: 150px;
    height: 130px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    margin: 0 auto 35px;
  }
  &--icons-wrapper---bigger {
    height: 170px;
  }
  &--main-icon {
    width: 110px;
  }
  &--main-icon---bigger {
    width: 170px;
  }
  &--decoration-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 100%;
    opacity: 1;
    transition: transform 0.75s ease-in-out, opacity 0.75s ease-in-out;

    &:nth-child(1) {
      width: 13px;
      height: 13px;
      left: 0;
      top: 75%;
      animation: 3s ease-in infinite pulse-decoration-icon,
        6s ease-in-out infinite fade-decoration-icon;
    }
    &:nth-child(2) {
      width: 16px;
      height: 16px;
      left: 15%;
      top: 15%;
      animation: 4s ease-in infinite pulse-decoration-icon,
        8s ease-in-out infinite fade-decoration-icon;
      animation-delay: 250ms;
    }
    &:nth-child(3) {
      width: 18px;
      height: 18px;
      left: 80%;
      top: 50%;
      animation: 5s ease-in infinite pulse-decoration-icon,
      9s ease-in-out infinite fade-decoration-icon;
      animation-delay: 500ms;
    }
    &:nth-child(4) {
      width: 17px;
      height: 17px;
      left: 90%;
      top: 85%;
      animation: 2s ease-in infinite pulse-decoration-icon,
        7s ease-in-out infinite fade-decoration-icon;
      animation-delay: 200ms;
    }
  }
  &--heading {
    margin: 0 0 10px 0 !important;
    color: #061f2d !important;
    font-weight: 700;
    font-size: 32px !important;
    text-align: center;
  }
  &--sub-heading {
    margin: 0 !important;
    color: #061f2d !important;
    text-align: center;
    font-size: 16px !important;
    max-width: 100%;
  }
  &--actions {
    display: flex;
    padding: 30px 20px 0 30px;
    justify-content: center;
    flex-flow: column nowrap;
  }
  &--actions .v-button:not(:last-child) {
    margin-bottom: 10px;
  }
}
@keyframes pulse-decoration-icon {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.55);
  }
  50% {
    transform: scale(1.25);
  }
  75% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes fade-decoration-icon {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
