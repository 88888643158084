<template>
  <div v-if="message" class="v-input--error---container">
    <div class="v-input--error">{{ message }}</div>
    <t2-icon icon-id="exclamation-mark" custom-class="v-input--error---icon" />
  </div>
</template>
<script>
import T2Icon from "@atomic/molecules/Icon";
export default {
  components: {
    T2Icon,
  },
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-input {
  &--error {
    display: block;
    color: @color-red;
    padding: 4px 0;
    font-size: 13px;
    text-align: left;
    min-height: 23px;

    &---container {
      bottom: -40px;
      left: 0;
      border: 1px transparent;
      border-radius: 4px;
      padding: 2px 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:before {
        position: absolute;
        top: -5px;
        left: 20px;
        transform: rotate(45deg);
        content: "";
        height: 10px;
        width: 10px;
        z-index: 0;
      }
    }
    &---icon {
      margin: 0 0 0 10px;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid @color-red;
      background-color: @color-red;
      > svg {
        fill: @color-white;
      }
    }
  }
}
</style>
