import axiosClient from "@/common/axiosClient";

const getUserData = () => {
  return axiosClient.get("/profile/v2/user");
};

const getConsents = () => {
  return axiosClient.get("/consent/consents");
};

const updateConsents = (consents) => {
  return axiosClient.put("/consent/consents", consents);
};

const sendEmailValidation = () => {
  return axiosClient.post("/profile/email/validate/send");
};

const updateUserData = (data) => {
  return axiosClient.put("/profile/user", data);
};

export default {
  getUserData,
  getConsents,
  updateConsents,
  sendEmailValidation,
  updateUserData,
};
