const createLoader = () => {
  const loaderEl = document.createElement("div");

  loaderEl.classList.add("loading-circle");

  for (let i = 0; i < 8; i++) {
    const loaderDot = document.createElement("div");

    loaderDot.classList.add("circle");
    loaderEl.prepend(loaderDot);
  }

  return loaderEl;
};

const searchLoaderEl = (collection) => {
  for (const el of collection) {
    if (el.className === "loading-circle") {
      return el;
    }
  }

  return null;
};

export default {
  beforeMount(el, binding) {
    if (binding.value) {
      el.classList.add("loading");
      el.appendChild(createLoader());
    }
  },
  beforeUpdate(el, binding) {
    const loaderEl = searchLoaderEl(el.children);

    if (binding.value) {
      el.classList.add("loading");

      if (loaderEl === null) {
        el.appendChild(createLoader());
      }
    } else {
      if (loaderEl !== null) {
        loaderEl.outerHTML = "";
      }

      el.classList.remove("loading");
    }
  },
};
