<template>
  <t2-modal id="information-modal" class="v-information-modal">
    <div class="v-information-modal--wrapper">
      <div class="v-information-modal--content">
        <h1>{{ data.title }}</h1>
        <p>{{ data.description }}</p>
        <img :src="data.image" />
      </div>
      <div class="v-information-modal--button">
        <t2-button
          :secondary="data.button != null"
          @click="emitter.emit('close-modal', { id: 'information-modal' })"
        >
          {{ data.buttonClose }}
        </t2-button>
        <t2-button v-if="data.button" @click="$emit('button-click')">
          {{ data.button }}
        </t2-button>
      </div>
    </div>
  </t2-modal>
</template>
<script>
import T2Modal from "@atomic/molecules/Modal";
import T2Button from "@/atomic-components/atoms/Button";

export default {
  components: {
    T2Modal,
    T2Button,
  },
  inject: ["emitter"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["button-click"]
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";

.v-information-modal {
  .popup {
    &--dialog {
      max-width: 650px;
      padding: 0;
    }

    &--content {
      padding: 0;
      position: relative;
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 5;
    }
  }

  &--wrapper {
    width: 100%;
  }

  &--content {
    padding: 50px 90px 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px 8px 0 0;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 30px 30px 0;
    }

    h1,
    p {
      text-align: center;
    }

    h1 {
      color: @color-blue;
      font-size: 34px;
      line-height: 44px;
      margin: 0 0 30px;
      text-transform: uppercase;

      @media screen and (max-width: @media-breakpoint-sm-max) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 20px;
      }
    }

    p {
      color: @color-darkblue;
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 40px;

      @media screen and (max-width: @media-breakpoint-sm-max) {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 25px;
      }
    }

    img {
      max-height: 200px;
      height: 100%;
      width: auto;
    }
  }

  &--button {
    text-align: center;
    padding: 20px 10px;
    box-shadow: 0px -7px 24px rgba(140, 151, 174, 0.12);
    width: 100%;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding: 10px;
    }

    button {
      margin: 0 7px;

      @media screen and (max-width: @media-breakpoint-sm-max) {
        max-width: 300px;
        width: 100%;
        margin: 5px 0;
      }
    }
  }
}
</style>
