const loader = document.getElementById("v-web-loader");

const hideLoader = () => {
  return new Promise((resolve) => {
    document.getElementById("v-web-loader").style.opacity = "0";
    setTimeout(() => {
      document.getElementById("v-web-loader").style.display = "none";
      resolve();
    }, 200);
  });
};

const showLoader = () => {
  loader.style.display = "flex";
  loader.style.opacity = "1";
};

export default {
  hideLoader,
  showLoader,
};
