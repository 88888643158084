import PaymentsApi from "@/api/payments";

const payments = {
  namespaced: true,
  state: {
    cardDetailsPromise: null,
  },
  mutations: {
    setCardDetailsPromise(state, value) {
      state.cardDetailsPromise = value;
    },
    resetCardDetails(state) {
      state.cardDetailsPromise = null;
    }
  },
  actions: {
    fetchCardDetails({ commit, state }, reload = false) {
      if (!reload && state.cardDetailsPromise !== null) {
        return state.cardDetailsPromise;
      }

      const fetchPromise = PaymentsApi.getCreditCard(); 
      commit("setCardDetailsPromise", fetchPromise);

      return fetchPromise;
    }
  },
};

export default payments;
