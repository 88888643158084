import axiosClient from "@/common/axiosClient";

const getDiscountsList = () => {
  return axiosClient.get("/corporate/discounts");
};

const getHomeDiscountsList = () => {
  return axiosClient.get("/corporate/discounts?home=true");
};

const getHeader = () => {
  return axiosClient.get(`/corporate/elements/header`);
};

const getFooter = () => {
  return axiosClient.get(`/corporate/elements/footer`);
};

const getSlides = () => {
  return axiosClient.get(`/corporate/elements/slides`);
};

const getForeignCallRates = () => {
  return axiosClient.get(`/corporate/foreign-call-rates`);
};

const getPageMetadata = (alias) => {
  return axiosClient.get(`/corporate/pages/metadata?alias=${alias}`);
};

const getPageById = (pageId) => {
  return axiosClient.get(`/corporate/pages/${pageId}`);
};

const getPagePreviewData = (previewId) => {
  return axiosClient.get(`/corporate/pages/${previewId}/preview`);
};

const getTranslations = (categories) => {
  return axiosClient.get(
    `/corporate/translations?categories=${categories.join("&categories=")}`
  );
};

const getShortLinks = () => {
  return axiosClient.get(`/corporate/shortlinks`);
};

const getShortDevicesInformationBySkuCodes = (skuCodes, isESim = false) => {
  let url = `/corporate/devices?skuCode=${skuCodes.join("&skuCode=")}`;

  if (isESim) {
    url = url + `&esim=${isESim}`;
  }

  return axiosClient.get(url);
};

const getDeviceInformationBySkuCode = (skuCode) => {
  return axiosClient.get(`/corporate/device/${skuCode}`);
};

const getDeviceStockBySkuCode = (skuCode) => {
  return axiosClient.get(`/corporate/device/${skuCode}/stock`);
};

export default {
  getDiscountsList,
  getHomeDiscountsList,
  getHeader,
  getFooter,
  getSlides,
  getForeignCallRates,
  getPageMetadata,
  getPageById,
  getPagePreviewData,
  getTranslations,
  getShortLinks,
  getShortDevicesInformationBySkuCodes,
  getDeviceInformationBySkuCode,
  getDeviceStockBySkuCode,
};
