export default {
  beforeMount: function(el, binding) {
    setTimeout(() => {
      el.clickOutsideEvent = function(event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
    }, 200);
  },
  beforeUnmount: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
