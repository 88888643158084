import constants from "@/constants";
import localConstants from "../constants";

const formatGameBanner = () => ({
  component: localConstants.Components.Game,
  componentData: {
    title: constants.Resources.PrizeCampaign.BannerTitle,
    description: constants.Resources.PrizeCampaign.BannerDescription,
    button: constants.Resources.PrizeCampaign.BannerButton,
    imageDesktop: constants.Images.RefillImages.BannerDesktop,
    imageMobile: constants.Images.RefillImages.BannerMobile,
    iconId: constants.Icons.Close,
    buttonLoader: false,
  },
});

const formatWinBanner = (prize) => ({
  component: localConstants.Components.Prize,
  componentData: {
    title: constants.Resources.PrizeCampaign.WinBannerTitle
      .replace("{0}", prize),
    description: constants.Resources.PrizeCampaign.WinBannerDescription,
    button: constants.Resources.PrizeCampaign.WinBannerButton,
    image: constants.Images.RefillImages.ConfettiBanner,
    emoji: constants.Images.RefillImages.Mascott3,
    iconId: constants.Icons.Close,
    buttonLoader: false,
  },
});

export default {
  formatGameBanner,
  formatWinBanner,
};
