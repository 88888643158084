<template>
  <component
    :is="tag"
    :class="classes"
    class="heading"
  >
    <slot></slot>
  </component>
</template>
<script>
export default {
  props: {
    customClass: {
      type: [String, Array, Object],
      default: ""
    },
    level: {
      type: [String, Number],
      default: ""
    },
  },
  computed: {
    classes() {
      return [
        this.level ? `heading--${this.level}` : "",
        this.customClass || ""
      ].filter(x => x);
    },
    tag() {
      return `h${this.level || "1"}`;
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@import (reference) "~@/assets/less/fonts.less";
.heading {
  .BP-bold;
  color: @color-dark;
  font-size: 32px;
  line-height: normal;
  margin-bottom: 25px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  &--1 {
    @media screen and (max-width: @media-tablet-portrait) {
      font-size: 20px;
    }
  }
  &--2 {
    color: @color-blue;
    font-size: 24px;
    margin-bottom: 25px;
    @media screen and (max-width: @media-tablet-portrait) {
      font-size: 16px;
    }
  }
  &--3 {
    color: @color-blue;
    font-size: 18px;
    letter-spacing: 0.05em;
    @media screen and (max-width: @media-tablet-portrait) {
      font-size: 16px;
    }
  }
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
}

[id] {
  scroll-margin: 100px;
}
</style>
