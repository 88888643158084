import CorporateApi from "@/api/corporate";

const shortLinks = {
  namespaced: true,
  state: {
    data: null,
  },

  mutations: {
    setData(state, data) {
      state.data = data;
    },
  },

  actions: {
    fetch({ commit, getters }) {
      if (getters.getData) {
        return Promise.resolve();
      }

      return CorporateApi.getShortLinks().then((response) => {
        commit("setData", response.data.data);
      });
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },

    tryGetNewPath: (state) => (path) => {
      return state.data?.find(
        (x) =>
          (x.shortlink.substring(0, 1) === "/"
            ? x.shortlink
            : "/" + x.shortlink) === path
      )?.path;
    },
  },
};

export default shortLinks;
