export default {
  GameStatus: {
    Ready: "ready",
    Started: "started",
    Finished: "finished"
  },
  PrizeTypes: {
    Plan: 1,
    Vas: 2,
    Physical: 3,
    PhysicalSpecial: 4,
    Nothing: 5,
  },
};
