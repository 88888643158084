export default {
  Dashboard: "/pagrindinis",
  Refill: "/papildymas",
  Plans: "/planai",
  Help: "/pagalba",
  PlanOrderConfirmation: "/planai/patvirtinimas",
  OrderConfirmation: "/pagrindinis/patvirtinimas",
  PricePlansCancellation: "/pagrindinis/planu-atsisakymas",
  VasOrderConfirmation: "/papildomos-paslaugos/patvirtinimas",
  PortOut: "/pagrindinis/numerio-perkelimas",
  Report: "/pagrindinis/isklotine",
  Settings: "/pagrindinis/nustatymai",
  Whalebone: "/pagrindinis/narsymo-apsauga",
  WhaleboneReport: {
    Path: "/pagrindinis/narsymo-apsauga/suvestine",
    Name: "suvestine",
    ChildPath: "suvestine",
  },
  WhaleboneSettings: {
    Path: "/pagrindinis/narsymo-apsauga/nustatymai",
    Name: "nustatymai",
    ChildPath: "nustatymai",
  },
  MyRequests: "/pagrindinis/mano-uzklausos",
  Invoice: "/pagrindinis/saskaitos-ir-mokejimai",
  Login: "/prisijungti",
  Registration: "/registruotis",
  PasswordRecovery: "/slaptazodzio-keitimas",
  Logout: "/atsijungti",
  RefillStatusShortcut: "/refill",
  RefillStatus: "/refill/:status",
  Campaign: {
    Path: "/kampanija/ausines/:transactionId?",
    Name: "campaign"
  },
  CampaignSuccess: "/kampanija/sekme",
  CampaignFailure: "/kampanija/panaudota",
  CampaignItemOrderStatus: 
  {
    Path: "/kampanija/ausines/uzsakymas/:status",
    Name: "campaign-item-order-status"
  },
  SummerCampaign2023: "/akcijos/vasara",
  SummerCampaign2023Game: "/akcijos/vasara/traukimas",
  SummerCampaign2023Rules: "/akcijos/vasara/taisykles",
  SummerCampaign2023Purchase: "/akcijos/vasara/pirkimas",
  SummerCampaign2023Merch: "/akcijos/vasara/prekes",
  SummerCampaign2023SuccessStatus: {
    Path: "/akcijos/vasara/laimejimas/:status",
    Name: "summer-campaign-2023-status"
  },
  CampaignItemOrderSuccess: "/plano-pirkimas/:campaignName/:transactionId",
  EmailConfirmation: "/epasto-patvirtinimas",
  Home: "/", 
  Discounts: "/akcijos",
  ForeignCallRates: "/paslaugos/skambuciai-i-uzsieni-ir-uzsienyje",
  ForeignCallRatesAlias: "/tarptautiniai",
  ForeignCallRatesAlias2: "/uzsienis",
  ForeignCallRatesAlias3: "/es",
  PildykIsCheapest: "/akcijos/vartotojai-tvirtina-pildyk-pigiausia",
  EuPlans: "/paslaugos/tarptinklinis-rysys-es",
  StartUsingPildyk: "/naudinga-zinoti/tapk-pildyk",
  ForeignInternetPlans: "/paslaugos/ismaisyk-pasauli-interneta",
  CampaignLanding: "/pildyk-ausines",
  CampaignRules: "/akcijos/xbox-taisykles",
  AwardsCampaignInfo: "/pagrindinis/pildyk-taskai",
  AwardsCampaignWheel: "/pildyk-taskai/loterija/:prize",
  AwardsCampaignSuccess: "/pildyk-taskai/sekmingas-uzsakymas",
  AwardsCampaignDelivery: "/pildyk-taskai/pristatymas/:winning",
  AwardsCampaignLanding: "/akcijos/pildyk-taskai",
  AwardsCampaignRules: "/akcijos/pildyk-taskai-taisykles",
  AppInformation: "/paslaugos/mano-pildyk-programele",
  SettingsPersonalInformation: {
    Path: "/pagrindinis/nustatymai/asmenine-informacija",
    Name: "asmenine-informacija",
    ChildPath: "asmenine-informacija",
  },
  SettingsPaymentCards: {
    Path: "/pagrindinis/nustatymai/mokejimo-korteles",
    Name: "mokejimo-korteles",
    ChildPath: "mokejimo-korteles",
  },
  SettingsLoginInfo: {
    Path: "/pagrindinis/nustatymai/prisijungimo-informacija",
    Name: "prisijungimo-informacija",
    ChildPath: "prisijungimo-informacija",
    Hashes: {
      ChangePassword: "slaptazodzio-keitimas",
    }
  },
  SettingsLoginHistory: {
    Path: "/pagrindinis/nustatymai/prisijungimu-istorija",
    Name: "prisijungimu-istorija",
    ChildPath: "prisijungimu-istorija",
  },
  MobileGameRules: "/akcijos/zaidimo-taisykles",
  PagePreview: "/preview",
  CoverageMap: "/paslaugos/tinklas",
  Narsyk: "/narsyk",
  NarsykPlansConfirmation: "/narsyk/patvirtinimas",
  NarsykUserNotFound: "/narsyk/nerastas",
  NarsykUnknownError: "/narsyk/nepavyko",
  NarsykAutoLoginDisabled: "/narsyk/isjungtas",
  NarsykSmsChallenge: "/narsyk/issukis",
  NarsykSmsChallengeThrottle: "/narsyk/issukis/klaida",
  NarsykAutoLogin: "/narsyk/auth/auto-login",
  P2pPlans: "/paslaugos/gauk-plana-dovanu",
  ChristmasGameRules: "/akcijos/zaidimo-taisykles",
  Maintenance: "/maintenance.htm",
  IsicLanding: "/akcijos/isic",
  IsicForm: "/akcijos/isic/forma",
  SelfcareAutoLoginSmsChallenge: "/prisijungti/issukis",
  Devices: "/prekes",
  DeviceInformation: "/prekes/:skuUrl",
  ESimLanding: "/esim",
  ESimOldLanding: "/e-sim",
  ESimPurchase: "/esim/pirkimas",
  ESimOldPurchase: "/e-sim/pirkimas",
  ESimPurchaseQr: {
    Path: "/esim/pirkimas/qr/:transactionId",
    Name: "e-sim-purchase-qr"
  },
  ESimOldPurchaseQr: "/e-sim/pirkimas/qr/:transactionId",
  ESimPurchaseQrShortcut: "/esim/pirkimas/qr",
  ESimChangeInformation: "/pagrindinis/esim",
  ESimOldChangeInformation: "/pagrindinis/e-sim",
  ESimChange: "/esim/keitimas",
  ESimOldChange: "/e-sim/keitimas",
  WhyPildyk: "/kodel-pildyk",
  RefillCampaign: "/6000prizu",
  RefillCampaignGame: "/6000prizu/zaidimas",
  RefillCampaignSuccess: {
    Name: "fonos-campaign-success",
    Path: "/6000prizu/zaidimas/:status"
  },
  CampaignRulesLink: "/akcijos/taisykles",
  LangProxy: "/l",
  EN: {
    Login: "/en/log-in",
    Plans: "/en/plans",
    Refill: "/en/refill",
    Help: "/en/help",
    PlanOrderConfirmation: "/en/plans/confirmation",
    ForeignCallRates: "/en/services/foreign-call-rates",
    Home: "/en",
    StartUsingPildyk: "/en/join-pildyk",
    ForeignInternetPlans: "/en/services/explore-world-internet",
    MobileGameRules: "/en/good-to-know/game-rules",
    P2pPlans: "/en/good-to-know/get-plan-as-gift",
    CampaignLanding: "/en/discounts/refill-and-win",
    ChristmasGameRules: "/en/game-rules",
    IsicLanding: "/en/discounts/isic",
    IsicForm: "/en/discounts/isic/form",
    PasswordRecovery: "/en/password-remind",
    Registration: "/en/register",
    SelfcareAutoLoginSmsChallenge: "/en/log-in/challenge",
    ESimLanding: "/en/esim",
    ESimOldLanding: "/en/e-sim",
    ESimPurchase: "/en/esim/purchase",
    ESimOldPurchase: "/en/e-sim/purchase",
    ESimPurchaseQr: "/en/esim/purchase/qr/:transactionId",
    ESimOldPurchaseQr: "/en/e-sim/purchase/qr/:transactionId",
    WhyPildyk: "/en/why-pildyk",
  },
  RU: {
    Login: "/ru/log-in",
    Plans: "/ru/plans",
    Refill: "/ru/refill",
    Help: "/ru/help",
    PlanOrderConfirmation: "/ru/plans/confirmation",
    ForeignCallRates: "/ru/services/foreign-call-rates",
    Home: "/ru",
    StartUsingPildyk: "/ru/join-pildyk",
    ForeignInternetPlans: "/ru/services/explore-world-internet",
    MobileGameRules: "/ru/good-to-know/game-rules",
    P2pPlans: "/ru/good-to-know/get-plan-as-gift",
    CampaignLanding: "/ru/discounts/refill-and-win",
    ChristmasGameRules: "/ru/game-rules",
    IsicLanding: "/ru/discounts/isic",
    IsicForm: "/ru/discounts/isic/form",
    PasswordRecovery: "/ru/password-remind",
    Registration: "/ru/register",
    SelfcareAutoLoginSmsChallenge: "/ru/log-in/challenge",
    ESimLanding: "/ru/esim",
    ESimOldLanding: "/ru/e-sim",
    ESimPurchase: "/ru/esim/purchase",
    ESimOldPurchase: "/ru/e-sim/purchase",
    ESimPurchaseQr: "/ru/esim/purchase/qr/:transactionId",
    ESimOldPurchaseQr: "/ru/e-sim/purchase/qr/:transactionId",
    WhyPildyk: "/ru/why-pildyk",
  },
  UkraineCampaign: "/slava-ukraini",
};
