import store from "@/store";

const showCookieBot = () => {
  const cookieBotScript = document.getElementById("Cookiebot");
  cookieBotScript.dataset.culture = store.getters["user/getLanguage"];
  
  window.Cookiebot.renew();
};

export default {
  showCookieBot,
};
