<template>
  <div
    id="cybot-overlay"
    class="v-cybot-overlay"
    :class="{ 'v-cybot-overlay---active': isActive }"
  >
    <div class="v-cybot-container">
      <div class="v-cybot-background"></div>
      <div v-loader="true" class="v-cybot-banner" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";
.v-cybot-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  display: none;
  pointer-events: none;
  z-index: 99998;
}

.v-cybot-overlay---active {
  display: block;
  pointer-events: all;
  opacity: 1;
}

.v-cybot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 15px;
}

.v-cybot-background {
  background: linear-gradient(180deg, rgba(0, 17, 35, 0) 0%, #000307 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
}

.v-cybot-banner {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  height: 204px;
  margin: auto 0 32px;
  padding: 32px 80px 32px 47px;
  display: flex;
  align-items: center;
  box-shadow: 0px 15px 31px rgba(140, 151, 174, 0.2);
  position: relative;

  @media screen and (max-width: 1000px) {
    height: 215px;
    padding: 25px 50px 25px 30px;
  }

  @media screen and (max-width: @media-breakpoint-sm-max) {
    height: 358px;
    padding: 30px 20px 14px;
  }
}
</style>