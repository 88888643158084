import constants from "@/constants";
import axiosClient from "@/common/axiosClient";

const reloadResources = () => {
  return getResources(Object.keys(constants.Resources), true);
};

const getResources = (resources, reload = false) => {
  if (typeof resources === "string") {
    return _loadSingleGroup(reload, resources);
  }

  if (Array.isArray(resources)) {
    if (!reload) {
      var alreadyLoadedGroups = resources.filter(
        (e) => Object.keys(constants.Resources).indexOf(e) !== -1
      );

      if (alreadyLoadedGroups.length > 0) {
        alreadyLoadedGroups.forEach((group) => {
          var index = resources.indexOf(group);

          if (index > -1) {
            resources.splice(index, 1);
          }
        });
      }
    }

    if (resources.length > 0) {
      return axiosClient
        .get(`/resource/groups?groups=` + resources.join("&groups="))
        .then((response) => {
          constants.Resources = { ...constants.Resources, ...response.data };
        });
    }
  }

  return Promise.resolve();
};

const _loadSingleGroup = (reload, group) => {
  if (!reload && Object.keys(constants.Resources).indexOf(group) > -1) {
    return Promise.resolve();
  }

  return axiosClient
    .get(`/resource/groups?groups=${group}`)
    .then((response) => {
      constants.Resources = { ...constants.Resources, ...response.data };
    });
};

export default {
  getResources,
  reloadResources,
};
