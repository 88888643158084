export default {
  HeroSection: "template-1",
  StepsSection: "template-2",
  BlockWithIcons: "template-3",
  BlockWithIconsWrapper: "wrapper-template-3",
  TextBlockForLegal: "template-4",
  BlockWithBlueHeadings: "template-5",
  BlockWithBlueHeadingsWrapper: "wrapper-template-5",
  OneCtaWithRequiredHeading: "template-7",
  BlueSquareBlockWith3RequiredFieldsAndCta: "template-8",
  BlueSquareBlockWith3RequiredFieldsAndCtaWrapper: "wrapper-template-8",
  ListWithTicks: "template-9",
  ListWithTicksWrapper: "wrapper-template-9",
  TextWithBlueBackgroundAndCta: "template-10",
  TextWith2Columns: "template-11",
  SideIcon: "template-12",
  SideIconWithTextExpand: "template-13",
  YoutubeVideoBlock: "template-14",
  BlueBlockWithImageHeadingDescriptionAndCta: "template-16",
  BlueBlockWithImageHeadingDescriptionAndCtaWrapper: "wrapper-template-16",
  FullSizedImage: "template-18",
  MerchandisePreorder: "template-19",
  MerchandisePreorderWrapper: "wrapper-template-19",
  HeroSectionWithPreorder: "template-21",
  WrapperWithShortTextAndCtaOrPreorderCta: "template-24",
  WrapperWithShortTextAndCtaOrPreorderCtaWrapper: "wrapper-template-24",
  TabsForMerchandise: "template-25",
  TabsForMerchandiseWrapper: "wrapper-template-25",
  TabsForMerchandiseWrapperItems: "wrapper-template-25-items",
  CtaButton: "template-26",
  CtaButtonWrapper: "wrapper-template-26",
  BlockWithHeadingAndListItemsV2: "template-27",
  BlockWithHeadingAndListItemsV2Wrapper: "wrapper-template-27",
  HeaderWithDeliveryDetailsModal: "template-28",
  SectionWithTitleTextareaLinkAndPreorderCta: "template-29",
  SectionWithTitleImageCtaAndPreorderCta: "template-30",
  TitleWithSmallIcon: "template-31",
  MultipleBlocks: "template-32",
  MultipleBlocksWrapper: "wrapper-template-32",
  HardwarePreorderWithUrlPreorderAndPlanPreselectButtons: "template-33",
  HardwarePreorderWithUrlPreorderAndPlanPreselectButtonsWrapper: "wrapper-template-33",
  ServiceBlocks: "template-34",
  ServiceBlocksWrapper: "wrapper-template-34",
  TablesWithTitleAndDescription: "template-35",
  TabsForEshopDevices: "template-36",
  TabsForEshopDevicesWrapper: "wrapper-template-36",
  TabsForEshopDevicesWrapperItems: "wrapper-template-36-items",
  WrapperWithEshopDevices: "template-37",
  WrapperWithEshopDevicesWrapper: "wrapper-template-37",
  WrapperWithEshopDevicesWrapperItems: "wrapper-template-37-items",
  ScrollTable: "scroll-table",
  SpecificBlock: "specific-block",
  Table: "table",
  HalfBlock2: "half-block-2",
  // Template fields
  TextFieldHeading: "text-field-heading",
  TextFieldEventType: "text-field-event-type",
  TextFieldSku: "text-field-sku",
  TextFieldPrice: "text-field-price",
  TextFieldRecentPrice: "text-field-recent-price",
  TextFieldDiscountPercent: "text-field-discount-percent",
  TextAreaField: "text-area-field",
  ButtonField: "button-field",
  CheckboxFieldDetailedInformation: "checkbox-field-detailed-information",
  CheckboxFieldShowMonthlyPayment: "checkbox-field-show-monthly-payment",
  ContentColor: "content-color",
  SubtitleColor: "subtitle-color"
};