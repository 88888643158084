<template>
  <div
    class="user-profile-info"
    :class="{ 'user-profile-info---mobile-break': useMobileBanner }"
  >
    <div class="user-profile-info--mobile-banner">
      <t2-heading
        v-if="user.name"
        class="user-profile-info--mobile-banner---heading"
      >
        <span>{{ user.name }}</span>
      </t2-heading>
      <t2-heading
        v-if="user.msisdn"
        class="user-profile-info--mobile-banner---heading"
      >
        <t2-svg
          v-if="user.isEsim"
          class="user-profile-info--icon"
          :icon-id="user.eSimCardIconMobile"
        />
        <span>{{ user.msisdn }}</span>
      </t2-heading>
      <p
        v-if="user.billValidity"
        class="user-profile-info--mobile-banner---sub-heading"
      >
        {{ user.billValidity }}
      </p>
    </div>
    <t2-heading
      level="3"
      class="user-profile-info--user-info-title user-profile-info--user-info-title---name"
    >
      {{ user.name || user.defaultName }}
    </t2-heading>
    <t2-heading
      v-if="user.msisdn"
      level="4"
      class="user-profile-info--user-info-title user-profile-info--user-info-title---phone"
    >
      <t2-svg
        v-if="user.isEsim"
        class="user-profile-info--icon"
        :icon-id="user.eSimCardIconDesktop"
      />
      <span>{{ user.msisdn }}</span>
    </t2-heading>
    <p
      v-if="user.billValidity"
      class="user-profile-info--user-info-title user-profile-info--user-info-title---validity"
    >
      {{ user.billValidity }}
    </p>
  </div>
</template>
<script>
import T2Svg from "@atomic/atoms/Svg";
import T2Heading from "@atomic/atoms/Heading";

export default {
  components: { T2Svg, T2Heading },
  props: {
    user: {
      type: Object,
      required: true,
    },
    useMobileBanner: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";
.user-profile-info {
  position: relative;
  margin-bottom: 30px;

  @media screen and (max-width: @media-breakpoint-lg-max) {
    margin-bottom: 0;

    &---tablet-hidden {
      display: none;
    }
  }
  &--user-info-title {
    .BP-bold;
    text-transform: uppercase;

    &---name {
      font-size: 32px;
      color: @color-red;
      margin: 0 0 9px 0;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 265px;

      @media screen and (max-width: @media-breakpoint-lg-max) {
        display: none;
      }
    }
    &---phone {
      font-size: 20px;
      color: @color-dark;
      margin: 0;
      text-align: left;
      display: flex;
      align-items: center;

      @media screen and (max-width: @media-breakpoint-lg-max) {
        display: none;
      }
    }
    &---validity {
      font-weight: 400 !important;
      text-transform: initial !important;
      font-size: 14px;
      line-height: 17px;
      margin-top: 20px;
      color: @color-grey-2;
      white-space: nowrap;
      @media screen and (max-width: @media-breakpoint-lg-max) {
        display: none;
      }
    }
  }
  &--mobile-banner {
    background: linear-gradient(270deg, @color-lighter-blue 0%, @color-blue 100%);
    text-align: center;
    padding: 20px;
    display: none;

    @media screen and (max-width: @media-breakpoint-lg-max) {
      display: block;
    }

    &---heading {
      color: @color-white;
      margin: 0 0 10px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &---sub-heading {
      color: @color-white;
      margin: 0;
      font-size: 13px;
    }
  }
  &--icon {
    height: 34px;
    width: 34px;
    margin: 0 8px 0 0;
  }
}
.user-profile-info---mobile-break {
  margin-bottom: 0;
  .user-profile-info {
    &--mobile-banner {
      display: block;
    }
    &--user-info-title---name,
    &--user-info-title---phone,
    &--user-info-title---validity {
      display: none;
    }
  }
}
</style>
