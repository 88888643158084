import { createRouter, createWebHistory } from "vue-router";
import NarsykApi from "@/views/narsyk/api";
import RouterService from "@/services/router";
import emitter from "@/common/emitter";
import constants from "@/constants";

const loadResources = () => {
  return NarsykApi.getResources([
    constants.ResourceGroups.ValidationMessages,
    constants.ResourceGroups.NewDashboard,
    constants.ResourceGroups.NewPolarModal,
    constants.ResourceGroups.NewExceptions,
    constants.ResourceGroups.Narsyk,
    constants.ResourceGroups.Footer,
    constants.ResourceGroups.SmsChallenge,
  ]);
};

const routeOptions = [
  {
    path: constants.Routes.Narsyk,
    location: "narsyk",
  },
  {
    path: constants.Routes.NarsykPlansConfirmation,
    location: "new-plans-confirmation",
    props: {
      redirectRoute: constants.Routes.Narsyk,
    },
  },
  {
    path: constants.Routes.NarsykUserNotFound,
    location: "narsyk-error",
    props: {
      errorType: constants.NarsykAuthenticationResults.UserNotFound,
    },
  },
  {
    path: constants.Routes.NarsykUnknownError,
    location: "narsyk-error",
    props: {
      errorType: constants.NarsykAuthenticationResults.UnknownError,
    },
  },
  {
    path: constants.Routes.NarsykAutoLoginDisabled,
    location: "narsyk-error",
    props: {
      errorType: constants.NarsykAuthenticationResults.AutoLoginDisabled,
    },
  },
  {
    path: constants.Routes.NarsykSmsChallengeThrottle,
    location: "narsyk-error",
    props: {
      errorType: constants.NarsykAuthenticationResults.SmsChallengeThrottle,
    },
  },
  {
    path: constants.Routes.NarsykSmsChallenge,
    location: "auto-login-sms-challenge",
    props: {
      isNarsyk: true,
    },
  },
];

const routes = routeOptions.map(RouterService.setRouteComponent);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  await loadResources();
  emitter.emit("resources-loaded");

  if (
    to.path === constants.Routes.Narsyk ||
    to.path === constants.Routes.Narsyk + "/"
  ) {
    let token = localStorage.getItem(constants.LocalStorage.NarsykJwtToken);
    if (to.query.status) {
      switch (+to.query.status) {
        case constants.NarsykAuthenticationResults.Success:
          token = await NarsykApi.getToken();
          localStorage.setItem(
            constants.LocalStorage.NarsykJwtToken,
            token.data.result
          );
          break;
        case constants.NarsykAuthenticationResults.UserNotFound:
          return next(constants.Routes.NarsykUserNotFound);
        case constants.NarsykAuthenticationResults.UnknownError:
          return next(constants.Routes.NarsykUnknownError);
        case constants.NarsykAuthenticationResults.AutoLoginDisabled:
          return next(constants.Routes.NarsykAutoLoginDisabled);
        case constants.NarsykAuthenticationResults.SmsChallengeThrottle:
          return next(constants.Routes.NarsykSmsChallengeThrottle);
        case constants.NarsykAuthenticationResults.ChallengeRequired:
          return next({
            path: constants.Routes.NarsykSmsChallenge,
            query: {
              msisdn: to.query.msisdn,
            },
          });
        default:
          return next(constants.Routes.NarsykUnknownError);
      }
    } else if (!token) {
      window.location =
        constants.Env.BrandtierUrl +
        constants.Routes.NarsykAutoLogin +
        (to.query.msisdn ? `?msisdn=${to.query.msisdn}` : "");
      return;
    }
  }

  next();
});

router.afterEach(() => {
  window.scroll(0, 0);
});

export default router;
