import constants from "@/constants";

const servicesListIndex = 1;
const cardListIndex = 2;

const formatSubmenu = (
  whaleboneDisabled,
  esimDisabled,
  myRequestsBadge,
  pukLoader,
  idCardLoader
) => (
  [
    {
      customClass: "",
      list: [
        {

          iconId: constants.Icons.Wallet,
          name: constants.Resources.NewDashboard.AddCredits,
          url: constants.Routes.Refill,
          spa: true,
          red: true,
        },
        {
          iconId: constants.Icons.Plans,
          name: constants.Resources.NewDashboard.ChangePlan,
          url: constants.Routes.Plans,
          spa: true,
          red: true,
        },
      ],
    },
    {
      customClass: "",
      list: [
        {
          iconId: constants.Icons.Security,
          name: constants.Resources.NewDashboard.Whalebone,
          url: constants.Routes.Whalebone,
          spa: true,
          disabled: whaleboneDisabled,
        },
        {
          iconId: constants.Icons.Paper,
          name: constants.Resources.NewDashboard.UseFactSheet,
          url: constants.Routes.Report,
          spa: true,
        },
        {
          iconId: constants.Icons.Block,
          name: constants.Resources.NewDashboard.PricePlansCancellation,
          url: constants.Routes.PricePlansCancellation,
          spa: true,
        },
        {
          iconId: constants.Icons.SimCard,
          name: constants.Resources.NewDashboard.ESimChange,
          url: constants.Routes.ESimChangeInformation,
          spa: true,
          disabled: esimDisabled
        },
        {
          iconId: constants.Icons.PortOutNumber,
          name: constants.Resources.NewDashboard.PortOut,
          url: constants.Routes.PortOut,
          spa: true,
        },
        {
          iconId: constants.Icons.Assignment,
          name: constants.Resources.NewDashboard.BillingAndPayments,
          url: constants.Routes.Invoice,
          spa: true,
        },
        {
          iconId: constants.Icons.Requests,
          name: constants.Resources.NewDashboard.MyRequests,
          url: constants.Routes.MyRequests,
          spa: true,
          badge: myRequestsBadge,
        }, 
      ]
      .filter((x) => !x.disabled),
    },
    {
      customClass: "",
      list: [
        {
          iconId: constants.Icons.PukCode,
          name: constants.Resources.NewDashboard.ShowPukCode,
          event: "pukCode",
          loader: pukLoader
        },
        {
          iconId: constants.Icons.IdCard,
          name: constants.Resources.NewDashboard.ChangeIdCard,
          event: "idCard",
          loader: idCardLoader
        },
        {
          iconId: constants.Icons.Settings,
          name: constants.Resources.NewDashboard.UserSettings,
          url: constants.Routes.Settings,
          spa: true,
        },
      ],
    },
    {
      customClass: "",
      list: [
        {
          iconId: constants.Icons.Logout,
          name: constants.Resources.NewDashboard.LogOut,
          event: "logOut",
          red: true
        },
      ],
    },
  ].filter((x) => x.list.length > 0)
);

export default {
  servicesListIndex,
  cardListIndex,
  formatSubmenu,
};
