<template>
  <form ref="form" :action="data.redirectUrl" :method="data.method">
    <input
      v-for="(value, name) in data.parameters"
      :key="name"
      :value="value"
      :name="name"
      type="hidden"
    />
    <input v-show="false" type="submit" value="Submit" />
  </form>
</template>
<script>
export default {
  name: "T2Form",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.form.submit();
    });
  }
};
</script>
