import constants from "@/constants";

export default {
  Types: {
    Info: 1,
    Warning: 2,
    Error: 3,
    Modal: 4
  },
  Locations: {
    1: "",
    2: constants.Routes.Dashboard,
    3: constants.Routes.Refill,
    4: constants.Routes.Plans,
    5: constants.Routes.Home,
  },
  DefaultImage: "/img/informationBanner.png",
  Events: {
    UserBannerClear: "clear-user-specific-banners",
  },
  Ids: {
    PasswordChangeReminder: "password-change-reminder",
    InformationModal: "information-modal",
    CardInformationBanner: "card-information-banner"
  },
};
