import AwardsApi from "@/api/awardsCampaign";
import SummerCampaignService from "@/services/summerCampaign2023";

const awardsCampaign = {
  namespaced: true,
  state: {
    purchasedPrizeType: null,
    prizeToSelectId: null,
    prizes: [],
    points: null,
    winnings: null,
    prize: null
  },

  mutations: {
    setPurchasedPrizeType(state, value) {
      state.purchasedPrizeType = value;
    },
    setPrizeToSelectId(state, value) {
      state.prizeToSelectId = value;
    },
    setPrizes(state, value) {
      state.prizes = value;
    },
    setPoints(state, value) {
      state.points = value;
    },
    setWinnings(state, value) {
      state.winnings = value;
    }, 
    setPrize(state, value) {
      state.prize = value;
    },
  },

  actions: {
    loadPrizes({ commit, getters }) {
      if (getters.prizesLoaded) {
        return Promise.resolve();
      }

      return AwardsApi.getPrizes().then((response) => {
        commit("setPrizes", SummerCampaignService.formatGifts(response.data));
      });
    },

    loadPoints({ commit }) {
      return AwardsApi.getPoints().then((response) => {
        commit("setPoints", response.data);
      });
    },

    loadWinnings({ commit }) {
      return AwardsApi.getWinnings().then((response) => {
        commit("setWinnings", response.data);
      });
    },
  },

  getters: {
    getPurchasedPrizeType: (state) => {
      return state.purchasedPrizeType;
    },
    getPrizeToSelectId: (state) => {
      return state.prizeToSelectId;
    },
    prizesLoaded: (state) => {
      return state.prizes.length > 0;
    },
    getPrizes: (state) => {
      return state.prizes;
    },
    getPrizeById: (state) => (prizeId) => {
      return state.prizes.find((x) => x.id === prizeId);
    },
    getPoints: (state) => {
      return state.points;
    },
    getPrize: (state) => {
      return state.prize;
    },
    getWinnings: (state) => {
      return state.winnings;
    },
    getWinningById: (state) => (winningId) => {
      return state.winnings.find((x) => x.winningId === winningId);
    },
  },
};

export default awardsCampaign;
