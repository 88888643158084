import axiosClient from "@/common/axiosClient";
import constants from "@/constants";

const loadActiveCampaigns = () => {
  if (constants.ActiveCampaigns) {
    return Promise.resolve();
  }

  return axiosClient.get("/campaign/active").then(({ data }) => {
    constants.ActiveCampaigns = data;
  });
};

const getValidTransaction = () => {
  return axiosClient.get("/airpod/transaction/valid");
};

const getTransactionStatus = (transactionId) => {
  return axiosClient.get(`/campaign/transactions/${transactionId}`);
};

const updateShipmentDetails = (data) => {
  return axiosClient.post("/airpod/shipment", data);
};

const getShipmentDetails = (transactionId) => {
  return axiosClient.get("/airpod/shipment/" + transactionId);
};

const getLocations = () => {
  return axiosClient.get("/campaign/locations");
};

const orderItem = (data) => {
  return axiosClient.post("/airpod/order", data);
};

const getPrizes = () => {
  return axiosClient.get("/airpod/prizes");
};

const getUserBalance = (transactionId) => {
  return axiosClient.get("/airpod/balance/" + transactionId);
};

const getCreditCard = (transactionId) => {
  return axiosClient.get("/airpod/card/" + transactionId);
};

export default {
  loadActiveCampaigns,
  getValidTransaction,
  getTransactionStatus,
  updateShipmentDetails,
  getShipmentDetails,
  getLocations,
  orderItem,
  getPrizes,
  getUserBalance,
  getCreditCard,
};
