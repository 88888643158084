Array.prototype.sum = function(prop) {
  var total = 0;
  for (var i = 0, len = this.length; i < len; i++) {
    total += parseFloat(this[i][prop].toString().replace(",", "."));
  }
  return total;
};

if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}
