import { onMounted, onUnmounted } from 'vue';

export function useMetaRobot(noIndex = false, noFollow = false) {
  onMounted(() => {
    const elements = [];

    if (noIndex) {
      elements.push("noindex");
    }

    if (noFollow) {
      elements.push("nofollow");
    }

    if (elements.length === 0) {
      return;
    }

    const metaElement = document.createElement("meta");
    metaElement.name = "robots";
    metaElement.content = elements.join(", ");

    document.head.appendChild(metaElement);
  });

  onUnmounted(() => {
    (document.querySelector("[name='robots']") || { remove: () => 0 }).remove();
  });
}