<template>
  <svg
    v-if="iconId"
    :key="iconId"
    class="svg"
    :class="[customClass]"
  >
    <use :xlink:href="'/img/icons.svg?v=37#' + iconId"></use>
  </svg>
</template>
<script>
export default {
  props: {
    customClass: {
      type: [String, Object],
      default: null,
    },
    iconId: {
      type: String,
      required: true,
    }
  },
};
</script>
