import axiosClient from "@/common/axiosClient";

const getCreditCard = () => {
  return axiosClient.get("/payment/payments/card");
};

const deleteCreditCard = () => {
  return axiosClient.delete("/payment/payments/card");
};

export default {
  getCreditCard,
  deleteCreditCard,
};
