<template>
  <transition name="custom-classes-transition">
    <div>
      <div
        class="v-privacy-policy-modal--shadow"
        :class="[isOpen ? 'active' : 'v-privacy-policy-modal--shadow---closed']"
      >
        <div v-show="isOpen" class="v-privacy-policy-modal--dialog-holder">
          <div class="v-privacy-policy-modal--dialog" @click.stop>
            <div class="v-privacy-policy-modal--content">
              <div class="v-privacy-policy-modal--content-wrapper">
                <t2-heading level="3" class="v-privacy-policy-modal--title">
                  {{ data.title }}
                </t2-heading>
                <div
                  class="v-privacy-policy-modal--description"
                  v-html="data.description"
                />
                <div class="v-privacy-policy-modal--items">
                  <t2-agreement
                    v-for="(item, index) in policies"
                    :key="`policy-${index}`"
                    class="v-privacy-policy-modal--item"
                    :agreementId="`${index}`"
                    :data="item"
                    @toggle-click="policies[index].agreed = $event"
                  />
                </div>
                <div
                  class="v-privacy-policy-modal--description"
                  v-html="data.moreInfo"
                />
              </div>
              <div
                v-loader="data.buttons.loader"
                class="v-privacy-policy-modal--button-wrapper"
              >
                <t2-button
                  blue
                  secondary
                  class="v-privacy-policy-modal--button"
                  @click="$emit('save-policy', policies)"
                >
                  {{ data.buttons.agreeSelected }}
                </t2-button>
                <t2-button
                  blue
                  class="v-privacy-policy-modal--button"
                  @click="handleAgreeAll"
                >
                  {{ data.buttons.agreeAll }}
                </t2-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import T2Button from "@atomic/atoms/Button";
import T2Heading from "@atomic/atoms/Heading";
import T2Agreement from "@atomic/molecules/Agreement";

export default {
  components: {
    T2Heading,
    T2Agreement,
    T2Button,
  },
  inject: ["emitter"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["save-policy"],
  data() {
    return {
      isOpen: false,
      scrolledY: 0,
      id: "privacy-policy-modal",
      policies: null,
    };
  },
  watch: {
    "data.items": {
      deep: true,
      handler: function(newValue) {
        this.policies = newValue;
      },
    },
  },
  created() {
    this.emitter.on("open-modal", this.openModal);
    this.emitter.on("close-modal", this.closeModal);
    this.policies = this.data.items;
  },
  methods: {
    openModal(payload) {
      if (payload.id !== this.id) {
        return;
      }

      this.isOpen = true;
      this.toggleScreenScroll();
    },
    closeModal(payload) {
      if (payload.id !== this.id) {
        return;
      }

      this.isOpen = false;
      this.toggleScreenScroll();
    },
    emitClose() {
      if (this.isOpen) {
        this.emitter.emit("close-modal", { id: this.id });
      }
    },
    toggleScreenScroll() {
      var scroll;
      if (window.scrollY !== undefined) {
        scroll = { x: window.scrollX, y: window.scrollY };
      } else {
        var sx,
          sy,
          d = document,
          r = d.documentElement,
          b = d.body;
        sx = r.scrollLeft || b.scrollLeft || 0;
        sy = r.scrollTop || b.scrollTop || 0;
        scroll = { x: sx, y: sy };
      }
      if (this.isOpen) {
        this.scrolledY = scroll.y;
      }

      var elements = [
        document.querySelector("body"),
        document.querySelector("html"),
      ];
      for (var i = 0; elements.length > i; i++) {
        elements[i].style.margin = this.isOpen ? 0 : "";
        elements[i].style.position = this.isOpen ? "fixed" : "";
        elements[i].style.top = this.isOpen ? "-" + scroll.y + "px" : "";
      }
      if (!this.isOpen) {
        window.scrollTo(0, this.scrolledY);
      }
    },
    handleAgreeAll() {
      this.policies.forEach((x) => {
        x.agreed = true;
      });
      this.$emit("save-policy", this.policies);
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-privacy-policy-modal {
  &--shadow {
    background-color: fade(@color-black, 60);
    height: 100%;
    left: 0;
    opacity: 1;
    overflow-x: visible;
    overflow-y: scroll;
    padding: 0 33px 0;
    position: fixed;
    top: 0;
    transition: opacity 0.45s ease, transform 0.45s ease;
    width: 100%;
    max-height: 100vh;
    z-index: 900;
    @media screen {
      @media (min-width: @media-tablet-portrait) and (max-height: 530px) {
        align-items: flex-start;
      }
      @media (max-width: @media-tablet-portrait) {
        padding: 0;
      }
    }
  }

  &--shadow---closed {
    z-index: -1;
    opacity: 0;
    transition: opacity 0.45s ease, transform 0.45s ease;
    will-change: z-index, opacity;
  }
  &--dialog-holder {
    min-height: ~"calc(100% - 66px)";
    display: flex;
    align-items: center;
    vertical-align: middle;
    width: 95%;
    height: auto;
    margin: 3% auto;
  }
  &--dialog {
    width: 100%;
    max-width: 820px;
    display: flex;
    flex-flow: column nowrap;
    margin: auto;
    padding: 50px 23px 80px 43px;
    position: relative;
    background-color: #f6f8ff;
    box-shadow: 0 5px 20px 0 fade(@color-black, 40);
    transform: scale(1);
    transition: all 0.45s ease;
    opacity: 0.99;

    @media screen {
      @media (max-width: @media-breakpoint-sm-max) {
        width: 100%;
        min-height: 400px;
        flex: 1 0 0;
        padding: 33px 5px 33px 15px;
        height: calc(100vh - 25px);
      }
    }
  }
  &--content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    font-size: 16px;
    line-height: 22px;
    @media screen {
      @media (max-width: @media-mobile-portrait) {
        padding: 0;
      }
    }
    &-wrapper {
      max-width: 100%;
      overflow-y: scroll;
      height: calc(100vh - 230px);
      padding-right: 20px;
      @media only screen and (max-width: @media-breakpoint-sm-max) {
        height: calc(100vh - 200px);
        padding-right: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 50px @color-white;
        border-radius: 50px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
        @media screen and (max-width: @media-breakpoint-sm-max) {
          width: 3px;
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        -webkit-box-shadow: inset 0 0 50px #e0e0e0;
        background-color: #e0e0e0;
        height: 50px;
      }
    }
  }
  &--title {
    margin: 0 0 49px;
    font-size: 24px;
    @media only screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 18px;
      line-height: 19px;
      margin-bottom: 30px;
      text-align: left;
    }
  }
  &--item {
    &:last-child {
      margin-bottom: 43px;
      @media only screen and (max-width: @media-breakpoint-sm-max) {
        margin-bottom: 23px;
      }
    }
  }
  &--items {
    .v-agreement--item {
      margin-bottom: 0;
    }
  }
  &--description {
    width: 100%;
    margin-bottom: 44px;
    font-size: 16px;
    line-height: 22px;
    @media only screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 15px;
    }
    a {
      color: @color-blue;
      &:hover {
        color: @color-darkred;
      }
    }
  }
  &--button {
    &:last-child {
      margin-left: 10px;
      @media only screen and (max-width: @media-breakpoint-sm-max) {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    &-wrapper {
      width: 100%;
      background-color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 13px 17px;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: @media-breakpoint-sm-max) {
        flex-direction: column;
      }
    }
    @media only screen and (max-width: @media-breakpoint-sm-max) {
      width: 100%;
    }
  }
}
</style>
