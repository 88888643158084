import lodashHas from "lodash/has";
import lodashIsString from "lodash/isString";
import lodashIsNumber from "lodash/isNumber";
import { nextTick } from "vue";

const scroll = (el, _binding, vnode) => {
  let attributes = {};
  Array.from(el.attributes).forEach((x) => {
    attributes[x.name] = x.value;
  });

  let opt = getOptions(attributes);
  let toElement = getScrollElement(opt, vnode);

  nextTick(() => {
    let clientWidth = vnode.el.clientWidth;
    let scrollWidth = vnode.el.scrollWidth;
    let offset = (vnode.el.offsetWidth - toElement.scrollWidth) / 2;
    if (offset > 0) {
      offset = -offset;
    }
    if (scrollWidth > clientWidth && toElement) {
      el.scroll({
        left: toElement.offsetLeft + offset,
        top: 0,
        behavior: "smooth",
      });
    }
  });
};

const getOptions = (data) => {
  let options = {};
  if (lodashHas(data, "scroll-to") && lodashIsString(data["scroll-to"]))
    options["scroll-to"] = data["scroll-to"];
  if (lodashHas(data, "scroll-to-id") && lodashIsString(data["scroll-to-id"]))
    options["scroll-to-id"] = data["scroll-to-id"];
  if (
    lodashHas(data, "scroll-to-class") &&
    lodashIsString(data["scroll-to-class"])
  )
    options["scroll-to-class"] = data["scroll-to-class"];
  return options;
};

const getScrollElement = (options, vnode) => {
  if (options["scroll-to"]) {
    if (options["scroll-to"] === "last-child") return vnode.el.lastChild;
    else if (options["scroll-to"] === "first-child") return vnode.el.firstChild;
    else {
      let childNumber = parseInt(options["scroll-to"]);
      if (lodashIsNumber(childNumber))
        return vnode.el.children[childNumber - 1];
    }
  }
  if (options["scroll-to-id"])
    return document.getElementById(options["scroll-to-id"]);
  if (options["scroll-to-class"]) {
    let elements = document.getElementsByClassName(options["scroll-to-class"]);
    if (elements.length === 1) {
      return elements[0];
    }
    if (elements.length === 0) {
      return vnode.el.firstChild;
    }
    // eslint-disable-next-line
    console.error("get more then one element by class name");
    return null;
  }
  return null;
};

export default {
  mounted: scroll,
  updated: scroll,
};
