import constants from "@/constants";

const getModalInformation = (modal, data) => {
  switch (modal) {
    case constants.ConsentCampaignComponents.Main:
      return _formatConsentsMain();
    case constants.ConsentCampaignComponents.Consents:
      return _formatConsents(data);
    case constants.ConsentCampaignComponents.Saved:
      return _formatConsentsSaved();
    case constants.ConsentCampaignComponents.Activated:
      return _formatConsentsActive();
  }

  return _formatConsentsMain();
};

const formatConsentUpdateData = (consents) => {
  return consents.map((consent) => {
    return {
      consentAgreementStatus: consent.agreed
        ? constants.ConsentStatus.Agree
        : constants.ConsentStatus.Disagree,
      title: consent.title,
      description: consent.description,
      consentType: consent.consentType,
    };
  });
};

const getConsentsCampaignBanner = () => {
  return {
    title: constants.Resources.ConsentCampaign.BannerTitle,
    prize: constants.Resources.ConsentCampaign.BannerPrize,
    description: constants.Resources.ConsentCampaign.BannerDescription,
    image: constants.Images.ConsentCampaign.Settings,
  };
};

const isConsentCampaignActive = () => {
  var currentDate = new Date();
  var campaignValidFrom = new Date(
    constants.Configurations.ConsentCampaignValidFrom
  );
  var campaignValidTo = new Date(
    constants.Configurations.ConsentCampaignValidTo
  );

  return campaignValidFrom < currentDate && campaignValidTo > currentDate;
};

const _formatConsentsMain = () => {
  return {
    component: constants.ConsentCampaignComponents.Main,
    content: {
      title: constants.Resources.ConsentCampaign.MainTitle,
      prize: constants.Resources.ConsentCampaign.MainPrize,
      description: constants.Resources.ConsentCampaign.MainDescrioption,
      image: constants.Images.ConsentCampaign.Main,
      gameRulesButton: constants.Resources.ConsentCampaign.MainGameRulesButton,
      gameRulesLink: constants.ConsentCampaignRulesLink,
      button: {
        text: constants.Resources.ConsentCampaign.MainButton,
        loader: false,
      },
    },
  };
};

const _formatConsents = (consents) => {
  return {
    component: constants.ConsentCampaignComponents.Consents,
    content: {
      title: constants.Resources.ConsentCampaign.ConsentTitle,
      description: constants.Resources.ConsentCampaign.ConsentDescription,
      image: constants.Images.ConsentCampaign.Consents,
      buttons: {
        agreeSelected: constants.Resources.ConsentCampaign.ConsentAgreeSelected,
        agreeAll: constants.Resources.ConsentCampaign.ConsentAgreeAll,
      },
      items: consents,
      consentsDescription:
        constants.Resources.ConsentCampaign.ConsentDescriptionMore,
      loader: false,
    },
  };
};

const _formatConsentsSaved = () => {
  return {
    component: constants.ConsentCampaignComponents.Saved,
    content: {
      title: constants.Resources.ConsentCampaign.SavedTitle,
      description: constants.Resources.ConsentCampaign.SavedDescription,
      imageDesktop: constants.Images.ConsentCampaign.SavedDesktop,
      imageMobile: constants.Images.ConsentCampaign.SavedMobile,
      button: constants.Resources.ConsentCampaign.SavedButton,
    },
  };
};

const _formatConsentsActive = () => {
  return {
    component: constants.ConsentCampaignComponents.Activated,
    content: {
      title: constants.Resources.ConsentCampaign.ActivatedTitle,
      description: constants.Resources.ConsentCampaign.ActivatedDescription,
      image: constants.Images.ConsentCampaign.Activated,
      button: constants.Resources.ConsentCampaign.ActivatedButton,
    },
  };
};

export default {
  getModalInformation,
  formatConsentUpdateData,
  getConsentsCampaignBanner,
  isConsentCampaignActive,
};
