import { createStore } from "vuex";

import {
  translations,
  footer,
  header,
  metadata,
  polarModal,
  user,
  common,
  awardsCampaign,
  features,
  shortLinks,
  menu,
  payments,
  whalebone,
  cart,
} from "./modules";

const store = createStore({
  modules: {
    translations,
    footer,
    header,
    metadata,
    polarModal,
    user,
    common,
    awardsCampaign,
    features,
    shortLinks,
    menu,
    payments,
    whalebone,
    cart,
  },
});

export default store;
