export default {
  SessionStorage: {
    PortoutSubmitted: "portout-submitted",
    AwardsCampaignEndingModalShown: "awards-campaign-ending-modal-shown",
    ConsentsCampaignModalShow: "consent-modal-shown",
    HiddenMarketingMessages: "hidden-marketing-messages",
    CookiebotLoadFailed: "cookiebot-load-failed",
    ReturnRoute: "return-route",
    ClosedInformationPopups: "closed-information-popups",
  },
  LocalStorage: {
    Language: "language",
    PaymentType: "paymentType",
    BankName: "bankName",
    MothersDayModalShown: "mothers-day-modal-shown",
    StarWarsDayModalShown: "star-wars-day-modal-shown",
    NarsykJwtToken: "narsykJWT",
    SelfcareJwtToken: "selfcareJWT",
    CookieAgreement: "cookie-agreement",
    OAuthSource: "oauth-source",
    HideCampaignBanner: "hideCampaignBanner",
    OrderedEarpods: "orderedEarpods",
    ForceFinishRegistration: "force-finish-registration",
    ForceFinishPasswordRemind: "force-finish-password-remind",
    PurchasedPricePlan: "purchased-price-plan",
  },
};
