import constants from "@/constants";
import cookie from "vue-cookies";

const formatCookieAgreementData = () => {
  return {
    title: constants.Resources.Footer.CookieAgreementTitle,
    description: constants.Resources.Footer.CookieAgreementDescriptiob,
    image: constants.Images.CookieAgreement.Url,
    button: constants.Resources.Footer.CookieAgreementButton,
  };
};

const showCookieAgreement = () => {
  const cookieAgreementConsent = localStorage.getItem(
    constants.LocalStorage.CookieAgreement
  );
  const cookiebotConsent = cookie.get("CookieConsent");

  sessionStorage.removeItem(constants.SessionStorage.CookiebotLoadFailed);

  return !cookieAgreementConsent && !cookiebotConsent;
};

const handleCookieAgreementClick = (context) => {
  localStorage.setItem(constants.LocalStorage.CookieAgreement, true);
  context.cookieAgreement.show = false;
};

export default {
  formatCookieAgreementData,
  showCookieAgreement,
  handleCookieAgreementClick,
};
