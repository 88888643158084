<template>
  <div class="v-copyright">
    <p class="v-copyright--content">
      <span class="v-copyright--copyright">{{ copyright }}</span>
      <span
        v-for="(link, index) in policies"
        :key="index"
        class="v-copyright--link"
      >
        <a
          :href="link.url"
          class="v-copyright--link-text"
          @click.prevent="$emit(link.cookies ? 'cookies-click' : 'link-click', link)"
        >
          {{ link.name }}
        </a>
      </span>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    copyright: {
      type: String,
      default: "",
    },
    policies: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    "cookies-click",
    "link-click",
  ],
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";
.v-copyright {
  background: @color-white;

  &--content {
    max-width: 1240px;
    width: 100%;
    margin: auto;
    padding: 20px;
    color: @color-text-dark;
    font-size: 13px;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 10px;
      font-size: 11px;
      line-height: 19px;
      text-align: center;
    }
  }

  &--link,
  &--copyright {
    display: inline-block;
  }

  &--link {
    color: @color-text-dark;
    margin-left: 4px;

    &:not(:last-child)::after {
      color: @color-text-dark;
      content: "|";
      padding-left: 4px;
    }
  }
  &--link-text {
    color: @color-text-dark;
    border-bottom: 1px solid @color-text-dark;
    white-space: nowrap;
    cursor: pointer;
  }
}
</style>
