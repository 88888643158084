import store from "@/store";
import constants from "@/constants";

const checkCurrentRoute = (context, currentLanguage) => {
  const language = window.location.pathname.split("/")[1];

  if (
    context.$router.currentRoute.value.meta.isDynamic ||
    !context.$router.currentRoute.value.meta.allowAdditionalLanguages
  ) {
    context.$router.replace({ path: store.getters["user/getLanguageRoutes"].Home });
    return true;
  }
  if (
    (constants.Languages.includes(language) && language !== currentLanguage) ||
    (!constants.Languages.includes(language) &&
      currentLanguage !== constants.DefaultLanguage)
  ) {
    if (context.$router.currentRoute.value.meta.alias) {
      const path =
        context.$router.currentRoute.value.meta.alias.find(
          (x) => x.split("/")[1] === currentLanguage
        ) ?? context.$router.currentRoute.value.meta.alias[0];

      sessionStorage.setItem("route", path);
      context.$router.replace({
        path: constants.Routes.LangProxy,
        query: {
          ...context.$route.query,
          ...context.$route.params
        },
      });

      return true;
    }
  }
  return false;
};

const getLanguageRoutes = () => {
  const routeKeys = Object.keys(constants.Routes)
  const ltRoutes = {};
  
  routeKeys.forEach((key) => {
    if (typeof constants.Routes[key] === "string") {
      ltRoutes[key] = constants.Routes[key]; 
      
      return;
    }
    
    if (!constants.Routes[key].Path) {
      return ;
    }

    ltRoutes[key] = constants.Routes[key].Path;
  });

  return {
    LT: ltRoutes,
    RU: constants.Routes.RU,
    EN: constants.Routes.EN,
  };
};

export default {
  checkCurrentRoute,
  getLanguageRoutes,
};
