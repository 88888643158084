<template>
  <div class="v-error">
    <t2-page-header :data="data.header" />
    <div class="v-error--content-wrapper">
      <div class="v-error--image">
        <img class="v-error--image-wrapper" :src="data.content.image" />
      </div>
      <div class="v-error--main-content--container">
        <h1 class="v-error--main-content--heading">
          {{ data.content.statusCode }}
        </h1>
        <p class="v-error--main-content--description">
          {{ data.content.description }}
        </p>
        <t2-button
          class="v-error--main-content--button"
          @click="$emit('click')"
        >
          {{ data.content.button }}
        </t2-button>
      </div>
    </div>
  </div>
</template>
<script>
import T2PageHeader from "@atomic/organisms/PageHeader";
import T2Button from "@atomic/atoms/Button";

export default {
  components: {
    T2PageHeader,
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["click"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-error {
  text-align: center;
  &--content-wrapper {
    padding: 0 20px 65px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: @grid-max-width;
    position: relative;
    width: 100%;
    @media screen and (max-width: @media-mobile-portrait) {
      flex-direction: column;
    }
  }
  &--image {
    flex-basis: 50%;
    &-wrapper {
      width: 170px;
      @media screen and (max-width: @media-mobile-portrait) {
        width: 110px;
      }
    }
  }
  &--main-content {
    &--container {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      text-align: left;

      @media screen and (max-width: @media-mobile-portrait) {
        text-align: center;
      }
    }
    &--heading {
      .BP-bold;
      color: @color-dark;
      font-size: 50px;
      margin-bottom: 20px;
      margin-top: 80px;
      text-align: left;

      @media screen and (max-width: @media-mobile-portrait) {
        float: center;
        margin-top: 30px;
        font-size: 28px;
        text-align: center;
      }
    }
    &--description {
      color: @color-dark;
      font-size: 14px;
      line-height: 1.42em;
      margin-bottom: 30px;
      white-space: pre-line;
    }
    &--button {
      align-self: flex-start;
      @media screen and (max-width: @media-mobile-portrait) {
        align-self: unset;
      }
    }
  }
}
</style>
