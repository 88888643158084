<template>
  <t2-campaign-banner class="v-banner">
    <div class="v-banner--wrapper">
      <h1>{{ data.title }}</h1>
      <img :src="data.image" />
      <p>{{ data.description }}</p>
      <t2-button pink @click="$emit('start-click')">
        {{ data.button }}
      </t2-button>
    </div>
  </t2-campaign-banner>
</template>
<script>
import T2CampaignBanner from "@atomic/molecules/CampaignBanner";
import T2Button from "@/atomic-components/atoms/Button";

export default {
  components: {
    T2CampaignBanner,
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["start-click"],
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";

.v-banner {
  background: linear-gradient(
    90deg,
    #111827 0.14%,
    #192640 72.21%,
    #182238 92.03%,
    #111827 99.83%
  );

  &--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      flex-direction: column;
      align-items: start;
      padding: 18px;
      max-width: 400px;
      margin: 0 auto;
      position: relative;
    }
  }

  h1 {
    font-size: 32px;
    line-height: 46px;
    text-transform: uppercase;
    max-width: 215px;
    margin: 0;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 24px;
      line-height: 36px;
      max-width: 100%;
    }
  }

  h1,
  p {
    color: @color-white;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    max-width: 450px;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 10px;
      line-height: 16px;
      margin-bottom: 30px;
      max-width: 175px;
    }
  }

  img {
    max-width: 210px;
    width: 100%;
    height: auto;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      position: absolute;
      right: 0;
      bottom: 10px;
    }
  }
}
</style>
