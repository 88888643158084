<template>
  <div v-loader="true" class="v-loader-box" />
</template>
<style lang="less" scoped>
@import (reference) "~@/assets/less/variables.less";

.v-loader-box {
  height: 300px;

  &---white.loading :deep(.loading-circle) .circle::before {
    background: @color-white;
  }
}
</style>
