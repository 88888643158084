<template>
  <div class="v-agreement--item">
    <t2-checkbox
      v-if="!trueLabel && !falseLabel"
      class="v-agreement--item-checkbox"
      :checked="data.agreed"
      :name="agreementId"
      @checkbox-change="$emit('toggle-click', $event)"
    />
    <div>
      <div class="v-agreement--item-heading--wrapper">
        <div class="v-agreement--item-heading">{{ data.title }}</div>
      </div>
      <div class="v-agreement--item-description">{{ data.description }}</div>
      <t2-toggle-switch
        v-if="trueLabel && falseLabel"
        :value="data.agreed"
        :true-label="trueLabel"
        :false-label="falseLabel"
        @toggle-click="$emit('toggle-click', $event)"
      />
    </div>
  </div>
</template>
<script>
import { T2ToggleSwitch } from "@atomic/molecules/ToggleSwitch";
import T2Checkbox from "@atomic/atoms/Checkbox";
export default {
  components: {
    T2ToggleSwitch,
    T2Checkbox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    trueLabel: {
      type: String,
      default: "",
    },
    falseLabel: {
      type: String,
      default: ""
    },
    agreementId: {
      type: String,
      required: true,
    },
  },
  emits: ["toggle-click"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-agreement {
  &--item {
    margin-bottom: 73px;
    display: flex;
    &-heading {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 17px;
    }
    &-description {
      margin-bottom: 26px;
      font-size: 16px;
      line-height: 22px;
    }
    &:last-child {
      margin-bottom: 43px;
    }
    &-checkbox {
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: @media-breakpoint-sm-max) {
    &--item {
      margin-bottom: 50px;
      &-heading {
        font-size: 16px;
      }
      &-description {
        font-size: 13px;
        line-height: 22px;
      }
      &:last-child {
        margin-bottom: 36px;
      }
    }
  }
}
</style>
