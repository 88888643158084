import AxiosClient from "@/common/axiosClient";

const validateEmail = (u, h) => {
  return AxiosClient.post("/profile/email/validate", {
    U: u,
    H: h,
  });
};

const getActionReminders = () => {
  return AxiosClient.get("/user-information/action-reminders");
}

export default {
  validateEmail,
  getActionReminders,
};
