import axiosClient from "@/common/axiosClient";

const getUserData = () => {
  return axiosClient.get("/user-information/user");
};

const getGroupedBuckets = () => {
  return axiosClient.get("/user-information/v2/grouped-buckets");
};

const getAdditionalServiceBuckets = () => {
  return axiosClient.get("/price-plan/additional-service-buckets-channels");
};

const addNewslettersMember = (email) => {
  return axiosClient.post("/user-information/newsletter/order", {
    email: email,
  });
};

const updateConsents = (consents) => {
  return axiosClient.put("/consent/consents", consents);
};

const getConsents = () => {
  return axiosClient.get("/consent/consents");
};

const saveAgreement = () => {
  return axiosClient.post("/consent/campaigns/save");
};

const challenge = (data) => {
  return axiosClient.post("/hardware-pre-order/challenge", {
    msisdn: data.msisdn,
  });
};

const getCustomTariffs = () => {
  return axiosClient.get("/price-plan/custom-tariffs");
};

const hardwareSubmit = (data) => {
  return axiosClient.post("/hardware-pre-order/submit", {
    challenge: data.code,
    fullName: data.fullName,
    eventType: data.eventType,
    marketingMessageId: data.id,
    comment: data.comment,
    msisdn: data.msisdn,
  });
};

const getActivePlans = () => {
  return axiosClient.get("/price-plan/v2/active");
}

const getSuggestedPlans = () => {
  return axiosClient.get("/price-plan/suggested-plans");
};

export default {
  getUserData,
  getGroupedBuckets,
  getAdditionalServiceBuckets,
  addNewslettersMember,
  updateConsents,
  getConsents,
  saveAgreement,
  challenge,
  getCustomTariffs,
  hardwareSubmit,
  getActivePlans,
  getSuggestedPlans
};
