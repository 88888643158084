<template>
  <span class="badge">{{ text }}</span>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.badge {
  margin-left: 3px;
  vertical-align: middle;
  font-size: 12px;
  padding: 0 5px 2px 5px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: @color-white;
  background-color: @color-black;
  transition: all 0.2s ease;
  font-weight: 800;
  @media screen and (max-width: @media-tablet-portrait) {
    vertical-align: middle;
    padding: 1px 5px 2px 5px;
    margin-left: 5px;
    font-size: 11px;
  }
  &:hover {
    background-color: darken(@color-red, 15%);
  }
}
</style>
