import axiosClient from "@/common/axiosClient";
import constants from "@/constants";

const getConfigurations = (configurations) => {
  if (Array.isArray(configurations)) {
    var alreadyLoadedConfigurations = configurations.filter(
      (e) => Object.keys(constants.Configurations).indexOf(e) !== -1
    );

    if (alreadyLoadedConfigurations.length > 0) {
      alreadyLoadedConfigurations.forEach((config) => {
        var index = configurations.indexOf(config);

        if (index > -1) {
          configurations.splice(index, 1);
        }
      });
    }

    if (configurations.length > 0) {
      return axiosClient
        .get("/system/configs?configs=" + configurations.join("&configs="))
        .then((response) => {
          constants.Configurations = {
            ...constants.Configurations,
            ...response.data,
          };
        });
    }
  }

  return Promise.resolve();
};

export default {
  getConfigurations,
};
