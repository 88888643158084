import FeatureManagementApi from "@/api/featureManagement";
import constants from "@/constants";

const features = {
  namespaced: true,
  state: {
    data: null,
    lastUpdate: new Date(0),
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setLastUpdate(state, date) {
      state.lastUpdate = date;
    },
  },
  actions: {
    fetch({ commit, getters }) {
      let currentDate = new Date();
      currentDate.setSeconds(
        currentDate.getSeconds() -
          constants.FeatureManagement.RefreshBlockedFeaturesSeconds
      );

      if (getters.getLastUpdate > currentDate) {
        return Promise.resolve();
      }

      return FeatureManagementApi.getDisabledFeatures().then((response) => {
        commit("setData", response.data);
        commit("setLastUpdate", new Date());
      });
    },
  },
  getters: {
    getData: (state) => {
      return state.data;
    },
    getLastUpdate: (state) => {
      return state.lastUpdate;
    },
    isDisabled: (state) => (featureId) => {
      return state.data.find((x) => x.id === featureId);
    },
    isDisabledByName: (state) => (featureName) => {
      return state.data.find((x) => x.name === featureName);
    },
  },
};

export default features;
