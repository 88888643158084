<template>
  <transition
    @enter="enter"
    @after-enter="afterAnimation"
    @leave="leave"
    @after-leave="afterAnimation"
  >
    <div v-show="show">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },  
  data() {
    return {
      cachedStyles: null,
      duration: 250,
    };
  },
  computed: {
    transition() {
      return Object.keys(this.cachedStyles)
        .map((key) => `${key} ${this.duration}ms ease-in-out`)
        .join(", ");
    },
  },
  methods: {
    enter(el, done) {
      this.detectAndCacheDimensions(el);

      this.setDimensions(el, "close");
      el.style.overflow = "hidden";
      this.forceRepaint(el);
      el.style.transition = this.transition;
      this.setDimensions(el, "open");

      setTimeout(done, this.duration);
    },
    afterAnimation(el) {
      el.style.overflow = "";
      el.style.transition = "";
      this.setDimensions(el, "unset");
      this.cachedStyles = null;
    },
    leave(el, done) {
      this.detectAndCacheDimensions(el);

      this.setDimensions(el, "open");
      el.style.overflow = "hidden";
      this.forceRepaint(el);
      el.style.transition = this.transition;
      this.setDimensions(el, "close");

      setTimeout(done, this.duration);
    },
    detectAndCacheDimensions(el) {
      if (this.cachedStyles) return;

      const visibility = el.style.visibility;
      const display = el.style.display;

      el.style.visibility = "hidden";
      el.style.display = "";

      this.cachedStyles = {
        height: el.offsetHeight + "px",
        opacity: "1",
        "padding-top": el.style.paddingTop || this.getCssValue(el, "padding-top"),
        "padding-bottom": el.style.paddingBottom || this.getCssValue(el, "padding-bottom"),
      };

      el.style.visibility = visibility;
      el.style.display = display;
    },
    setDimensions(el, value) {
      Object.keys(this.cachedStyles).forEach((key) => {
        const values = {
          close: "0",
          open: this.cachedStyles[key],
          unset: "",
        };
        el.style[key] = values[value];
      });
    },
    forceRepaint(el) {
      return getComputedStyle(el)["height"];
    },
    getCssValue(el, style) {
      return getComputedStyle(el, null).getPropertyValue(style);
    },
  },
};
</script>
