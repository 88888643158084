export default {
  Europe: "icon-eu-new",
  Gift: "icon-gift",
  Sms: "envelope",
  Voice: "phone",
  Phone: "icon-phone",
  Internet: "icon-wireless",
  Coins: "coins",
  CoinsDashboard: "coins-dashboard",
  Wallet: "icon-wallet",
  Plans: "icon-plans",
  Paper: "icon-paper",
  Assignment: "icon-assignment",
  Requests: "icon-requests-2",
  Settings: "icon-settings-bold",
  Eye: "icon-eye",
  IdCard: "icon-id-card",
  Logout: "icon-logout",
  ProgressCircle: "progress-circle",
  Block: "block",
  PortOutNumber: "port-out-number",
  Facebook: "facebook",
  Google: "social-google",
  CardAdd: "card-add",
  CardAdded: "card-added",
  CardWarning: "card-warning",
  CardError: "card-error",
  ReadRequest: "envelope-open",
  UnreadRequest: "curved-arrow-right",
  Paid: "paid",
  Unpaid: "unpaid",
  PartlyPaid: "partly-paid",
  Pending: "pending",
  SafetyCode: "safety-code",
  Cell: "cell-filled",
  PaymentCard: "payment-card",
  Lock: "lock-filled",
  Close: "close",
  CloseBold: "close-bold",
  Infinity: "infinity",
  Blocked: "blocked",
  Undo: "undo",
  Calendar: "calendar",
  Call: "call",
  PhoneCall: "phone-call",
  SmsIcon: "sms",
  RefillCard: "refill-card",
  RefillBank: "refill-bank",
  RefillMobile: "refill-mobile",
  RefillCheck: "refill-check",
  CardFilled: "card-filled",
  Info: "info",
  Check: "check",
  Cart: "cart",
  RoundedArrorRight: "rounded-arrow-right",
  Reload: "reload",
  Remove: "remove-icon",
  Security: "security",
  Add: "add",
  Clock: "clock",
  Delete: "trash",
  BackArrow: "back-arrow",
  Trash: "trash",
  Update: "update",
  Browse: "browse",
  SimCard: "sim-card",
  ExclamationMark: "new-exclamation-mark",
  TransferredBucket: "transferred-bucket",
  ChangesStep1: "change-step-1",
  ChangesStep2: "change-step-2",
  ChangesStep3: "change-step-3",
  PukCode: "puk-code",
  Pencil: "pencil",
  ESimCardBlack: "esim-black",
  ESimCardWhite: "esim-white",
  RefillBankNew: "icon-refill-bank",
  RefillCardNew: "icon-refill-card",
  RefillCardNewExpired: "icon-refill-card-expired",
  RefillGooglePay: "icon-refill-google-pay",
  ApplePayMark: "apple-pay-mark",
  SmartPhone: "smart-phone",
  PlanWallet: "icon-wallet-plan",
  PlanEu: "icon-eu-plan",
};
