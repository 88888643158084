<template>
  <div class="v-narsyk-header">
    <img :src="image" class="v-narsyk-header--image" />
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="less">
.v-narsyk-header {
  height: 60px;
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0px 1px 10px #DFE5F4;
  position: relative;
  z-index: 1;

  &--image {
    width: 80px;
    height: auto;
    margin: auto;
    transform: translate(0, 8px);
  }
}
</style>
