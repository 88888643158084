import store from "@/store";
import constants from "@/constants";

const formatData = (data) => ({
  copyright: constants.Resources.Footer?.Copyright,
  apps: {
    stores: data.apps,
    title: _translations("footer_apps_title"),
  },
  policies: [
    ...(data.policies?.data || []),
    {
      name: _translations("footer_info_cookies_settings"),
      cookies: true,
    }
  ],
  help: {
    longNumber: {
      name: _translations("footer_help_longnumber"),
      price: {
        prefix: _translations("footer_help_longnumber_price"),
      },
      url: _translations("footer_help_longnumber_url"),
    },
    shortNumber: {
      name: _translations("footer_help_shortnumber"),
      price: {
        amount: _translations("footer_help_shortnumber_price"),
        prefix: _translations("footer_help_shortnumber_prefix"),
      },
      url: _translations("footer_help_shortnumber_url"),
    },
    support: {
      hours: _translations("footer_help_longnumber_time"),
      label: _translations("footer_help_label"),
    },
    title: _translations("footer_help_title"),
  },
  info: data.info,
  plans: data.plans,
  services: data.services,
  devices: data.devices,
  socials: {
    ...data.socials,
    title: _translations("footer_social_title"),
  },
  languageTitle: _translations("footer_help_languages"),
  languages: _formatLanguages(),
});

const _translations = (id) => {
  return store.getters["translations/getValue"]("public")(id);
};

const _formatLanguages = () => constants.Languages.map((value) => ({
  value,
  flag: constants.Images.LanguageFlags[value.toUpperCase()],
  selected: value === store.getters["user/getLanguage"],    
}));

export default { formatData };
