<template>
  <t2-error
    :data="pageData"
    @click="handleRedirect"
  />
</template>

<script>
import constants from "@/constants";
import T2Error from "@atomic/pages/Error";
import store from "@/store";

export default {
  components: { T2Error },
  inheritAttrs: false,
  data() {
    return {
      loaded: false,
      pageData: {
        header: {
          heading: constants.Resources.PageNotFound.PageNotFound,
          image: {
            url: "/img/mascotts/404.png?v=2",
            align: "middle"
          }
        },
        content: {
          statusCode: 404,
          description: `${constants.Resources.PageNotFound.PageNotFoundApologies}\n ${constants.Resources.PageNotFound.PageNotFoundSuggestion}`,
          image: "/img/heroes/Mira-shadow.png",
          button: constants.Resources.PageNotFound.PageNotFoundReturn
        }
      }
    };
  },
  methods: {
    handleRedirect() {
      return this.$store.getters["common/getPreviousRoute"] === "/"
        ? this.$router.push(store.getters["user/getLanguageRoutes"].Home)
        : this.$router.go(-1);
    }
  }
};
</script>
