<template>
  <div class="v-consents-campaign-consents">
    <div class="v-consents-campaign-consents--header">
      <img :src="data.image" class="v-consents-campaign-consents--image" />
      <div class="v-consents-campaign-consents--title">
        {{ data.title }}
      </div>
      <p
        class="v-consents-campaign-consents--description"
        v-html="data.description"
      />
    </div>
    <div class="v-consents-campaign-consents--container">
      <div
        v-for="(item, index) in items"
        :key="`consent-${index}`"
        class="v-consents-campaign-consents--item"
      >
        <div class="v-consents-campaign-consents--item-title--wrapper">
          <div class="v-consents-campaign-consents--item-title">
            {{ item.title }}
          </div>
          <t2-toggle-checkbox
            v-model="items[index].agreed"
            with-smile
          />
        </div>
        <p class="v-consents-campaign-consents--item-description">
          {{ item.description }}
        </p>
      </div>
      <div
        class="v-consents-campaign-consents--item-description"
        @click.prevent="handleLinkClick"
        v-html="data.consentsDescription"
      />
    </div>
    <div
      v-loader="data.loader"
      class="v-consents-campaign-modal--button-wrapper"
    >
      <t2-button secondary @click="handleSubmit">
        {{ data.buttons.agreeSelected }}
      </t2-button>
      <t2-button @click="handleSubmitSelected">
        {{ data.buttons.agreeAll }}
      </t2-button>
    </div>
  </div>
</template>
<script>
import T2Button from "@atomic/atoms/Button";
import T2ToggleCheckbox from "@atomic/molecules/ToggleCheckbox";

export default {
  components: {
    T2Button,
    T2ToggleCheckbox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "save-consents",
    "link-click",
  ],
  data() {
    return {
      items: this.data.items,
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("save-consents", this.items);
    },
    handleSubmitSelected() {
      this.items.map((item) => (item.agreed = true));
      this.handleSubmit();
    },
    handleLinkClick(e) {
      if (e.target.href) {
        this.$emit("link-click", e.target.href);
      }
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-consents-campaign-consents {
  width: 100%;
  &--header {
    background: linear-gradient(
      90deg,
      #009edb 1%,
      #009edb 31%,
      #33a8de 57%,
      #65b1e2 86%,
      #78b5e3 100%
    );
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 30px 30px;
    position: relative;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 66px 25px 25px;
    }
  }
  &--image {
    max-width: 247px;
    height: 135px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    @media screen and (max-width: @media-breakpoint-sm-max) {
      max-width: 161px;
      height: 83px;
    }
  }
  &--title {
    font-weight: bold;
    color: @color-white;
    text-transform: uppercase;
    font-size: 34px;
    max-width: 420px;
    margin-bottom: 25px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 22px;
      max-width: 308px;
      margin-bottom: 15px;
    }
  }
  &--description {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    max-width: 420px;
    color: @color-white;
    margin: 0;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 16px;
      line-height: 20px;
      max-width: 308px;
    }
  }
  &--container {
    background-color: @color-white;
    max-height: 420px;
    height: 100%;
    overflow-y: scroll;
    padding: 0 15px 0 35px;
    margin: 35px 8px 10px 0;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 50px @color-white;
      border-radius: 50px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
      @media screen and (max-width: @media-breakpoint-sm-max) {
        width: 3px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      -webkit-box-shadow: inset 0 0 50px #e0e0e0;
      background-color: #e0e0e0;
      height: 50px;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 0 10px 0px 22px;
    }
  }
  .v-consents-campaign-modal--button-wrapper {
    box-shadow: 0px -7px 24px rgba(140, 151, 174, 0.12);
    @media screen and (max-width: @media-breakpoint-sm-max) {
      flex-direction: column;
      margin: 0;
      align-items: center;
      button {
        max-width: 310px;
        width: 100%;
        &:first-child {
          margin-bottom: 14px;
        }
      }
    }
  }
  &--item {
    margin-bottom: 25px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      margin-bottom: 17px;
    }
    &-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 15px;
      &--wrapper {
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        padding-right: 20px;
      }
    }
    &-description {
      padding-right: 80px;
      font-size: 14px;
      line-height: 20px;
      color: @color-text-dark;
      @media screen and (max-width: @media-breakpoint-sm-max) {
        padding-right: 0;
        font-size: 13px;
      }
    }
  }
}
</style>
