import router from "@/router/pildyk";
import axiosClient from "@/common/axiosClient";
import store from "@/store";
import constants from "@/constants";

const logOut = () => {
  return store.dispatch("user/clearUserData")
    .then(() => {
      return router.push({
        path: store.getters["user/getLanguageRoutes"].Login,
        query: { useAutoLogin: false },
      });
    });

};

const checkAutoLogin = () => {
  return axiosClient
    .get("/auth/auto-login/check")
    .then((response) => {
      store.commit("user/setTryAutoLogin", response.data.result);
    })
    .catch(() => {
      store.commit("user/setTryAutoLogin", false);
    });
};

const getToken = () => {
  return axiosClient
    .get("/auth/token")
    .then(({ data }) => store.dispatch("user/setAuthToken", data.result))
    .catch();
};

const getLiveChatToken = () => {
  return axiosClient.get("/live-chat/token");
}

const getForceFinishPath = () => {
  const isForceRegistration = store.getters["user/isForceFinishRegistration"];
  const isForcePasswordChange = store.getters["user/isForcePasswordChange"];
  const isForceRedirect = isForceRegistration || isForcePasswordChange;

  if (!isForceRedirect) {
    return null;
  }

  if (isForceRegistration) {
    return {
      path: store.getters["user/getLanguageRoutes"].Registration,
      hash: constants.Hashes[store.getters["user/getLanguage"].toUpperCase()]
        .RegistrationConfirm,
    };
  }

  return {
    path: store.getters["user/getLanguageRoutes"].PasswordRecovery,
    hash: constants.Hashes[store.getters["user/getLanguage"].toUpperCase()]
      .PasswordRecoveryConfirm,
  };
}

export default {
  logOut,
  checkAutoLogin,
  getToken,
  getLiveChatToken,
  getForceFinishPath,
};
