<template>
  <div class="v-fonos-prize-won-banner">
    <div class="v-fonos-prize-won-banner--title">{{ data.title }}</div>
    <div class="v-fonos-prize-won-banner--images">
      <img :src="data.image" class="v-fonos-prize-won-banner--image" />
      <img :src="data.emoji" class="v-fonos-prize-won-banner--image" />
    </div>
    <div class="v-fonos-prize-won-banner--description">
      {{ data.description }}
    </div>
    <div>
      <t2-button
        v-loader="data.buttonLoader"
        class="v-fonos-prize-won-banner--button"
        pink
        @click="$emit('receive-prize-click')"
      >
        {{ data.button }}
      </t2-button>
    </div>
    <span @click="$emit('close-click')">
      <t2-svg icon-id="close" class="v-fonos-prize-won-banner--close" />
    </span>
  </div>
</template>
<script>
import T2Button from "@atomic/atoms/Button";
import T2Svg from "@atomic/atoms/Svg";

export default {
  components: {
    T2Button,
    T2Svg,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "receive-prize-click",
    "close-click"
  ]
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-fonos-prize-won-banner {
  position: absolute;
  top: 0;
  background: linear-gradient(90.74deg, #4589da 8.26%, #004498 104.11%);
  height: 130px;
  width: 100%;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7% 0 5%;
  @media screen and (max-width: @media-breakpoint-lg-min) {
    padding: 0 5% 0 3%;
  }
  @media screen and (max-width: @media-breakpoint-sm-max) {
    flex-direction: column;
    align-items: initial;
    height: auto;
    padding: 25px 24px 20px;
    background: linear-gradient(180deg, #004498 25.52%, #4589da 100%);
  }
  &--title {
    font-size: 28px;
    line-height: 38px;
    max-width: 297px;
    font-weight: bold;
    color: @color-white;
    text-transform: uppercase;
    @media screen and (max-width: @media-breakpoint-lg-min) {
      max-width: 240px;
      font-size: 24px;
      line-height: 32px;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 18px;
      line-height: 26px;
      max-width: 183px;
      margin-bottom: 18px;
    }
  }
  &--images {
    @media screen and (max-width: @media-breakpoint-sm-max) {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 10%;
      top: 36px;
    }
  }
  &--image {
    height: 87px;
    max-width: 100%;
    @media screen and (max-width: @media-breakpoint-lg-min) {
      height: 65px;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      height: 61px;
    }
    &:first-child {
      margin-right: 13px;
      @media screen and (max-width: @media-breakpoint-sm-max) {
        margin-right: 0;
        margin-bottom: 13px;
      }
    }
  }
  &--description {
    color: @color-white;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    @media screen and (max-width: @media-breakpoint-lg-min) {
      max-width: 120px;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 15px;
      max-width: 171px;
    }
  }
  &--close {
    fill: @color-white;
    position: absolute;
    cursor: pointer;
    height: 21px;
    width: 21px;
    right: 12px;
    top: 12px;
  }
}
</style>
