const getPositionValue = (value, index = 0) => {
  return value && value !== 0 ? value - 1 : index;
};

const setOrderPosition = (el, { value }) => {
  const currSortNo = getPositionValue(value.items[value.index][value.sortParameter], value.index);
  el.classList.add("v-item-position-order");
  el.style = `--order: ${currSortNo}`;

  // Code snippet is disabled due to plan sorting
  // if (!value.items || value.items.length === (value.index + 1)) {
  //   return;
  // }

  // const nextItem = value.items[value.index + 1];
  // const nextItemSortNo = getPositionValue(nextItem[value.sortParameter], value.index + 1);
  // const isSpaceNeeded = nextItemSortNo - currSortNo > 1;

  // if (isSpaceNeeded && el.nextSibling.classList?.contains("v-item-position-break")) {
  //   return;
  // }

  // if (el.nextSibling.classList?.contains("v-item-position-break")) {
  //   el.nextSibling.remove();
  // }

  // if (isSpaceNeeded) {
  //   const breakElement = document.createElement("div");
  //   breakElement.classList.add("v-item-position-break");
  //   breakElement.style = `--order: ${value.index + 1}`;
  //   el.parentNode.insertBefore(breakElement, el.nextSibling);

  //   return;
  // }
};

export default {
  mounted: setOrderPosition,
  updated: setOrderPosition,
};
