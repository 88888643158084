const updateTableScroll = (el) => {
  let tables = el.getElementsByTagName("table");
  if (tables.length) {
    Array.from(tables).forEach((table) => {
      let parent = table.parentNode;
      if (!parent.classList.contains("table-draggable")) {
        let wrapper = document.createElement("div");
        wrapper.classList.add("table-draggable");
        parent.insertBefore(wrapper, table);
        wrapper.appendChild(table);
      }
    });
  }
};

export default {
  beforeMount: updateTableScroll,
  updated: updateTableScroll,
};
