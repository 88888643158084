const pushDiscountsView = (discounts) => {
  if (!window.dataLayer) {
    return;
  }
  var discountList = [];
  for (var i = 0; i < discounts.length; i++) {
    discountList.push({
      id: discounts[i].id,
      name: discounts[i].title,
      position: discounts[i].position,
    });
  }

  window.dataLayer.push({
    event: "promotionView",
    ecommerce: {
      promoView: {
        promotions: discountList,
      },
    },
  });
};

const pushDiscountClick = (discount) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({
    event: "promotionClick",
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: discount.id,
            name: discount.title,
            position: discount.position,
          },
        ],
      },
    },
  });
};

const pushPageView = (url) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({
    event: "VirtualPageview",
    page_url: url,
  });
};

const pushOrderFormSubmit = (comment) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({
    event: "form_submission",
    productName: comment,
  });
};

export default {
  pushDiscountsView,
  pushDiscountClick,
  pushPageView,
  pushOrderFormSubmit,
};
