export default {
  HardwareDetailsModal: "hardware-details-modal",
  HardwarePreOrderFirst: "hardware-order-first",
  HardwarePreOrderSecond: "hardware-order-second",
  AdditionalService: "additional-service-modal",
  NewRequestModal: "new-request-modal",
  NotEnoughBalance: "not-enough-balance",
  PlansCancellation: "plans-cancelation",
  FonosPrizeReceive: "fonos-prize-receive-modal",
  PrivacyPolicy: "privacy-policy-modal",
  ChangeIdCard: "change-id-card",
  Spotify: "spotify",
  OfferForm: "receive-offer-modal",
  PlanTariffs: "plan-tariffs-modal",
  ConsentCampaign: "consents-campaign-modal",
  AddPopUp: "add-popup-message",
  ConfirmLogOut: "confirm-logout",
  InactiveCardSubmit: "inactive-card-submit",
  GiveawayDeliveryModal: "giveaway-delivery-modal",
  ConfirmPlanCancellation: "confirm-plan-cancellation"
};
