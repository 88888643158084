const handleClickEvent = (e, binding) => {
  if (e.target.href && e.target.target !== "_blank") {
    e.preventDefault();
    binding.instance.$emit("href-click", { url: e.target.href });

    return;
  }

  const parent = e.target.parentElement;
  if (parent.href && parent.target !== "_blank") {
    e.preventDefault();
    binding.instance.$emit("href-click", { url: parent.href });

    return;
  }

  const greatParent = e.target.parentElement.parentElement; 
  if (greatParent.href && greatParent.target !== "_blank") {
    e.preventDefault();
    binding.instance.$emit("href-click", { url: greatParent.href });

    return;
  }
};

export default {
  beforeMount(el, binding) {
    return el.addEventListener("click", handleClickEvent.bind(null, el, binding));
  },
  beforeUnmount(el) {
    return el.removeEventListener("click", handleClickEvent);
  },
};
