import CorporateApi from "@/api/corporate";

const translations = {
  namespaced: true,
  state: {
    data: {}
  },
  mutations: {
    pushCategory(state, { category, values }) {
      var categoryTranslations = {
        [category]: values
      };
      state.data = { ...state.data, ...categoryTranslations };
    },
    resetData(state) {
      state.data = {};
    }
  },
  actions: {
    fetch({ commit, getters }, data) {
      var filteredCategories = data.categories.filter(
        category => !getters.categoryLoaded(category)
      );
      if (filteredCategories && filteredCategories.length) {
        return CorporateApi.getTranslations(
          filteredCategories,
          data.language
        ).then(response => {
          var responseData = response.data;
          for (var category in responseData) {
            var values = responseData[category];
            commit("pushCategory", { category, values });
          }
        });
      }
    },
    reload({ commit, dispatch, getters }, language) {
      let loadedCategories = getters.loadedCategories;
      if (loadedCategories.length > 0) {
        commit("resetData");
        return dispatch("fetch", {
          categories: loadedCategories,
          language: language
        });
      }
      return Promise.resolve();
    }
  },
  getters: {
    categoryLoaded: state => category => {
      return state.data[category] !== undefined;
    },
    getValue: (state, getters) => category => id => {
      if (getters.categoryLoaded(category)) {
        if (state.data[category][id]) {
          return state.data[category][id];
        } else if (process.env.NODE_ENV === "development") {
          return category + "." + id;
        }
      }
      return "";
    },
    loadedCategories: state => {
      return Object.keys(state.data);
    }
  }
};

export default translations;
