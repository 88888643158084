import constants from "@/constants";
import CorporateApi from "@/api/corporate";

const header = {
  namespaced: true,
  state: {
    isLoading: true,
    data: null
  },
  mutations: {
    setData(state, data) {
      state.data = data;
      state.isLoading = false;
    },
    unshiftSubmenuElement(state, element) {
      if(!state.isLoading) {
        state.data.submenu.unshift(element);
      }
    }
  },
  actions: {
    fetch({ commit }, language = constants.DefaultLanguage) {
      return CorporateApi.getHeader(language).then(response => {
        commit("setData", response.data);
      });
    }
  },
  getters: {
    hasData: state => {
      return !state.isLoading;
    },
    getData: state => {
      return state.data;
    }
  }
};

export default header;
