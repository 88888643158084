import CorporateApi from "@/api/corporate";

const metadata = {
  namespaced: true,
  state: {
    data: {}
  },
  mutations: {
    pushAlias(state, { alias, values }) {
      var aliasMetadata = {
        [alias]: values
      };
      state.data = { ...state.data, ...aliasMetadata };
    }
  },
  actions: {
    fetch({ commit, getters }, alias) {
      if (getters.aliasLoaded(alias)) {
        return Promise.resolve();
      }

      let values;

      return CorporateApi.getPageMetadata(alias).then(({ data }) => {
        values = data.data;
      }).catch(() => {
        values = null;
      }).finally(() => {
        commit("pushAlias", { alias, values });
      });
    }
  },
  getters: {
    aliasLoaded: state => alias => {
      return state.data[alias] !== undefined;
    },
    getValue: state => alias => {
      return state.data[alias];
    }
  }
};

export default metadata;
