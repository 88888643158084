<template>
  <t2-modal id="consents-campaign-modal" class="v-consents-campaign-modal">
    <component
      :is="data.component"
      :data="data.content"
      @participate-click="$emit('participate-click')"
      @game-rules-click="$emit('game-rules-click')"
      @save-consents="$emit('save-consents', $event)"
      @link-click="$emit('link-click', $event)"
      @close-click="$emit('close-click')"
    />
  </t2-modal>
</template>
<script>
import T2Modal from "@atomic/molecules/Modal";
import T2ConsentsCampaignMain from "@atomic/organisms/Campaigns/ConsentsCampaign/Main";
import T2ConsentsCampaignConsents from "@atomic/organisms/Campaigns/ConsentsCampaign/Consents";
import T2ConsentsCampaignActivated from "@atomic/organisms/Campaigns/ConsentsCampaign/Activated";
import T2ConsentsCampaignSaved from "@atomic/organisms/Campaigns/ConsentsCampaign/Saved";

export default {
  components: {
    T2Modal,
    T2ConsentsCampaignMain,
    T2ConsentsCampaignConsents,
    T2ConsentsCampaignActivated,
    T2ConsentsCampaignSaved,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "participate-click",
    "game-rules-click",
    "save-consents",
    "link-click",
    "close-click"
  ],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-consents-campaign-modal {
  .popup {
    &--dialog {
      padding: 0;
      max-width: 644px;
      border-radius: 8px;
      &-holder {
        @media screen and (max-width: @media-breakpoint-sm-max) {
          margin: 15% auto;
        }
      }
    }
    &--content {
      padding: 0;
    }
    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
      svg {
        fill: @color-white;
      }
    }
  }
  &--button-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 13px;
    }
    button {
      letter-spacing: 0px;
      margin: 0 10px;
    }
  }
}
</style>
