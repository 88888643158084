<template>
  <div class="v-campaign-banner">
    <t2-svg
      iconId="close"
      class="v-campaign-banner--close"
      @click="$emit('close-click')"
    ></t2-svg>
    <slot></slot>
  </div>
</template>
<script>
import T2Svg from "@atomic/atoms/Svg";

export default {
  components: {
    T2Svg,
  },
  emits: ["close-click"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";

.v-campaign-banner {
  position: absolute;
  top: 0;
  background-color: @color-blue;
  min-height: 130px;
  width: 100%;
  z-index: 9;

  &--close {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 21px;
    width: 21px;
    fill: @color-white;
    cursor: pointer;
    z-index: 1;
  }
}
</style>
