<template>
  <t2-modal v-if="data" :id="id" class="v-confirm-modal">
    <div v-if="data.image" class="v-confirm-modal--image">
      <img :src="data.image" />
    </div>
    <t2-heading v-if="data.title" level="4" class="v-confirm-modal--title">
      {{ data.title }}
    </t2-heading>
    <p v-if="data.description" class="v-confirm-modal--description">
      {{ data.description }}
    </p>
    <slot></slot>
    <div class="v-confirm-modal--buttons">
      <t2-button
        v-if="data.decline"
        class="v-button--secondary v-confirm-modal--buttons-decline"
        @click="emitter.emit('close-modal', { id })"
      >
        {{ data.decline }}
      </t2-button>
      <t2-button
        v-if="data.confirm"
        v-loader="data.loader"
        class="v-confirm-modal--buttons-confirm"
        @click="$emit('confirm-click')"
      >
        {{ data.confirm }}
      </t2-button>
    </div>
  </t2-modal>
</template>
<script>
import T2Button from "@atomic/atoms/Button";
import T2Heading from "@atomic/atoms/Heading";
import T2Modal from "@atomic/molecules/Modal";
export default {
  components: {
    T2Button,
    T2Modal,
    T2Heading,
  },
  inject: ["emitter"],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: "confirm-modal",
    },
  },
  emits: ["confirm-click"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-confirm-modal {
  .popup--content {
    padding: 0 45px 28px;
    @media screen {
      @media (max-width: @media-tablet-portrait) {
        padding: 0 30px 0;
      }
      @media (max-width: @media-mobile-portrait) {
        padding: 0 30px 0;
      }
    }
  }
  &--image {
    width: 80px;
    height: 80px;
    text-align: center;
    img {
      width: auto;
      height: 100%;
    }
  }
  &--title {
    font-size: 18px;
    margin: 37px 0 0;
  }
  &--buttons {
    display: flex;
    margin-top: 42px;
    button {
      margin: 0 10px;
    }
    @media screen and (max-width: @media-breakpoint-sm-max) {
      flex-direction: column-reverse;
      justify-content: center;
      max-width: 200px;
      width: 100%;
      button {
        margin: 0 0 10px;
      }
    }
  }

  &--description {
    margin: 20px 0 0;
    text-align: center;
  }
}
</style>
