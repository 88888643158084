const getHost = (url) => {
  return _trimWWW(_urlToLocation(url).hostname);
};

const getPath = (url) => {
  const path =
    _urlToLocation(url).pathname +
    _urlToLocation(url).search +
    _urlToLocation(url).hash;
  return path.substring(0, 1) !== "/" ? "/" + path : path;
};

const _urlToLocation = (url) => {
  const location = document.createElement("a");
  location.href = url;
  if (location.host === "") {
    location.host = window.location.hostname;
  }
  return location;
};

const _trimWWW = (url) => {
  return url.replace("www.", "");
};

export default {
  getHost,
  getPath,
};
