const hide = (el, binding) => {
  if (!binding.value.mobileEnabled && screen.width < 769) {
    return;
  }

  const elements = el.querySelectorAll(binding.value.selector);
  [...elements].forEach((element) => {
    const translateX = binding.value.translateX ?? "0";
    const translateY = binding.value.translateY ?? "40px";
    element.style.opacity = 0;
    element.style.transform = `translate(${translateX},${translateY})`;
    element.style.transition = binding.value.transition ?? "0.4s ease-out";
    element.style.transitionDelay = binding.value.transitionDelay ?? "0.1s";
  });
};

const manageReveal = (el, binding) => {
  const elements = el.querySelectorAll(binding.value.selector);
  handleScroll(el, elements);
  document.addEventListener("scroll", handleScroll.bind(null, el, elements));
};

const reveal = (elements) => {
  [...elements].forEach((element, index) => {
    setTimeout(() => {
      element.style.opacity = 1;
      element.style.transform = "translate(0,0)";
      setTimeout(() => {
        element.style.transition = null;
        element.style.transform = null;
        element.style.opacity = null;
      }, 400);
    }, (index + 1) * 200);
  });
};

const unbindListeners = (el, binding) => {
  const elements = el.querySelectorAll(binding.value.selector);
  document.addEventListener("scroll", handleScroll.bind(null, el, elements));
};

const handleScroll = (element, elements) => {
  if (!isOnScreen(element)) {
    return;
  }

  return reveal(elements);
};

const isOnScreen = (element) => {
  const position = element.getBoundingClientRect();
  return position.top < window.innerHeight && position.bottom >= 0;
};

export default {
  bind: hide,
  unbind: unbindListeners,
  inserted: manageReveal,
};
