export default {
  LT: {
    RegistrationConfirm: "#patvirtinimas",
    PasswordRecoveryConfirm: "#patvirtinimas",
    RegistrationLoginInfo: "#prisijungimo-informacija",
  },
  EN: {
    RegistrationConfirm: "#confirm",
    PasswordRecoveryConfirm: "#confirm",
    RegistrationLoginInfo: "#login-information",
  },
  RU: {
    RegistrationConfirm: "#confirm",
    PasswordRecoveryConfirm: "#confirm",
    RegistrationLoginInfo: "#login-information",
  },
};
