import CorporateApi from "@/api/corporate";
import constants from "@/constants";

const footer = {
  namespaced: true,
  state: {
    isLoading: true,
    data: null
  },
  mutations: {
    setData(state, data) {
      state.data = data;
      state.isLoading = false;
    }
  },
  actions: {
    fetch({ commit }, language = constants.DefaultLanguage) {
      return CorporateApi.getFooter(language).then(({ data }) => {
        commit("setData", data);
      });
    }
  },
  getters: {
    hasData: state => {
      return !state.isLoading;
    },
    getData: state => {
      return state.data;
    }
  }
};

export default footer;
