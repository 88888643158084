export default {
  CreditCardsPPOrder: "CreditCardsPPOrder",
  AwardsCampaignActiveFrom: "AwardsCampaignActiveFrom",
  AwardsCampaignActiveTo: "AwardsCampaignActiveTo",
  ConsentCampaignValidFrom: "ConsentCampaignValidFrom",
  ConsentCampaignValidTo: "ConsentCampaignValidTo",
  RefillCampaignStartDate: "RefillCampaignStartDate",
  RefillCampaignEndDate: "RefillCampaignEndDate",
  ChristmasCampaignStartDate: "ChristmasCampaignStartDate",
  ChristmasCampaignEndDate: "ChristmasCampaignEndDate",
  IsicCampaignValidFrom: "IsicCampaignValidFrom",
  IsicCampaignValidTo: "IsicCampaignValidTo",
  IsicPricePlanId: "IsicPricePlanId",
  WhaleboneManagementEnabled: "WhaleboneManagementEnabled",
  ESimChangePricePlanId: "ESimChangePricePlanId",
};
