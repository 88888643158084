<template>
  <div class="v-consents-campaign-activated">
    <div class="v-consents-campaign-activated--container">
      <div class="v-consents-campaign-activated--title">
        {{ data.title }}
      </div>
      <p class="v-consents-campaign-activated--description">
        {{ data.description }}
      </p>
      <img :src="data.image" class="v-consents-campaign-activated--image" />
    </div>
    <div class="v-consents-campaign-modal--button-wrapper">
      <t2-button @click="$emit('close-click')">{{ data.button }}</t2-button>
    </div>
  </div>
</template>
<script>
import T2Button from "@atomic/atoms/Button";

export default {
  components: {
    T2Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["close-click"],
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-consents-campaign-activated {
  width: 100%;
  &--container {
    background: linear-gradient(
      270deg,
      #009edb 1%,
      #009edb 31%,
      #33a8de 57%,
      #65b1e2 86%,
      #78b5e3 100%
    );
    border-radius: 8px 8px 0 0;
    padding: 50px 80px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 50px 20px 15px;
    }
  }
  &--title {
    text-transform: uppercase;
    color: @color-white;
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 15px;
    max-width: 517px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 22px;
      line-height: 32px;
      max-width: 308px;
      margin-bottom: 20px;
    }
  }
  &--description {
    color: @color-white;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin: 0 0 20px;
    max-width: 377px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      font-size: 16px;
      line-height: 20px;
      max-width: 308px;
      margin-bottom: 20px;
    }
  }
  &--image {
    max-width: 339px;
    height: auto;
    padding: 15px;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      max-width: 269px;
    }
  }
  button {
    font-style: italic;
  }
}
</style>
