import axiosClient from "@/common/axiosClient";
import constants from "@/constants";

const getGroupedBuckets = () => {
  return axiosClient.get("/user-information/v2/grouped-buckets");
};

const getPlans = () => {
  return axiosClient.get(`/price-plan/v3/plans-channels`);
};

const orderPlans = (data) => {
  return axiosClient.post("/price-plan/order", data);
};

const getToken = () => {
  return axiosClient.get("/narsyk/auth/token");
};

const getResources = (resources, reload = false) => {
  if (typeof resources === "string") {
    return _loadSingleResourceGroup(reload, resources);
  }

  if (Array.isArray(resources)) {
    if (!reload) {
      var alreadyLoadedGroups = resources.filter(
        (e) => Object.keys(constants.Resources).indexOf(e) !== -1
      );

      if (alreadyLoadedGroups.length > 0) {
        alreadyLoadedGroups.forEach((group) => {
          var index = resources.indexOf(group);

          if (index > -1) {
            resources.splice(index, 1);
          }
        });
      }
    }

    if (resources.length > 0) {
      return axiosClient
        .get(`/resource/groups?groups=` + resources.join("&groups="))
        .then((response) => {
          constants.Resources = { ...constants.Resources, ...response.data };
        });
    }
  }

  return Promise.resolve();
};

const _loadSingleResourceGroup = (reload, group) => {
  if (!reload && Object.keys(constants.Resources).indexOf(group) > -1) {
    return Promise.resolve();
  }

  return axiosClient
    .get(`/resource/groups?groups=${group}`)
    .then((response) => {
      constants.Resources = { ...constants.Resources, ...response.data };
    });
};

export default {
  getGroupedBuckets,
  getPlans,
  orderPlans,
  getToken,
  getResources,
};
