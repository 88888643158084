<template>
  <t2-confirm-modal 
    :id="inactiveCardModal"
    class="payment-confirmation" 
    :data="data"
    @confirm-click="confirmSubmit">
    <div class="payment-confirmation--checkbox-container">
      <t2-checkbox 
        name="save-card" 
        :label="saveCardLabel" 
        :checked="saveCard"
        @checkbox-change="setDefaultMethod = saveCard = $event"/>
      <t2-checkbox 
        v-show="saveCard" 
        name="set-default-method" 
        :label="setDefaultMethodLabel" 
        :checked="setDefaultMethod"
        @checkbox-change="setDefaultMethod = $event"/>
    </div>
  </t2-confirm-modal>
</template>
<script>
import { T2ConfirmModal } from "@atomic/organisms/Modals/ConfirmModal";
import T2Checkbox from "@atomic/atoms/Checkbox";
import Service from "./services";
import constants from "@/constants";

export default {
  components: {
    T2ConfirmModal,
    T2Checkbox
  },
  inject: ["emitter"],
  data() {
    return {
      id: constants.AwardsCampaign.Modals.Consents,
      data: null,
      inactiveCardModal: constants.Modals.InactiveCardSubmit,
      saveCard: false,
      saveCardLabel: constants.Resources.NewRefill.SaveCard,
      setDefaultMethod: false,
      setDefaultMethodLabel: constants.Resources.NewRefill.SetCreditCardAsDefaultMethod,
      callback: () => {}
    };
  },
  created() {
    this.data = Service.formatConfirmModal();

    if (this.emitter.all.has(constants.Events.ConfirmPaymentCard)) {
      this.emitter.off(constants.Events.ConfirmPaymentCard);
    }

    this.emitter.on(constants.Events.ConfirmPaymentCard, this.openModal);
  },
  methods: {
    openModal(callback) {
      this.callback = callback;

      this.emitter.emit(constants.ModalControl.Open, {
        id: this.inactiveCardModal,
      });
    },
    confirmSubmit() {
      this.callback(this.saveCard, this.setDefaultMethod);

      this.emitter.emit(constants.ModalControl.Close, {
        id: this.inactiveCardModal,
      });
    },
  },
};
</script>
<style lang="less" >
@import (reference) "~@/assets/less/variables.less";
.payment-confirmation {
  &--checkbox-container {
    background-color: #F2F4FA;
    width: 100%;
    padding: 20px;
    border-radius: 8px
  }

  .checkbox:not(:first-child) {
    margin-top: 20px;
  }

  .checkbox--item {
    top: 0px;
  }

  .v-confirm-modal {
    &--title {
      text-transform: none;
      margin-bottom: 20px;
    }

    &--image {
      height: 105px;
      margin: 30px 0;
    }

    @media screen and (max-width: @media-mobile-medium-portrait) {
      &--buttons {
        max-width: unset;
      }
    }

    @media screen and (max-width: @media-breakpoint-lg-min) {
      &--buttons-confirm, &--buttons-decline {
        min-width: 180px;
      }
    }
  }

  .popup--dialog {
    max-width: 672px;
    @media screen and (max-width: @media-mobile-portrait) {
      padding: 15px;
    }
  }

  .popup--content {
    padding: 0 20px 10px;
    @media screen and (max-width: @media-mobile-portrait) {
      padding: 5px;
    }
  }
}
</style>