<template>
  <t2-modal
    :id="modalId"
    class="v-fonos-prize-receive-modal"
  >
    <span @click="emitter.emit('close-modal', { id: modalId })">
      <t2-svg
        icon-id="close"
        class="v-fonos-prize-receive-modal--close"
      />
    </span>
    <div class="v-fonos-prize-receive-modal--title">
      {{ data.heading }}
    </div>
    <p
      class="v-fonos-prize-receive-modal--description"
      v-html="data.description"
    />
    <div class="v-fonos-prize-receive-modal--input-wrapper">
      <t2-input
        ref="firstName"
        v-model="firstName"
        class="v-fonos-prize-receive-modal--input"
        name="firstName"
        :label="data.inputFirstName.label"
        :validation="{
          rules: 'required',
          translations: { required: data.inputFirstName.emptyError },
        }"
      />
      <t2-input
        ref="lastName"
        v-model="lastName"
        class="v-fonos-prize-receive-modal--input"
        name="lastName"
        :label="data.inputLastName.label"
        :validation="{
          rules: 'required',
          translations: { required: data.inputLastName.emptyError },
        }"
      />
      <t2-input
        v-if="data.isPhone"
        ref="personalNumber"
        v-model="personalNumber"
        class="v-fonos-prize-receive-modal--input"
        type="code"
        maxlength="11"
        name="personalNumber"
        :label="data.inputPersonalNumber.label"
        :validation="{
          rules: 'required|personalNumber',
          translations: {
            required: data.inputPersonalNumber.emptyError,
            personalNumber: data.inputPersonalNumber.validationError,
          },
        }"
      />
      <t2-input
        v-if="data.isPhone"
        ref="address"
        v-model="address"
        class="v-fonos-prize-receive-modal--input"
        name="address"
        :label="data.inputAddress.label"
        :validation="{
          rules: 'required',
          translations: { required: data.inputAddress.emptyError },
        }"
      />
      <t2-custom-select
        ref="location"
        v-model="location"
        class="v-fonos-prize-receive-modal--input"
        :label="data.inputLocation.label"
        :selectEmpty="data.inputLocation.selectEmpty"
        :options="locationsOptions"
        :validation="{
          rules: 'required',
          translations: { required: data.inputLocation.emptyError },
        }"
      />
    </div>
    <t2-button
      v-loader="data.button.loader"
      class="v-fonos-prize-receive-modal--button"
      pink
      @click="data.isPhone ? submitPhone() : submit()"
    >
      {{ data.button.text }}
    </t2-button>
  </t2-modal>
</template>
<script>
import T2Button from "@atomic/atoms/Button";
import T2Svg from "@atomic/atoms/Svg";
import T2Modal from "@atomic/molecules/Modal";
import T2Input from "@atomic/molecules/Input";
import T2CustomSelect from "@atomic/molecules/CustomSelect";
import constants from "@/constants";

export default {
  components: {
    T2Button,
    T2Modal,
    T2Input,
    T2CustomSelect,
    T2Svg,
  },
  inject: ["emitter"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["submit"],
  data() {
    return {
      modalId: constants.Modals.FonosPrizeReceive,
      firstName: null,
      lastName: null,
      personalNumber: null,
      address: null,
      location: null,
    };
  },
  computed: {
    locationsOptions() {
      return this.data.inputLocation.locations
        .map((location, index) => ({
          name: location.name,
          subname: location.address,
          value: `${index}`,
        }));
    },
  },
  methods: {
    submit() {
      const validationResults = [
        this.$refs.firstName.hasErrors(),
        this.$refs.lastName.hasErrors(),
        this.$refs.location.hasErrors()
      ];

      if (validationResults.some(v => v)) {
        return;
      }

      this.$emit("submit", {
        firstName: this.firstName,
        lastName: this.lastName,
        location: this.data.inputLocation.locations[this.location],
      });
    },
    submitPhone() {
      const validationResults = [
        this.$refs.firstName.hasErrors(),
        this.$refs.lastName.hasErrors(),
        this.$refs.personalNumber.hasErrors(),
        this.$refs.address.hasErrors(),
        this.$refs.location.hasErrors()
      ];

      if (validationResults.some(v => v)) {
        return;
      }

      this.$emit("submit", {
        firstName: this.firstName,
        lastName: this.lastName,
        personalNumber: this.personalNumber,
        address: this.address,
        location: this.data.inputLocation.locations[this.location],
      });
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
.v-fonos-prize-receive-modal {
  .popup--dialog {
    padding: 50px;
    max-width: 800px;
    position: relative;
    @media screen and (max-width: @media-breakpoint-sm-max) {
      padding: 40px 30px;
    }
  }
  .popup--close {
    display: none;
  }
  .popup--content {
    padding: 0;
  }
  &--close {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 23px;
    width: 23px;
    cursor: pointer;
  }
  &--title {
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &--description {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin: 0 0 70px;
    max-width: 100%;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      margin-bottom: 40px;
    }
  }
  &--input {
    width: calc(50% - 20px);

    &:nth-child(2n + 1) {
      margin-right: 20px;
    }
    &:nth-child(2n) {
      margin-left: 20px;
    }

    @media screen and (max-width: @media-breakpoint-sm-max) {
      width: 100%;

      &:nth-child(2n + 1) {
        margin-right: unset;
      }
      &:nth-child(2n) {
        margin-left: unset;
      }
    }

    &-row {
      width: 100%;
      display: flex;

      @media screen and (max-width: @media-breakpoint-sm-max) {
        flex-direction: column;
      }
    }
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
    }
    .v-custom-select--dropdown-wrapper {
      top: 86px;
    }
    .v-input--container {
      height: 48px;
    }
    .v-input---has-error {
      margin-bottom: 0;
    }
    .v-input--error---container {
      position: static;
      bottom: auto;
      left: 0;
      background-color: transparent;
      padding: 3px 12px 6px 0;
      .v-input--error---icon {
        display: none;
      }
    }
    .v-input--container:not(.v-input---has-error),
    .v-custom-select--input-container:not(.v-input---has-error) {
      margin-bottom: 39px;
    }
    .v-input--label,
    .v-custom-select--label {
      margin: 0;
      padding: 8px 0;

      @media screen and (max-width: @media-breakpoint-sm-max) {
        padding-top: 0;
      }
    }
  }
  &--checkbox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 49px 0 43px;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      margin: 25px 0 25px;
    }
  }
  &--button {
    width: 340px;

    @media screen and (max-width: @media-breakpoint-sm-max) {
      width: 100%;
    }
  }
}
</style>
