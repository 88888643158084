<template>
  <div class="footer-menu">
    <div
      class="footer-menu--container footer-menu--container---main smaller"
      :class="{ 'footer-menu--container---smaller': isSmallerContainer }"
    >
      <div
        v-if="data.plans && data.plans.data.length"
        class="footer-menu--item footer-menu--item---plans footer-menu--item---space-between"
        :class="{ opened: accordions.fpl.opened }"
      >
        <t2-heading-arrow
          v-if="data.plans.title"
          class="footer-menu--title"
          @click="toggle(accordions.fpl)"
        >
          {{ data.plans.title }}
        </t2-heading-arrow>
        <component
          :is="isSm ? 't2-accordion' : 'div'"
          :show="isAccordionEnabled(accordions.fpl.opened)"
        >
          <ul class="footer-menu--list">
            <li
              v-for="(link, index) in data.plans.data"
              :key="'fpl-' + index"
              class="footer-menu--list-item"
            >
              <a
                class="footer-menu--list-link"
                :href="link.url"
                @click.prevent="$emit('link-click', link)"
              >
                {{ link.name }}
              </a>
            </li>
          </ul>
        </component>
      </div>
      <div
        v-if="data.devices && data.devices.data.length"
        class="footer-menu--item footer-menu--item---devices"
        :class="{ opened: accordions.fdl.opened }"
      >
        <t2-heading-arrow
          v-if="data.devices.title"
          class="footer-menu--title"
          @click="toggle(accordions.fdl)"
        >
          {{ data.devices.title }}
        </t2-heading-arrow>
        <component
          :is="isSm ? 't2-accordion' : 'div'"
          :show="isAccordionEnabled(accordions.fdl.opened)"
        >
          <ul class="footer-menu--list">
            <li
              v-for="(link, index) in data.devices.data"
              :key="'fdl-' + index"
              class="footer-menu--list-item"
            >
              <a
                class="footer-menu--list-link"
                :href="link.url"
                @click.prevent="$emit('link-click', link)"
              >
                {{ link.name }}
              </a>
            </li>
          </ul>
        </component>
      </div>
      <div
        v-if="data.services && data.services.data.length"
        class="footer-menu--item footer-menu--item---services"
        :class="{ opened: accordions.fsl.opened }"
      >
        <t2-heading-arrow
          v-if="data.services.title"
          class="footer-menu--title"
          @click="toggle(accordions.fsl)"
        >
          {{ data.services.title }}
        </t2-heading-arrow>
        <component
          :is="isSm ? 't2-accordion' : 'div'"
          :show="isAccordionEnabled(accordions.fsl.opened)"
        >
          <ul class="footer-menu--list">
            <li
              v-for="(link, index) in data.services.data"
              :key="'fsl-' + index"
              class="footer-menu--list-item"
            >
              <a
                class="footer-menu--list-link"
                :href="link.url"
                @click.prevent="$emit('link-click', link)"
              >
                {{ link.name }}
              </a>
            </li>
          </ul>
        </component>
      </div>
      <div
        v-if="data.info && data.info.data.length"
        class="footer-menu--item footer-menu--item---info"
        :class="{ opened: accordions.fil.opened }"
      >
        <t2-heading-arrow
          v-if="data.info.title"
          class="footer-menu--title"
          @click="toggle(accordions.fil)"
        >
          {{ data.info.title }}
        </t2-heading-arrow>
        <component
          :is="isSm ? 't2-accordion' : 'div'"
          :show="isAccordionEnabled(accordions.fil.opened)"
        >
          <ul class="footer-menu--list">
            <li
              v-for="(link, index) in data.info.data"
              :key="'fil' + index"
              class="footer-menu--list-item"
            >
              <a
                class="footer-menu--list-link"
                :href="link.url"
                @click.prevent="$emit('link-click', link)"
              >
                {{ link.name }}
              </a>
            </li>
          </ul>
        </component>
      </div>
      <div
        v-if="data.help"
        class="footer-menu--item footer-menu--item---help"
      >
        <t2-heading
          v-if="data.help.title"
          class="footer-menu--title"
        >
          {{ data.help.title }}
        </t2-heading>
        <div class="footer-menu--phone">
          <div class="footer-menu--phone-icon">
            <t2-svg icon-id="old-phone" class="footer-menu--svg" />
          </div>
          <div v-if="data.help.shortNumber" class="footer-menu--phone-info">
            <a
              :href="data.help.shortNumber.url"
              target="_self"
              class="footer-menu--phone-number"
            >
              {{ data.help.shortNumber.name }}
            </a>
            <div
              v-if="data.help.shortNumber.price"
              class="footer-menu--phone-disclaimer"
            >
              <span class="footer-menu--phone-price">
                {{ data.help.shortNumber.price.amount }}
              </span>
              {{ data.help.shortNumber.price.prefix }}
            </div>
          </div>
          <div v-if="data.help.longNumber" class="footer-menu--phone-info">
            <a
              :href="data.help.longNumber.url"
              target="_self"
              class="footer-menu--phone-number"
            >
              {{ data.help.longNumber.name }}
            </a>
            <div
              v-if="data.help.longNumber.price"
              class="footer-menu--phone-disclaimer"
            >
              <span class="footer-menu--phone-price">
                {{ data.help.longNumber.price.amount }}
              </span>
              {{ data.help.longNumber.price.prefix }}
            </div>
          </div>
          <div
            v-if="data.help.support"
            class="footer-menu--phone-support-hours"
          >
            {{ data.help.support.label }}
            <p class="footer-menu--phone-support-hours---strong">
              {{ data.help.support.hours }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="data.languages || data.socials || data.apps"
        class="footer-menu--item footer-menu--item---languages footer-menu--item---space-between footer-menu--item---flexed"
      >
        <div v-if="data.socials" class="footer-menu--social">
          <h2 class="footer-menu--social-title">{{ data.socials.title }}</h2>
          <div class="footer-menu--social-icons">
            <a
              v-if="data.socials.facebook"
              class="footer-menu--social-icon"
              :href="data.socials.facebook.url"
              @click.prevent="$emit('link-click', data.socials.facebook)"
            >
              <t2-svg
                class="footer-menu--svg"
                icon-id="social-facebook"
              />
            </a>
            <a
              v-if="data.socials.instagram"
              class="footer-menu--social-icon"
              :href="data.socials.instagram.url"
              @click.prevent="$emit('link-click', data.socials.instagram)"
            >
              <t2-svg
                class="footer-menu--svg"
                icon-id="social-instagram"
              />
            </a>
            <a
              v-if="data.socials.youtube"
              class="footer-menu--social-icon"
              :href="data.socials.youtube.url"
              @click.prevent="$emit('link-click', data.socials.youtube)"
            >
              <t2-svg
                class="footer-menu--svg"
                icon-id="social-youtube"
              />
            </a>
            <a
              v-if="data.socials.tiktok"
              class="footer-menu--social-icon"
              :href="data.socials.tiktok.url"
              @click.prevent="$emit('link-click', data.socials.tiktok)"
            >
              <t2-svg
                class="footer-menu--svg"
                icon-id="social-tiktok"
              />
            </a>
          </div>
        </div>
        <div
          v-if="data.languages"
          class="footer-menu--language"
        >
          <h2 class="footer-menu--language-title">{{ data.languageTitle }}</h2>
          <div class="footer-menu--language-container">
            <div
              v-for="(lang, index) in data.languages"
              :key="index"
              class="footer-menu--language-image-container"
              @click="$emit('language-select', lang)"
            >
              <img
                v-lazy="lang.flag"
                class="footer-menu--language-image"
              >
            </div>
          </div>
        </div>
        <div v-if="data.apps" class="footer-menu--apps">
          <h2 class="footer-menu--apps-title">{{ data.apps.title }}</h2>
          <div class="footer-menu--apps-images--container">
            <a
              v-for="(store, index) in data.apps.stores"
              :key="`store-${index}`"
              class="footer-menu--apps-image--wrapper"
              :href="store.url"
              @click.prevent="$emit('link-click', { url: store.url })"
            >
              <img v-lazy="store.image" class="footer-menu--apps-image" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <t2-footer-copyright
      :copyright="data.copyright"
      :policies="data.policies"
      @cookies-click="$emit('cookies-click', $event)"
      @link-click="$emit('link-click', $event)"
    />
  </div>
</template>
<script>
import T2Svg from "@atomic/atoms/Svg/Svg.vue";
import T2FooterCopyright from "@atomic/atoms/FooterCopyright/FooterCopyright.vue";
import T2Accordion from "@atomic/atoms/Accordion/Accordion.vue";
import T2Heading from "@atomic/atoms/Heading/Heading.vue";
import T2HeadingArrow from "@atomic/molecules/HeadingArrow/HeadingArrow.vue";
import { useBreakpoints } from "@/composables/useBreakpoints.js";
export default {
  components: {
    T2Svg,
    T2Heading,
    T2HeadingArrow,
    T2Accordion,
    T2FooterCopyright,
  },
  inject: ["emitter"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "link-click",
    "cookies-click",
    "language-select",
  ],
  setup() {
    const { isSm } = useBreakpoints();

    return { isSm };
  },
  data() {
    return {
      accordions: {
        fsl: {
          id: "footer_accordion_fsl",
          opened: false,
        },
        fpl: {
          id: "footer_accordion_fpl",
          opened: false,
        },
        fil: {
          id: "footer_accordion_fil",
          opened: false,
        },
        fdl: {
          id: "footer_accordion_fdl",
          opened: false,
        },
      },
    };
  },
  computed: {
    isSmallerContainer() {
      const sections = [
        this.data.plans.data.length,
        this.data.devices.data.length,
        this.data.services.data.length,
        this.data.info.data.length,
      ].filter(x => x);

      return sections.length < 4;
    },
    selectedLanguage() {
      return this.data.languages.find((x) => x.selected);
    },
    otherLanguages() {
      return this.data.languages.filter((x) => !x.selected);
    },
  },
  methods: {
    toggle(accordion) {
      accordion.opened = !accordion.opened;
    },
    isAccordionEnabled(isOpened) {
      return this.isSm ? isOpened : null;
    },
  },
};
</script>
<style lang="less">
@import (reference) "~@/assets/less/variables.less";
@color-for-language-bubble: #F0F0F0;
.footer-menu {
  padding: 30px 0 0;
  background: @color-blue;
  margin-top: auto;
  @media screen and (max-width: @media-tablet-landscape) {
    height: auto;
  }

  @media screen and (max-width: @media-tablet-portrait) {
    padding: 0;
  }

  &--container {
    padding-bottom: 40px;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 450px;
    padding: calc(@grid-mobile-padding * 2) @grid-padding;

    @media screen and (max-width: @media-breakpoint-lg-max) {
      max-height: 1100px;
      max-width: 900px;

      &.footer-menu--container---smaller {
        max-height: 1000px;
      }
    }

    @media screen and (max-width: @media-tablet-portrait) {
      max-height: unset;

      &.footer-menu--container---smaller {
        max-height: unset;
      }
    }

    &---main {
      margin-bottom: 2rem;
      &:not(:last-child) {
        @media screen and (min-width: @media-tablet-portrait) {
          border-bottom: unset !important;
        }
      }
    }

    &:not(:last-child) {
      @media screen and (min-width: @media-tablet-portrait) {
        border-bottom: 1px solid @color-grey-10;
        padding-bottom: 2rem;
      }

      @media screen and (max-width: @media-tablet-portrait) {
        border-bottom: 0;
        padding-bottom: 0;

        .footer-menu--newsletter---container {
          border-bottom: 1px solid @color-grey-10;
          padding-bottom: 1rem;
        }
      }
    }

    &:not(:first-child) {
      padding: 2rem 10px;

      @media screen and (max-width: @media-tablet-portrait) {
        padding: 0 10px;
      }
    }

    @media screen and (max-width: @media-tablet-portrait) {
      padding: calc(@grid-mobile-padding * 2) @grid-mobile-padding;

      &.smaller {
        padding: 0 @grid-mobile-padding calc(@grid-mobile-padding * 2);
      }
    }
  }

  &--item {
    max-width: 240px;
    padding: 0 12px 0 0;
    margin-bottom: 36px;

    @media screen and (max-width: @media-tablet-portrait) {
      padding-right: 0;
      margin-bottom: 0;
    }

    &---space-between {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    &.opened .heading-arrow--title-svg {
      transform: rotate(180deg);
    }

    .footer-menu--title.opened .heading-arrow--title-svg {
      transform: rotate(180deg);
    }

    @media screen and (max-width: @media-breakpoint-lg-max) {
      width: 50%;
      max-width: unset;

      &.footer-menu--item---plans {
        order: 1;
      }
      &.footer-menu--item---help {
        order: 2;
      }
      &.footer-menu--item---devices {
        order: 3;
      }
      &.footer-menu--item---info {
        order: 4;
      }
      &.footer-menu--item---services {
        order: 5;
      }
      &.footer-menu--item---languages {
        order: 6;
      }
    }
    @media screen and (max-width: @media-tablet-portrait) {
      width: 100%;
      //Using inline-block because layout breaks with flexbox and display: block on Android 4.X
      display: inline-block;
      padding-top: @grid-mobile-padding+6;
      border-bottom: 1px solid @color-grey-10;

      &.footer-menu--item---devices {
        order: 2;
      }
      &.footer-menu--item---services {
        order: 3;
      }
      &.footer-menu--item---help {
        order: 5;
      }
      &.footer-menu--item---languages {
        order: 6;
      }

      &:last-child {
        border-bottom: 0;
      }
      &---flexed {
        display: flex;
      }
    }
  }

  &--newsletter {
    .newsletter {
      &--label {
        color: @color-text-dark;
      }

      &--input {
        .v-input--container {
          input {
            font-size: 16px;
            font-weight: 400;
          }
          input:active,
          input:focus {
            color: @color-dark;
          }
        }
      }

      &--disclaimer {
        color: @color-text-dark;
        font-size: 16px;
      }
    }

    &---container {
      max-width: @small-container-width;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &--list {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }

  &--title,
  &--social-title {
    .BP-bold;
    color: @color-white;
    text-align: left;
    font-size: 16px;
    margin: 0;
    padding-bottom: @grid-mobile-padding + 10;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    @media screen and (max-width: @media-tablet-portrait) {
      font-size: 14px;
    }
  }

  &--title {
    pointer-events: none;

    .heading-arrow--title-svg {
      fill: @color-white;
    }

    @media screen and (max-width: @media-tablet-portrait) {
      pointer-events: all;
      cursor: pointer;
      padding-bottom: 0;
      margin-bottom: @grid-mobile-padding + 6;
    }
  }

  &--title-svg {
    display: none;
    fill: @color-text-dark;
    height: 6px;
    position: absolute;
    right: 0;
    top: 5px;
    transition: transform 0.2s ease-in-out;
    width: 10px;
    @media screen and (max-width: @media-tablet-portrait) {
      display: block;
    }
  }

  //accordion open
  [data-accordion-status="open"] {
    .footer-menu--title-svg {
      transform: rotate(180deg);
    }
  }

  &--list-item {
    display: block;
    font-size: 14px;
    line-height: 19px;
    padding: 5px 0;
    padding-left: 1px;

    @media screen and (max-width: @media-tablet-portrait) {
      font-size: 13px;
      &:last-child .footer-menu--list-link {
        padding-bottom: @grid-mobile-padding + 2;
      }
    }
  }

  &--list-link {
    display: block;
    color: @color-white;

    &:hover {
      color: @color-white;
      text-decoration: underline;
    }
  }

  &--phone {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: @media-tablet-portrait) {
      padding: 0 0 20px;
      flex-direction: row;
      margin-top: 0;
    }

    &-disclaimer {
      .BP-light;
      color: @color-white;
      line-height: 14px;
      font-size: 12px;
      margin: 5px 0 24px;

      .footer-menu--phone-price {
        font-weight: bold;
      }

      @media screen and (max-width: @media-tablet-portrait) {
        margin: 0;
      }
    }

    &-support-hours {
      .BP-light;
      color: @color-white;
      line-height: 14px;
      font-size: 12px;

      @media screen and (max-width: @media-tablet-portrait) {
        display: none;
      }

      &---strong {
        margin: 0;
        font-weight: bold;
      }
    }

    &---container {
      display: flex;
      flex-direction: row;
    }
  }

  &--phone-info {
    @media screen and (max-width: @media-tablet-portrait) {
      padding-left: 15px;
    }
  }
  &--phone-label {
    font-size: 16px;
    font-weight: 300;
    color: @color-text-dark;
    @media screen and (max-width: @media-tablet-portrait) {
      font-size: 13px;
    }
  }

  &--phone-call-info {
    color: @color-text-dark;
    position: relative;
    top: 2px;
  }

  &--phone-number {
    cursor: pointer;
    .BP-bold;
    font-size: 18px;
    color: @color-red;
    display: flex;
    align-items: center;

    &:hover {
      color: @color-darkred;
    }

    @media screen and (max-width: @media-tablet-portrait) {
      height: unset;
    }
  }

  &--phone-icon,
  &--social-icon {
    background-color: @color-white;
    border-radius: 100%;
    display: block;
    flex-shrink: 0;
    height: 48px;
    position: relative;
    width: 48px;

    @media screen and (max-width: @media-tablet-portrait) {
      height: 38px;
      width: 38px;
    }
  }

  &--phone-icon {
    display: none;
    @media screen and (max-width: @media-tablet-portrait) {
      display: block;
    }
    .footer-menu--svg {
      fill: @color-red;
    }
  }

  &--social-icon {
    transition: all 0.2s ease-in-out;
    color: unset;

    &:hover {
      color: unset;
      border: 1px solid @color-white;
      background-color: @color-blue;
      transition: all 0.2s ease-in-out;

      .footer-menu--svg {
        fill: @color-white;
        transition: all 0.2s ease-in-out;
      }
    }

    @media screen and (max-width: @media-tablet-portrait) {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--social {
    @media screen and (max-width: @media-tablet-portrait) {
      padding: 20px 0 0;
      width: 100%;
      order: 4;
    }
  }

  &--social-title {
    @media screen and (max-width: @media-tablet-portrait) {
      display: none;
    }
  }

  &--social-icons {
    display: flex;
    justify-content: space-between;
    width: 240px;
    @media screen and (max-width: @media-tablet-portrait) {
      justify-content: center;
      width: auto;
    }
  }

  &--apps {
    margin-top: 40px;

    @media screen and (max-width: @media-tablet-portrait) {
      order: 3;
      border-bottom: 1px solid @color-grey-10;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      padding: 12px 0 28px;
    }
    &-images--container {
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: @media-tablet-portrait) {
        justify-content: center;
      }
    }
    &-image {
      width: 100%;
      height: 100%;

      &--wrapper {
        max-height: 32px;
        max-width: 108px;
        margin-bottom: 10px;
        margin-right: 10px;

        &:nth-child(even) {
          margin-right: unset;
        }

        @media screen and (max-width: @media-breakpoint-lg-max) {
          &:nth-child(even) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &--apps-title {
    color: @color-white;
    font-size: 16px;

    @media screen and (max-width: @media-tablet-portrait) {
      font-size: 14px;
    }
  }

  &--svg {
    bottom: 0;
    fill: @color-blue;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s ease-in-out;
    width: 20px;

    @media screen and (max-width: @media-tablet-portrait) {
      height: 17px;
    }
  }

  &--language {
    margin-top: 20px;

    @media screen and (max-width: @media-tablet-portrait) {
      border-bottom: 1px solid @color-grey-10;
      padding-bottom: 16px;
      margin-top: 0;
    }
  }

  &--language-title {
    text-transform: uppercase;
    color: @color-white;
    font-size: 16px;

    @media screen and (max-width: @media-tablet-portrait) {
      display: none;
    }
  }
  &--language-container {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: @media-breakpoint-lg-max) {
      width: 240px;
    }
    @media screen and (max-width: @media-tablet-portrait) {
      margin: 0 auto;
    }
  }
  &--language-image-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 48px;
    width: 48px;
    @media screen and (max-width: @media-tablet-portrait) {
      height: 38px;
      width: 38px;

      margin-right: 20px;
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
    }
  }
  &--language-image {
    transition: height .2s;
    height: 30px;
    cursor: pointer;
    background: @color-for-language-bubble;
    border-radius: 100%;
    border: 1px solid @color-for-language-bubble;

    &:hover {
      height: 38px;

      @media screen and (max-width: @media-tablet-portrait) {
        height: 34px;
      }
    }
  }
}

.accordion {
  .footer-menu--list-link,
  .footer-menu--list-item,
  .footer-menu--list {
    pointer-events: none;
    z-index: -1;
  }

  &---enabled {
    .footer-menu--list-link,
    .footer-menu--list-item,
    .footer-menu--list {
      pointer-events: all;
      z-index: 5;
    }
  }
}
</style>
