import axios from "axios";
import constants from "@/constants";
import qs from "qs";
import TokenService from "@/services/token";
import store from "@/store";

const currentHostname = window.location.hostname;
let url = process.env.VUE_APP_BRANDTIER_URL;
if (currentHostname.includes("staging")) {
  url = process.env.VUE_APP_BRANDTIER_STAGING_URL;
} else if (currentHostname.includes("test1")) {
  url = process.env.VUE_APP_BRANDTIER_TEST1_URL;
} else if (currentHostname.includes("test2")) {
  url = process.env.VUE_APP_BRANDTIER_TEST2_URL;
}
constants.Env.BrandtierUrl = url;

let baseURL = constants.Env.BrandtierUrl;
let isNarsyk = window.location.pathname.includes(constants.Routes.Narsyk);
let tokenName = isNarsyk
  ? constants.LocalStorage.NarsykJwtToken
  : constants.LocalStorage.SelfcareJwtToken;
let recentlyExchanged = false;

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

const exchangeToken = (token) => {
  recentlyExchanged = true;

  fetch(`${constants.Env.BrandtierUrl}/auth/token/exchange`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data?.token) {
        localStorage.setItem(tokenName, data.token);
      }

      setTimeout(() => {
        recentlyExchanged = false;
      }, constants.TokenExchangeTimeout);
    });
};

axiosInstance.defaults.paramsSerializer = function(params) {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(tokenName);

  if (token) {
    if (
      !isNarsyk &&
      TokenService.isTokenCloseToExpiration(token) &&
      !recentlyExchanged
    ) {
      exchangeToken(token);
    }

    config.headers["Authorization"] = `Bearer ${token}`;
  }

  config.headers["Accept-Language"] =
    store.getters["user/getLanguage"] || localStorage.getItem("language");

  return config;
});

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response) {
      if (error.response.status === 503) {
        window.location.href = constants.Routes.Maintenance;
      } else if (error.response.status === 401) {
        localStorage.removeItem(tokenName);
        window.location.href = isNarsyk
          ? constants.Routes.Narsyk
          : constants.Routes.Login;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
