import axiosClient from "@/common/axiosClient";

const getValidTransactions = () => {
  return axiosClient.get("/campaign/transactions");
};

const getTransactionState = (transactionId) => {
  return axiosClient.get(`/campaign/transactions/${transactionId}`);
};

const save = (transactionId, data) => {
  return axiosClient.post(`/campaign/transactions/${transactionId}/shipment`, data);
};

const spin = (transactionId) => {
  return axiosClient.post(`/campaign/transactions/${transactionId}/spin`);
};

export default {
  getValidTransactions,
  getTransactionState,
  save,
  spin,
};
