import constants from "@/constants";
import store from "@/store";

let timer = null;
let currentVersion = null;

const watchVersion = () => {
  currentVersion = document.querySelector("meta[name=app-version]").content;

  timer = setInterval(() => {
    return getCurrentVersion().then(version => {
      if (currentVersion !== version) {
        store.commit("common/setNewVersionAvailable", true);
        clearInterval(timer);
      }
    });
  }, constants.AppVersionCheckIntervalInSeconds * 1000);
};

const getCurrentVersion = () => {
  return fetch(location.origin).then((response) => response.text())
    .then((response) => {
      const tmpElement = document.createElement("html");
      tmpElement.innerHTML = response;

      return tmpElement.querySelector("meta[name=app-version]").content;
  })
};

export default {
  watchVersion
};
