import axiosClient from "@/common/axiosClient";

const getUserInfo = () => {
  return axiosClient.get("/user-information/user");
};

const getPukCode = () => {
  return axiosClient.get("/user-information/puk-code");
};

const getIdCard = () => {
  return axiosClient.get("/user-information/id-card");
};

const getNewRequestCount = () => {
  return axiosClient.get("/problem-handler/problems/new-count");
}

export default {
  getUserInfo,
  getPukCode,
  getIdCard,
  getNewRequestCount,
};
