import axiosClient from "@/common/axiosClient";

const updatePoints = () => {
  return axiosClient.post("/awards-campaign/actions/update");
};

const getPoints = () => {
  return axiosClient.get("/awards-campaign/balance");
};

const getUserActionHistory = () => {
  return axiosClient.get("/awards-campaign/actions");
};

const getPrizes = () => {
  return axiosClient.get("/awards-campaign/prizes");
};

const orderPrize = (item) => {
  return axiosClient.post(`/awards-campaign/purchase/${item}`);
};

const startGame = () => {
  return axiosClient.post("/awards-campaign/start-game");
};

const confirmWinning = (winningId) => {
  return axiosClient.post(`/awards-campaign/confirm/${winningId}`);
};

const getLocations = () => {
  return axiosClient.get("/awards-campaign/locations");
};

const getAgreement = () => {
  return axiosClient.get("/awards-campaign/consents");
};

const saveAgreement = () => {
  return axiosClient.post("/awards-campaign/consents");
};

const getWinnings = () => {
  return axiosClient.get("/awards-campaign/winnings");
};

const saveShipment = (data) => {
  return axiosClient.post(`/awards-campaign/shipment`, data);
};

const confirmEmail = (code, email) => {
  return axiosClient.post('/awards-campaign/email', { code, email });
};

const purchaseItem = (itemId, data) => {
  return axiosClient.post(`/awards-campaign/purchase/${itemId}`, data);
};

const finishOrder = (winningId, u) => {
  return axiosClient.post(`/awards-campaign/email/${winningId}/${u}`);
};

export default {
  updatePoints,
  getPoints,
  getUserActionHistory,
  getPrizes,
  orderPrize,
  startGame,
  confirmWinning,
  getLocations,
  getAgreement,
  saveAgreement,
  getWinnings,
  saveShipment,
  confirmEmail,
  purchaseItem,
  finishOrder,
};
