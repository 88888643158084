<template>
  <t2-footer
    v-if="$store.getters['footer/hasData']"
    :data="footerData"
    @link-click="handleRoute"
    @cookies-click="$emit('cookies-click')"
    @language-select="handleLanguageSelect"
  />
</template>
<script>
import T2Footer from "@atomic/templates/Footer";
import UrlParserService from "@/services/urlParser";
import FooterService from "./services";
export default {
  name: "FooterWrapper",
  components: { T2Footer },
  inject: ["emitter"],
  emits: ["cookies-click"],
  computed: {
    footerData() {
      return FooterService.formatData(this.$store.getters["footer/getData"]);
    },
  },
  methods: {
    handleRoute(payload) {
      const isPildykRoute = payload.url && UrlParserService.getHost(payload.url) === window.location.hostname;
      const urlPath = UrlParserService.getPath(payload.url);

      if (this.$route.fullPath === urlPath) {
        window.scrollTo(0, 0);
        return;
      }

      if (!payload.icon && isPildykRoute && this.$route.fullPath !== urlPath) {
        return this.$router.push(urlPath);
      }

      if (!payload.url) {
        return;
      }

      if (payload.external) {
        const win = window.open(payload.url, "_blank");
        win.focus();

        return;
      }

      window.location.href = payload.url;
    },
    handleLanguageSelect(payload) {
      if (!payload) {
        return;
      }

      this.emitter.emit("language-select", payload);
    },
  },
};
</script>
